import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';

import { TextArea, InputSelect, InputText, Item, Alert, IconButton } from 'src/components/Ca2';
import { getPermission, transformType } from './helper';

import InputPassword from './InputPassword';
import TextArray from './TextArray';
import InputMoney from './InputMoney';

const Field = React.memo(({ field, value, onChange, helper = '', tabIndex }) => {
    const { type, name, label, required, items, visible, accessFormMain } = field;
    const params = useParams();
    const navigate = useNavigate();

    const describe = transformType(field);
    const disabled = !getPermission(params, field);

    const parseValue = useCallback((value, isNumber = false) => {
        return value !== undefined && value !== null ? value : (isNumber ? NaN : '');
    }, []);

    const renderInput = useCallback(() => {
        if (!visible) {
            return <input type="hidden" id={name} name={name} value={parseValue(value, describe.type === 'number')} />;
        }

        const commonProps = {
            key: name,
            id: name,
            label,
            value: parseValue(value, describe.type === 'number'),
            onChange,
            required,
            disabled,
            helper: helper,
            tabIndex,
        };

        const gridProps = {
            item: true,
            xs: field?.cfgCol?.xs ?? 12,
            sm: field?.cfgCol?.sm ?? 12,
            md: field?.cfgCol?.md ?? 12,
            lg: field?.cfgCol?.lg ?? 12,
            xl: field?.cfgCol?.xl ?? 12,
            xxl: field?.cfgCol?.xxl ?? 12,
        };

        switch (describe.type) {
            case 'password':
                return (
                    <Grid {...gridProps}>
                        <InputPassword {...commonProps} />
                    </Grid>
                );
            case 'text':
                return (
                    <Grid {...gridProps}>
                        <InputText {...commonProps} />
                    </Grid>
                );
            case 'money':
                return (
                    <Grid {...gridProps}>
                        <InputMoney {...commonProps} />
                    </Grid>
                );
            case 'number':
            case 'time':
                return (
                    <Grid {...gridProps}>
                        <InputText {...commonProps} type={describe.type} step={describe.type === 'time' ? 1 : undefined} />
                    </Grid>
                );
            case 'select-api':
                return (
                    <Grid {...gridProps}>
                        <Stack flexDirection="row" alignItems="center" spacing={1}>
                            <InputSelect
                                {...commonProps}
                                items={items}
                                style={{ flexGrow: 1 }} // Garante que o InputSelect ocupe todo o espaço disponível
                            />
                            {accessFormMain?.insert && (
                                <IconButton
                                    icon="material-symbols:add-box-outline"
                                    title="Adicionar"
                                    onClick={() => navigate(accessFormMain.insert)}
                                />
                            )}
                            {accessFormMain?.edit && value !== "" && (
                                <IconButton
                                    icon="material-symbols:edit-document-outline"
                                    title="Editar"
                                    onClick={() => navigate(accessFormMain.edit.replace(':id', parseValue(value)))}
                                />
                            )}
                        </Stack>
                    </Grid>
                );
            case 'enum':
                return (
                    <Grid {...gridProps}>
                        <InputSelect {...commonProps} items={describe.items || []} />
                    </Grid>
                );
            case 'text-area':
                return (
                    <Grid {...gridProps}>
                        <TextArea {...commonProps} />
                    </Grid>
                );
            case 'array':
                return (
                    <Grid {...gridProps}>
                        <Stack flexDirection="row" alignItems="center">
                            <TextArray {...commonProps} />
                        </Stack>
                    </Grid>
                );
            default:
                return (
                    <Item key={name} label={label}>
                        <Alert color="warning">{`Tipo "${type}" não foi implementado.`}</Alert>
                    </Item>
                );
        }
    }, [describe, field, value, onChange, parseValue, helper, tabIndex, disabled, navigate]);

    return renderInput();
});

export default Field;

