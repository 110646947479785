import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  List,
  Badge,
  Divider,
  Typography,
  ListItemText,
  ListItemButton,
  Button,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { fToNow } from 'src/utils/formatTime';
import { IconButtonAnimate } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import MenuPopover from 'src/components/menu-popover';

import Api from 'src/services/api/log_integracao_tempo'
import { IconButton, LoadingSimple } from 'src/components/Ca2';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { parseError } from 'src/helpers/utils';

export default function IntegrationFail() 
{
	const navigate = useNavigate();

	const { enqueueSnackbar } = useSnackbar();

	const { data, isFetching, refetch } = useQuery(
		['log_sistema_falha'],
		async () => await Api.getRows(),
		{
            cacheTime: 60000, 
            refetchInterval: 120000
		}
	);

	const [counter, setCounter] = useState(0);
	const [items, setItems] = useState([]);

	useEffect(() =>
	{
		if (data)
		{
			const { status, result } = data;

			if (status)
			{
				const res = result.filter(item => item.falha);
				setCounter(res.length);
				setItems(res);
			}
		}
	}, 
	[data]);

	const [openPopover, setOpenPopover] = useState(null);

	const handleOpenPopover = (event) => setOpenPopover(event.currentTarget); 

	const handleClosePopover = () => setOpenPopover(null);

	const handleMarkAllAsRead = async () =>
	{
		if (data)
		{
			const { status, result } = data;

			if (status)
			{
				for await (const item of result) 
					await handleMarkAsRead(item);
			}
		}
	}

	const handleMarkAsRead = async (item) =>
	{
		try 
		{
			const { status, result } = await Api.remover(item.cod_sistema);
			
			if (!status)
				enqueueSnackbar(result, { variant: 'warning'});

			refetch();
		} 
		catch (error) 
		{
			const { message } = parseError("Falha de Integração", error);

			enqueueSnackbar(message, { variant: 'error'});
		}
	}

	const handleFilter = (item) => 
	{
		navigate(PATH_DASHBOARD.tracking.view("system", item.nome));
		handleClosePopover()
	}

	return (
		<>
			<IconButtonAnimate
				color={openPopover ? 'primary' : 'default'}
				onClick={handleOpenPopover}
				sx={{ width: 40, height: 40 }}
			>
				<Badge badgeContent={counter} color="error">
					<Iconify icon="ic:outline-satellite-alt" />
				</Badge>
			</IconButtonAnimate>
			<MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
				<Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
					<Box sx={{ flexGrow: 1 }}>
						<Typography variant="subtitle1">Falha de Integração</Typography>
						<Typography variant="body2" sx={{ color: 'text.secondary' }}>
							{
								counter === 0
								? <>
									<Stack direction="row" sx={{ justifyContent: 'left', alignItems:'center'}}>
										<span>Tudo certo por aqui</span>&nbsp;<Iconify icon="mdi:like-outline"/>
									</Stack>
								</>
								: `Você tem ${counter} ${(counter === 1 ? "falha de integração" : "falhas de integração")}`
							}
							
						</Typography>
					</Box>
				</Box>
				<Divider sx={{ borderStyle: 'dashed' }} />
				{
					isFetching 
					? <LoadingSimple />
					:<>
						<Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
							<List
								disablePadding
							>
								{
									items.slice(0, 5).map((item, i) => 
									(
										<MessageItem 
											key={i} 
											item={item} 
											handleMarkAsRead={handleMarkAsRead}
											handleFilter={handleFilter}
										/>
									))
								}
							</List>
						</Scrollbar>
						{
							counter > 0 && 
							<>
								<Divider sx={{ borderStyle: 'dashed' }} />
								<Box sx={{ p: 1 }}>
									<Button fullWidth disableRipple onClick={() => handleMarkAllAsRead()}>
										Marcar todas como Lidas
									</Button>
								</Box>
							</>
						}
					</>
				}
			</MenuPopover>
		</>
	);
}

function MessageItem({ item, handleFilter, handleMarkAsRead }) 
{
	return (
		<ListItemButton
			sx={{
				py: 1.5,
				px: 2.5,
				mt: '1px',
				//...(message.isUnRead && {
					//bgcolor: 'action.selected',
				//}),
			}}
		>
			<ListItemText
				disableTypography
				primary={item.nome}
				secondary={
					<Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled', justifyContent: 'space-between', alignItems:'center'}}>
							<div>
								<Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
								<Typography variant="caption">{fToNow(item.datahora)}</Typography>
							</div>
							<Stack direction="row" >
								<IconButton 
									title="Filtrar" 
									icon={"material-symbols:filter-alt-outline-sharp"} 
									onClick={() => handleFilter(item) }
								/>
								<IconButton 
									title="Marcar como Lida" 
									icon={"material-symbols:check-small"} 
									onClick={() => handleMarkAsRead(item) }
								/>
							</Stack>
					</Stack>
				}
			/> 
		</ListItemButton>
	);
}