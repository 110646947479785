import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Divider, Drawer,  Stack } from '@mui/material';

import useResponsive from 'src/hooks/useResponsive';
import Scrollbar from 'src/components/scrollbar';
import DateTimeFilter from 'src/pages/tracking/ReportDialog/DateTimeFilter';

import { NAV } from 'src/config';

const ReportNav = memo(({ items, openNav, onOpenCompose, onCloseNav, di, df, setDi, setDf, getData, buttonsToRows, buttonsToRow, beforeFilter, addFilters }) => 
{
	const { pathname } = useLocation();

	const isDesktop = useResponsive('up', 'md');

    useEffect(() => 
	{
		if (openNav) onCloseNav();
	}, 
    // eslint-disable-next-line
    [pathname]);

    const BeforeFilter = () =>
    {
        if (beforeFilter)
        {
            const Element = () => beforeFilter;

            return (<>
                <Element />
                <Divider />
            </>);
        }
    }

	const renderContent = (
		<Scrollbar >
			<Stack spacing={1} sx={{ml: 2, mt: 2, mr: 2}}>
                <BeforeFilter />
                <DateTimeFilter di={di} df={df} setDi={setDi} setDf={setDf} getData={getData} />
				{
					addFilters.length > 0 &&
					<>
						<Divider />
						{ 
							addFilters.map((item, i) => 
							{
								const Element = () => item.element; 

								return <Element key={i} />
							}) 
						}
					</>
				}
				{
					buttonsToRows.length > 0 && 
					<>
						<Divider />
						<Stack flexDirection={"row"}>
							{ 
								buttonsToRows.map((item, i) => 
								{
									const Element = () => item.element; 

									return <Element key={i} />
								}) 
							}
						</Stack>
					</>
				}
				{
					buttonsToRow.length > 0 && 
					<>
						<Divider />
						<Stack flexDirection={"row"}>
							{ 
								buttonsToRow.map((item, i) => 
								{
									const Element = () => item.element;

									return <Element key={i} />
								}) 
							}
						</Stack>
					</>
				}
			</Stack>
		</Scrollbar>
	);

	return (
		<>
			{
                isDesktop 
                ? <Drawer
					variant="permanent"
					PaperProps={{
						sx: {
							width: NAV.W_BASE - 22,
							position: 'relative',
							bgcolor: 'background.neutral'
						},
					}}
				>
					{renderContent}
				</Drawer>
				: <Drawer
					open={openNav}
					onClose={onCloseNav}
					ModalProps={{ keepMounted: true }}
					PaperProps={{
						sx: {
							width: NAV.W_BASE,
							bgcolor: 'background.neutral'
						},
					}}
				>
					{renderContent}
				</Drawer>
		    }
		</>
	);
});

ReportNav.propTypes = {
	items: PropTypes.array,
	openNav: PropTypes.bool,
	onCloseNav: PropTypes.func,
	onOpenCompose: PropTypes.func,
};


export default ReportNav
