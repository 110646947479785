import React, { memo } from 'react';

const TextGrid = memo(({ item }) => {
    if (!item?.value) return null;

    return <span title={item.value}>{item.value}</span>;
});

export default TextGrid;

