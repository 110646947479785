import * as Util from '../../helpers/utils';

let Api = {
    getRows: async () =>
    {
		const endpoint = '/log_integracao_tempo/listar';
		return await Util.fetchDataOn(endpoint);
    },
    getRow: async (cod_sistema) =>
    {
		const endpoint = `/log_integracao_tempo/obter/${cod_sistema}`;
		return await Util.fetchDataOn(endpoint);
    },
	remover: async (cod_sistema) =>
    {
		const endpoint = `/log_integracao_tempo/remover/${cod_sistema}`;
		return await Util.fetchDataOn(endpoint);
    },
}

export default Api;
