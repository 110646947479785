import { APP_ALIAS } from "src/config/constants";

export const list = (company) =>
{
	company = company.trim().toLowerCase();

	switch (company) 
	{
		case 'localhost': 			return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Dev - Local", host: "http://localhost:3000" };
		case 'devl': 				return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Dev - Local", host: "http://localhost:3000" };
        case 'dev': 				return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Dev - Remoto", host: "http://www.ca2soft.com.br:3000" };
		case 'devssl': 				return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Dev - Remoto com SSL", host: "https://www.ca2soft.com.br:3001" };
		case 'genesis': 			return { appName: { n1: 'Genesis', n2: 'Groups' }, applicationName: 'Genesis Groups', logo: 'genesis', logoApp: 'genesis.png', name: "Genesis", host: "https://ca2soft.com.br/igenesis" };
		case 'gerensat': 			return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'gerensat', logoApp: 'single.svg', name: "Gerensat", host: "https://ca2soft.com.br/igerensat" };
		case 'gerensat-local': 		return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'gerensat', logoApp: 'single.svg', name: "Gerensat", host: "http://10.150.206.35:3000" };
		case 'hu':					return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Hungaro Transportes", host: "https://ca2soft.com.br/ihu" }; //http://177.125.217.10:3000
		case 'hu-local':			return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Hungaro Transportes", host: "http://172.16.210.9:3000" };
		case '1500':				return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "1500 Transportes", host: "https://ca2soft.com.br/ihu" };
		case '1500-local':			return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "1500 Transportes", host: "http://172.16.210.9:3000" };
		case 'pamajhon':			return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'TGA', logo: 'pamajhon', logoApp: 'single.svg', name: "Pamajhon", host: "https://www.ca2soft.com.br:3001" };
		case 'preslog':				return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Preslog", host: "https://ca2soft.com.br/ihu" };
		case 'preslog-local':		return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Preslog", host: "http://172.16.210.9:3000" };
		// case 'matsuda':				return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Matsuda", host: "http://200.195.156.242:3000" };
		// case 'matsuda-local':		return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Matsuda", host: "http://192.168.1.6:3000" };
		case 'tdg-local':			return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "TDG", host: "http://10.0.0.201:3000" };
		case 'tdg':					return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "TDG", host: "https://ca2soft.com.br/itdg" }; //http://187.95.123.4:3000
		case 'transrisco-local':	return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Transrisco", host: "http://192.168.1.200:3000" };
		case 'transrisco':			return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Transrisco", host: "https://ca2soft.com.br/itransrisco" }; //http://179.233.31.231:3000 
		case 'transrodace-local':	return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Transrodace", host: "http://192.168.0.249:3000" };
		case 'transrodace':			return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "Transrodace", host: "https://ca2soft.com.br/itransrodace" }; //http://179.127.174.58:3000"
		default:					return { appName: { n1: 'Ca2', n2: 'Track' }, applicationName: 'Ca2Track', logo: 'dashboard', logoApp: 'single.svg', name: "", host: "http://invalido" };
	}
}

export const getLogoName = (manager) => list(manager.trim().toLowerCase())?.logo;

export const getApplicationName= () => 
{
	//list(manager.trim().toLowerCase())?.applicationName;
	const name = localStorage.getItem(APP_ALIAS + '-app-name');
	return name ? name : '';
}

export const setApplicationName = (manager) => 
{
	const name = list(manager.trim().toLowerCase())?.applicationName ?? "Ca2Track";
	localStorage.setItem(APP_ALIAS + '-app-name', name);
}

export const getClientName = () => 
{
	const name = localStorage.getItem(APP_ALIAS + '-client-name');

	return name ? name : '';
}

export const setClientName = (manager) => 
{
	const name = list(manager.trim().toLowerCase())?.name;
	localStorage.setItem(APP_ALIAS + '-client-name', name);
}
