import PropTypes from 'prop-types';

import { Typography, Stack } from '@mui/material';

import Logo from '../../components/logo';
import Image from '../../components/image';

import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';
import { APP_PATH_MAIN } from 'src/config/constants';
import AppName from 'src/config/AppName';

LoginLayout.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title, logoName }) 
{
	return (
		<StyledRoot>
			<Logo
				sx={{
					zIndex: 9,
					position: 'absolute',
					mt: { xs: 1.5, md: 5 },
					ml: { xs: 2, md: 5 },
				}}
			/>
			<StyledSection>
				<Stack flexDirection={"row"}> 
					<Typography variant="h4" sx={{ mb: 10, mr: 1, maxWidth: 480, textAlign: 'center' }}>
						{title || `Bem Vindo ao `}
					</Typography>
					<AppName />
				</Stack>
				<Image
					disabledEffect
					visibleByDefault
					alt="auth"
					src={illustration || `${APP_PATH_MAIN}/assets/illustrations/illustration_${logoName}.png`}
					sx={{ maxWidth: 720 }}
				/>
				<StyledSectionBg />
			</StyledSection>
			<StyledContent>
				<Stack sx={{ width: 1 }}>{children}</Stack>
			</StyledContent>
		</StyledRoot>
	);
}
