import { useState } from 'react';
import ApiTrip from 'src/services/api/viagem';
import { parseError } from 'src/helpers/utils';

const useTripData = (dispatch) => {
    const [trip, setTrip] = useState(null);

    const getTrip = async (row) => {
        if (row.itinerario) {
            dispatch({ type: 'LOADING', payload: "Carregando dados da viagem..." });
            try {
                const { status, result } = await ApiTrip.getRow(row.cod_veiculo);
                if (status) {
                    setTrip(result);
                    dispatch({ type: 'SUCCESS' });
                } else {
                    dispatch({ type: 'ERROR', payload: result });
                }
            } catch (error) {
                const { message } = parseError("Viagem", error);
                dispatch({ type: 'ERROR', payload: message });
            }
        }
    };

    return {
        trip,
        setTrip,
        getTrip,
    };
};

export default useTripData;

