import React from 'react';

import { LoadingForUseQuery }  from 'src/components/Ca2/Loading';

import { useForm } from 'src/context/FormReusable/useForm';

const LoadingQuery = () =>
{
    const { query } = useForm();

    return <LoadingForUseQuery query={query} />
}

export default LoadingQuery