import moment from 'moment';
import axios from 'axios';
import { formatDistanceToNowStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { isEmpty, isNull, isObject } from 'lodash';

import * as Servers from './servers';
import { APP_ALIAS } from 'src/config/constants';

const packageJson = require('../../package.json');

export const version = () => packageJson.version;

export const urlBase = (value="") =>
{
    const { protocol, host, pathname} = window.location;
    const url = protocol + "//" + host + pathname + value;

    return url;
}

export const toEncrypt = (value) => value;

export const toDecrypt = (value) => value;

/**
 * conecta com api sem autenticação
 * @param {string} company
 * @param {string} endpoint
 * @param {string} raw
 */
export const fetchDataOff = async (company, endpoint, raw) =>
{
	const url = `${Servers.list(company).host}${endpoint}`;

	var config = {
		method: 'post',
		url: url
	};

	if (raw !== undefined && raw !== null)
		config.data = raw;

	const res = await axios(config);

	return res.data;
}

/**
 * conecta com api com autenticação
 * @param {string} endpoint
 * @param {object} raw
 */
export const fetchDataOn = async (endpoint, raw = null) =>
{
	const token = localStorage.getItem(APP_ALIAS + "-accessToken");

    if (!token)
    {
        return { 
			status: true, 
			result: "Sessão Expirada" 
		};
    }
    else
    {
        const company = localStorage.getItem(APP_ALIAS + "-company");
        const url = `${Servers.list(company).host}${endpoint}`;

        var config = {
            method: 'post',
            url: url,
            headers: { 'Authorization': `Bearer ${token}` },
            timeout: 0
        };

        if (raw)
            config.data = raw;

        try 
		{
			const res = await axios(config);
	
			return res.data;			
		} 
		catch (error) 
		{
			return getError(error)
		}
    }
}

export const getError = (e) =>
{
	let msg = e.toString();

	if (e.response?.data && typeof e.response.data.status !== 'undefined')
		msg = e.response.data.result;
	else 
	{
		if (msg?.includes("Failed to fetch"))
        	msg = "Sua requisição falhou. Verifique se todos os dados estão corretos e tente novamente.";
		else
		if (msg === "TypeError: Network request failed")
			msg = "Houve algum problema com sua conexão com a internet. Verifique se você tem conexão com a rede.";
		else
		if (msg?.includes("SyntaxError: JSON Parse error: Unrecognized token"))
			msg = "Encontramos um erro durante a sua solicitação. Por favor, tente mais tarde, pois já estamos resolvendo o problema.";
		else
		if (msg?.includes("Unexpected token < in JSON at position 1"))
			msg = "Encontramos uma falha durante a sua solicitação. Por favor, tente mais tarde, pois já estamos resolvendo o problema.";
		else
		if (msg?.includes("Request failed with status code 500"))
			msg = "Encontramos uma falha com erro 500 durante a sua solicitação. Por favor, tente mais tarde, pois já estamos resolvendo o problema.";
		else
		{
			msg = `Uncaught Error: ${e.toString()}`;
			// console.log("Helpers/Utils->getError", e);
		}
	}

    const json = {
        status: false,
        result: msg,
		response: e.response
    }

    return json;
}

export const parseError = (title, error) =>
{
	console.error(error);

	let response = {
		status: -1, 
		message: `${title}: Ops, houve um erro`
	}

	if (error?.response?.data?.result)
		response = { status: error.response.status, message: `${title}: ${error.response.data.result}` }
	else
	if (error?.message)
		response = { status: -1, message: `${title}: ${error?.message}` }
	
	return response;
}

export const getStatus = (response) =>
{
	console.log("getStatus", response)
    return true;
}

/**
 * Retornar bool ao verificar se o dh esta entre di e df
 * @param {datetime} dh
 * @param {datetime} di
 * @param {datetime} df
 * @returns {boolean}
 */
export const datetimeIsBetween = (dh, di, df) =>
{
	return moment(dh).isBetween(di, df);
}

export const datetime2br = (value="") =>
{
	if (isObject(value))
	{
		return value.format('DD/MM/YYYY HH:mm:ss') 
	}
	else 
	{
		if (value !== null)
		{
			const arr = value.replace('T',' ').split(' ');
			const date = arr[0];
			const time = arr[1];
	
			const arrDate = date.split('-');
			const day = arrDate[2];
			const month = arrDate[1];
			const year = arrDate[0];
	
			return day === undefined ? '' : `${day}/${month}/${year} ${time}`;
		}
		else
			return '';		
	}
}

export const datetimeBr2Mysql = (value="") =>
{
	if (isObject(value))
	{
		return value.format('YYYY-MM-DD HH:mm:ss') 
	}
	else 
	{
		if (value !== null)
		{
			const arr = value.split(' ');
			const date = arr[0];
			const time = arr[1];
	
			const arrDate = date.split('/');
			const day = arrDate[0];
			const month = arrDate[1];
			const year = arrDate[2];
	
			return day === undefined ? '' : `${year}-${month}-${day} ${time}`;
		}
		else
			return '';		
	}
}

export const date2br = (value="") =>
{
	return value !== null ? moment(value).format('DD/MM/YYYY') : '';
}

export const date2weekenddaybr = (value="") =>
{
	if (value !== null)
	{
		let weekday = value;

		switch (value)
		{
			case 'Sun':
				weekday = 'Dom';
				break;
			case 'Mon':
				weekday = 'Seg';
				break;
			case 'Tue':
				weekday = 'Ter';
				break;
			case 'Wed':
				weekday = 'Qua';
				break;
			case 'Thu':
				weekday = 'Qui';
				break;
			case 'Fri':
				weekday = 'Sex';
				break;
			case 'Sat':
				weekday = 'Sab';
				break;
			default:
				weekday = value;
			break;
		}

		return weekday;
	}
	else
		return '';
}

export const sec2time = (secs, formatted=false) =>
{
	let days = moment.utc(secs*1000).format('D') - 1;
	days = days === 0 ? '' : (formatted ? days + 'd ' : days + '.');
	const time = moment.utc(secs*1000).format('HH:mm:ss');

	return days + time;
}

export const time2sec = (value) =>
{
	// Create a moment object representing the time
	const time = moment(value, 'HH:mm:ss');

	// Create a duration object from the time
	const duration = moment.duration(time);

	// Unix timestamp in seconds
	const unixTimestamp = duration.asSeconds();

	// Create a moment object from the Unix timestamp
	const time2 = moment.unix(unixTimestamp);

	// Get the time in seconds since midnight
	const seconds = time2.diff(time2.clone().startOf('day'), 'seconds');

	return seconds;
}

export const day2sec = (day) =>
{
	return day * 86400;
}

export const remove_acentos = (value) =>
{
    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const somente_numeros = (value) =>
{
	return value.replace(/[^\w\s]|_/g, "").replace(/\s+/g, "")
}

export const getStatusData = (value) =>
{
    return "<div style='background-color: " + (value === 'Ativo' ? "green" : "red") + "'><center>" + value + "</center></div>";
}

export const getStatusViagemColor = (value) =>
{
    if (value === "Viagem Agendada")
        return "#F7B924";
    else
    if (value === "Sem Viagem")
        return "#3F6AD8";
    else
    if (value === "Com Viagem")
        return "#3AC47D";
    else
    if (value === "Com Viagem Atrasado")
        return "#16AAFF";
    else
    if (value === "Em Trânsito Sem Viagem")
        return "#D92550";
	else
	if (value === "Check-List Agendado")
		return "#20A8D8";
	else
        return "white";
}

export const getColorHtml = (value) =>
{
    if (value === "warning")
        return { border: "#f3450f", color: "#fcac03" };
    else
    if (value === "info")
        return { border: "#3F6AD8", color: "#20A8D8" };
    else
    if (value === "success")
        return { border: "#00755c", color: "#00c16c" };
    if (value === "error")
        return { border: "#e50e0e", color: "#f3450f" };
	else
        return { border: "#6d7578", color: "#8b9e9b" };
}

export const angleFromCoordinate = (lat1,lon1,lat2,lon2) =>
{
	var p1 = {
		x: lat1,
		y: lon1
	};

	var p2 = {
		x: lat2,
		y: lon2
	};

	//var angleRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
	var angleDeg = Math.round(Math.atan2(p2.y - p1.y, p2.x - p1.x) * 180 / Math.PI);

	return angleDeg;
}

export const capitalize = (s) =>
{
	if (typeof s !== 'string') return ''
	return s.charAt(0).toUpperCase() + s.slice(1)
}

export const cod2md5 = (table,value) =>
{
	var md5 = require("md5");
	return md5(table + value);
}

export const formataCPF = (cpf) =>
{
	//retira os caracteres indesejados...
	cpf = cpf.replace(/[^\d]/g, "");

	//realizar a formatação...
	return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export const formataCNPJ = (cnpj) =>
{
	//retira os caracteres indesejados...
	cnpj = cnpj.replace(/[^\d]/g, "");

	//realizar a formatação...
	return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export const formatarCpfCnpj = (numeroDocumento) => 
{
	if (numeroDocumento.length === 11) {
	  // Formata CPF: 999.999.999-99
	  return numeroDocumento.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
	} else if (numeroDocumento.length === 14) {
	  // Formata CNPJ: 99.999.999/9999-99
	  return numeroDocumento.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
	} else {
	  // Documento inválido
	  return numeroDocumento;
	}
}

export const formatarDocumentoMercosul = (numeroDocumento, nacionalidade) => 
{
	if (numeroDocumento.length === 11 && nacionalidade === "Brasileira") {
	  // Formata CPF: 999.999.999-99
	  return numeroDocumento.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
	} else {
	  // Documento inválido
	  return numeroDocumento;
	}
}

/**
 * Valida um CPF
 *
 * @param {string} cpf
 * @returns {boolean}
 */
export const validaCPF = (cpf) =>
{
	if ( !cpf || cpf.length !== 11
		|| cpf === "00000000000"
		|| cpf === "11111111111"
		|| cpf === "22222222222"
		|| cpf === "33333333333"
		|| cpf === "44444444444"
		|| cpf === "55555555555"
		|| cpf === "66666666666"
		|| cpf === "77777777777"
		|| cpf === "88888888888"
		|| cpf === "99999999999" )
	return false

	var soma = 0
	var resto

	for (var i = 1; i <= 9; i++)
		soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);

	resto = (soma * 10) % 11

	if ((resto === 10) || (resto === 11))  resto = 0

	if (resto !== parseInt(cpf.substring(9, 10)) ) return false

	soma = 0

	for (var a = 1; a <= 10; a++)
		soma = soma + parseInt(cpf.substring(a-1, a)) * (12 - a)

		resto = (soma * 10) % 11
	if ((resto === 10) || (resto === 11))  resto = 0
	if (resto !== parseInt(cpf.substring(10, 11) ) ) return false

	return true
}

export const copiarObjeto = (original) =>
{
	return JSON.parse(JSON.stringify(original));
}

export const convertArray2Object = (arr) =>
{
	var obj = {};

	arr.forEach(item =>
	{
		obj[item.name] = '';
	});

	return obj;
}

// export const groupBy = (list, keyGetter) =>
// {
//     let map = [];
//     let keyB = "";

//     list.forEach((item) =>
//     {
//         const key = keyGetter(item);

//         delete item.table_name;

//         if (key === keyB)
//             map[map.length - 1].items.push(item);
//         else
//         {
//             map.push({ name: key,  items: [] });
//             map[map.length - 1].items.push(item);
//         }

//         keyB = key;
//     });

//     return map;
// }

export const groupBy = (array, key) =>
{
	const obj = array.reduce((acc, item) => (
	{
		...acc,
		[item[key]]: [...(acc[item[key]] ?? []), item],
    }),
  	{});

	return Object.entries(obj).map(item =>
	{
		const x = {
			group: item[0],
			items: item[1]
		}
		return x;
	});
}

/**
 *
 * @param {number} meter
 * @returns {string}
 */
export const formatMeter = (meter) =>
{
	var value = meter / 1000;

	return meter > 999
		? value.toFixed(3).toString().replace(".",",") + " km"
		: meter + " m";
}

/**
 *
 * @param {number} value
 * @returns {string}
 */
export const formatMoeda = (value, simbol = false) =>
{
	if (value === undefined)
		return `${simbol ? "R$" : ""} 0,00`;
	else
	{
		//return  `${simbol ? "R$ " : ""}${value.toFixed(2).toString().replace(".",",")}`;
		return `${ simbol ? value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : value.toLocaleString('pt-br', {minimumFractionDigits: 2}) }`;
	}
}

export const now = () => moment().format('YYYY-MM-DD HH:mm:ss');

export const datetime_add = (datetime, secs) => moment(datetime).add(secs, 'seconds').format('YYYY-MM-DD HH:mm:ss');

export const datetime_sub = (datetime, secs) => moment(datetime).subtract(secs, 'seconds').format('YYYY-MM-DD HH:mm:ss');

export const datetime_diff = (start, end) => moment.duration(moment(end).diff(moment(start))).asSeconds();

export const dynamicSort = (property) =>
{
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

/**
 * varre a string e retorna somente numeros
 * @param {string} value
 * @returns {string}
 */
 export const somenteNumeros = (value) => value.replace(/\D/gim, '');

// export const alertOK = (message, title = 'Aviso') =>
// {
// 	confirmAlert(
// 	{
// 		title,
// 		message,
// 		buttons: [{ label: 'OK' }]
// 	});
// }

// export const alertWarning = (message, title) =>
// {
// 	confirmAlert(
// 	{
// 		title,
// 		message,
// 		buttons: [{ label: 'OK' }]
// 	});
// }

export const arrayChangePosition = (arr, old_index, new_index) =>
{
    if (new_index >= arr.length)
	{
        var k = new_index - arr.length + 1;
        while (k--)
		{
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

/**
 *
 * @param {int} distancia metros
 * @param {int} velocidadeMedia km/h
 * @returns {int} segundos
 */
export const tempoViagem = (distancia, velocidadeMedia) => Math.round(((distancia/1000)/velocidadeMedia) * 3600);

export const porcentagem = (parcial, total) => (parcial*100)/total;

export const percentageBetween = (num1, num2) =>   (((num1 - num2) / num2) * 100) * -1;

export const inflation = (data) =>
{
	return data.map((item, i) =>
	{
		if (i === 0)
			return { o: item, a: item, p: 0 }
		else
			return { o: data[i-1], a: item, p: data[i-1] === 0 && item === 0 ? 0 : percentageBetween(data[i-1], item)};
	})
	.reduce((acc, curr) => ((1 + parseFloat(acc)) * (1 + parseFloat(curr.p) / 100)) - 1, 0)*100;
}

export const cssTotalCenter = () =>
{
	return {height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'};
}

export const sleep = (milleseconds) =>
{
	setTimeout(() => {  console.log("World!"); }, milleseconds);
}

export const str2time = (datetime) =>
{
    return parseInt(Date.parse(datetime).toString().substr(0,10));
}

export const unixTimestamp2DateTime = (timestamp) =>
{
    return moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss');
}

export const porcentoDe = (valor) =>
{
    return valor / 100 + 1;
}

/**
 *
 * @param {number} laps
 * @param {datetime} date
 * @param {*} minutes
 * @param {*} pause_begin
 * @param {*} pause_end
 * @param {*} interval
 * @param {*} rest
 * @returns {datetime} previsao de chegada
 */

export const deadline_loop = (laps, date, minutes, pause_begin, pause_end, interval, rest) =>
{
	let delay = 0;
	let deadline = null;

	for (let i = 1; i <= laps; i++)
	{
		const date_verify           = str2time(date) + ((minutes * i) * 60);
		const date_verify_format    = unixTimestamp2DateTime(date_verify);
		const date_verify_array	    = date_verify_format.split(' ');

		//console.log(`${i} -> ${date_verify} ${date_verify_format}`)

		// interval
		const x_a = `${date_verify_array[0]} 12:00:00`;
		const x_b =  datetime_add(x_a, interval);// `${date_verify_array[0]} ${12 + (interval / 3600)}:00:00`

		//console.log(`${i} -> ${x_a} ${x_b}`)

		// horario ativo
		const y_a = `${date_verify_array[0]} ${pause_end}`;
		const y_b = `${date_verify_array[0]} ${pause_begin}`;

		//console.log(`${i} -> ${y_a} ${y_b}`)

		// rest
		const z_a = datetime_add(x_b, 4*3600);//`${date_verify_array[0]} 17:00:00`;
		const z_b = datetime_add(z_a, rest);

		//console.log(`${i} -> ${z_a} ${z_b}`)

		const verify_x = (date_verify > str2time(x_a) && date_verify < str2time(x_b));
		const verify_y = (date_verify > str2time(y_a) && date_verify < str2time(y_b));
		const verify_z = (date_verify > str2time(z_a) && date_verify < str2time(z_b));

		//console.log(`${i} -> ${date_verify_format} A: ${verify_x} P: ${verify_y} D: ${verify_z}`);

		if (verify_x || !verify_y || verify_z)
			delay++

		if (i % 30 === 0)
			delay += 6;

		if (i === laps)
		{
			deadline = delay >= 1
				? deadline_loop(delay, date_verify_format, minutes, pause_begin, pause_end, interval, rest)
				: date_verify_format;
		}
	}

	return deadline;
}

/**
 *
 * @param {number} velocidade_media
 * @param {time} pernoite_ini
 * @param {time} pernoite_fim
 * @param {number} refeicao_tempo_seg
 * @param {number} descanso_tempo_seg
 * @param {number} distancia_total
 * @param {number} distancia_restante
 * @param {datetime} saida
 * @returns
 */
export const transitTime = (velocidade_media, pernoite_ini, pernoite_fim, refeicao_tempo_seg, descanso_tempo_seg, distancia_total, distancia_restante, saida) =>
{
	let item = {};

	const interval_minutes      = 1;
	const timer_distance        = (velocidade_media/60) * interval_minutes;
	const timer_laps            = Math.floor((distancia_total/1000)/timer_distance);

	let deadline_inicial        = deadline_loop(timer_laps, saida, interval_minutes, pernoite_ini, pernoite_fim, refeicao_tempo_seg, descanso_tempo_seg)

	// adiciona margem de tolerancia de 10% para o tempo maximo
	const porcento              = 5;//(100/(distancia_total/1000))*5;
	const margem_add            = porcentoDe(porcento);
	const tempo_sec             = datetime_diff(saida, deadline_inicial);
	const tempo_sec_add         = Math.round((margem_add * tempo_sec));
	const tempo_sec_add_diff    = tempo_sec_add - tempo_sec;
	deadline_inicial            = datetime_add(deadline_inicial,tempo_sec_add_diff);

	// correcao, caso saida em horario de almoco ou descanso - VER PERNOITE
	// deadline_inicial            = descontarAlmoco(saida, refeicao_tempo_seg, deadline_inicial);
	// deadline_inicial            = descontarDescanso(saida, refeicao_tempo_seg, descanso_tempo_seg, deadline_inicial);

	item.recalculo_inicial = {
		margem_add: margem_add,
		porcentagem: `${porcento}%`,
		distancia: distancia_total,
		tempo_sec: tempo_sec_add, //datetime_diff(saida, deadline_inicial),
		tempo: sec2time(datetime_diff(saida, deadline_inicial)),
		datahora: saida,
		previsao_chegada: deadline_inicial,
	}

	const agora             = moment().format("YYYY-MM-DD HH:mm:ss").toString();
	const timer_laps_now    = Math.floor((distancia_restante/1000)/timer_distance);
	let deadline_atual      = deadline_loop(timer_laps_now, agora, interval_minutes, pernoite_ini, pernoite_fim, refeicao_tempo_seg, descanso_tempo_seg)

	// correcao, caso saida em horario de almoco ou descanso - VER PERNOITE
	// deadline_atual          = descontarAlmoco(agora, refeicao_tempo_seg, deadline_atual);
	// deadline_inicial        = descontarDescanso(agora, refeicao_tempo_seg, descanso_tempo_seg, deadline_atual);

	item.recalculo_atual = {
		andamento: `${Math.round((distancia_restante*100)/distancia_total)}%`,
		rodado: distancia_total - distancia_restante,
		tempo_op: sec2time(datetime_diff(saida, agora)),
		distancia: distancia_restante,
		tempo_sec: datetime_diff(agora, deadline_atual),
		tempo: sec2time(datetime_diff(agora, deadline_atual)),
		datahora: agora,
		previsao_chegada: deadline_atual
	}

	return item;
}

export const sendMessage2Whatsapp = (telefone, mensagem, enviar = false) =>
{
	window.open(`https://wa.me/55${somenteNumeros(telefone)}?text=${mensagem}&app_absent=${enviar ? 1 : 0}`);
}

/**
 * Formata um numero de telefone para os formatos (99) 9 9999-9999 ou (99) 9999-9999
 *
 * @param {string} value
 * @returns {string}
 */
export const formataTelefone = (phoneNumber) =>
{
	let cleaned = ('' + phoneNumber).replace(/\D/g, '');
  
	if (cleaned[0] === '0') {
	  cleaned = cleaned.substring(1);
	}
	
	let match = cleaned.match(/^(\+?\d{2})?(\d{2})(\d{4,5})(\d{4})$/);
	
	if (match) {
	  let countryCode = match[1] ? `${match[1]} ` : '';
	  let areaCode = match[2];
	  let block3 = match[3].length === 5 ? `${match[3].slice(0, 1)} ${match[3].slice(1, 5)}` : match[3];
	  let block4 = match[4];
	  if (countryCode === "+55" && areaCode === "44" && block3.startsWith("9")) {
		return `${countryCode}(${areaCode}) ${block3.slice(0, 1)} ${block3.slice(1)}-${block4}`;
	  }
	  return `${countryCode}(${areaCode}) ${block3}-${block4}`;
	}
	
	return '';
}

export const dec2hex = (number) =>
{
	const dec = +number;
	const hex = dec.toString(16).toLocaleUpperCase();
	return hex;
}

export const goGoogleMaps= (lat, lng) =>
{
	window.open(`https://www.google.com/maps/search/${lat}+${lng}/${lat},${lng},17z`);
}


export const goStreetView= (lat, lng) =>
{
	window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}&heading=0&pitch=00&fov=80`);
}

// import navigation from 'src/_nav';
// export const getToFromNav = (table) =>
// {
//     const navItem = navigation.find(x => x.table === table);

//     return navItem && navItem.to
// }

export const getCenterBrazil = () =>
{
    return [-10.334444, -53.199444];
}

//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export const distance = (lat1, lon1, lat2, lon2, unit) =>
 {
    if ((lat1 === lat2) && (lon1 === lon2))
        return 0;
    else
    {
        var radlat1 = Math.PI * lat1/180;
        var radlat2 = Math.PI * lat2/180;
        var theta = lon1-lon2;
        var radtheta = Math.PI * theta/180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") { dist = dist * 1.609344 }
        if (unit === "N") { dist = dist * 0.8684 }
        return dist;
    }
}

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
  
export const formatDistanceToNow = (value) =>
{
	const dateNow = now().split(' ')[0];
	const dateValue = value.split(' ')[0];
	const timeValue = value.split(' ')[1].substring(0,5);

	if (dateNow === dateValue)
		return `Hoje, ${timeValue}`;
	else
		return formatDistanceToNowStrict(new Date(value), { locale: ptBR, addSuffix: true}) + ", " + datetime2br(value);
}

/**
 * valida placa de veiculos
 * @param {string} placa 
 * @returns 
 */
export const validaPlaca = (placa) =>
{
	const regexPlaca                = /^[a-zA-Z]{3}[0-9]{4}$/;
	const regexParaguai             = /^[a-zA-Z]{3}[0-9]{3}$/;
	const regexParaguaiMercosul     = /^[a-zA-Z]{4}[0-9]{3}$/;
	const regexPlacaMercosulCarro   = /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/;
	const regexPlacaMercosulMoto    = /^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{1}[0-9]{1}$/;

	return regexPlaca.test(placa) 
	|| regexPlacaMercosulCarro.test(placa) 
	|| regexPlacaMercosulMoto.test(placa) 
	|| regexParaguai.test(placa)
	|| regexParaguaiMercosul.test(placa)
}

export const convertPlacaMercosul = (placa) =>
{                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
	let saida = placa.replace("-","").trim(); 
	const antigo = saida.substring(4,5);
	let novo = "";

	switch (antigo)
    {
		case '0':
			novo = "A";
			break;
		case '1':
			novo = "B";
			break;
		case '2':
			novo = "C";
			break;
		case '3':
			novo = "D";
			break;
		case '4':
			novo = "E";
			break;
		case '5':
			novo = "F";
			break;
		case '6':
			novo = "G";
			break;
		case '7':
			novo = "H";
			break;
		case '8':
			novo = "I";
			break;
		case '9':
			novo = "J";
			break;
		default:
			novo = antigo;
			break;
    }

	return (`${saida.substr(0,4)}${novo}${saida.substr(5,2)}`).toUpperCase();  
}

/**
 * verifica se a placa está no formato mercosul
 * @param {string} placa 
 * @returns 
 */
 export const isPlacaMercosul = (placa) =>
{
	// const regexPlacaMercosulCarro   = /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/;
	// const regexPlacaMercosulMoto    = /^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{1}[0-9]{1}$/;

	// return !(regexPlacaMercosulCarro.test(placa) || regexPlacaMercosulMoto.test(placa))

	placa = placa.replace("-", "").toUpperCase();

	// const regexOld = /^[A-Z]{3}[0-9]{4}$/;
	const regexNew = /^[A-Z]{3}[0-9]{1}[A-Z]{1}[0-9]{2}$/;

	return regexNew.test(placa);

// regexOld.test(plate)) { return “Placa antiga”; } else if (regexNew.test(plate)) { return “Placa nova”; } else { return “Placa inválida”; } }
}

export const initializeArrayZero = (amount) =>
{
	const tmp = [];

	for (let i = 0; i < amount; i++)
		tmp.push(0);


	return tmp;
}

export const getRandomIntInclusive = (min, max) => 
{
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
} 

export const days2seconds = (days) =>
{
	const secondsInDay = 24 * 60 * 60;
	
	return days * secondsInDay;
}

export const hasPermission = (items, n1, n2, n3) => 
{	
	const test = items
	.filter(a => a.id === n1 && a.items.filter(b => b.id === n2 && b.items.filter(c => c.id === n3).length === 1).length === 1)
	.length === 1;
    //console.log(items, n1, n2, n3, test)
	return test;//items.filter(a => a.id === n1 && a.items.filter(b => b.id === n2 && b.items.filter(c => c.id === n3))).length === 1
};

export const removerDuplicacao = (sentence) =>
{
	sentence = sentence.replace("\r\n","1sss")
	return [...new Set(sentence.split(" "))].join(" ");
}

export const defineDi = (datahora) =>
{
	let res = now().split(" ")[0] + " 00:00:00";

	if (datahora)
	{
		if (now().split(" ")[0] === datahora.split(' ')[0])
			res = now().split(" ")[0] + " 00:00:00";
		else 
			res =  datahora.split(" ")[0] + " 00:00:00";
	}
	
	return res;
}

export const convertToEmailString = (data) => 
{
	// Filtrar os objetos do array que possuem email1 definido
	const emails = data.filter(item => isNull(item.email1) || !isEmpty(item.email1) );
  
	// Mapear apenas os emails do email1 de cada objeto
	const emailStrings = emails.map(item => item.email1);
  
	// Juntar os emails em uma única string separados por ";"
	const result = emailStrings.join(";");
  
	return result;
  }

export const typeFiltersTracking = (value) =>
{
    if (value === 'system')
        return 'Sistema';
    else if (value === 'logisticalStatus')
        return 'Status Logístico';
    else if (value === 'cv')
        return 'C/V'
    else if (value === 'contract')
        return 'Contrato'
    else if (value === 'operationKind')
        return 'Tipo de Operação';
    else if (value === 'pgr')
        return 'PGR';
    else if (value === 'reference')
        return 'Referência';
    else 
        return '';
}

export const isBetween = (value, min, max) => value >= min && value <= max;

export const vhToPx = (vh) => {
  const windowHeight = window.innerHeight;
  return (vh / 100) * windowHeight;
};

export const pxToVh = (pixels) => {
  const windowHeight = window.innerHeight;
  return (pixels / windowHeight) * 100;
};
