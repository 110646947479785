import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import * as helper from 'src/components/Ca2/FormReusable/helper';
import { useSnackbar } from 'src/components/snackbar';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { capitalize, fetchDataOn, toDecrypt, toEncrypt, urlBase } from 'src/helpers/utils';
import { APP_FORM_DINAMICS_EDIT } from 'src/config/constants';

export const FormContext = createContext({});

export const FormProvider = ({ idPage, title, table, fieldsInit, optionsButtonsList, children }) => {
    const params = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [values, setValues] = useState({});
    const [fields, setFields] = useState(fieldsInit);
    const [selectStatus, setSelectStatus] = useState({ status: false, msg: '' });
    const [validated, setValidated] = useState(false);

    /**
     * Função para verificar e atualizar `fields` apenas se houver mudança real.
     * Isso ajuda a evitar renderizações desnecessárias.
     */
    const updateFields = useCallback((newFields) => {
        if (JSON.stringify(fields) !== JSON.stringify(newFields)) {
            setFields(newFields);
        }
    }, [fields]);

    /**
     * Função para buscar dados da tabela com base no ID dos parâmetros.
     * Essa função é usada para editar registros existentes.
     */
    const dataTable = useCallback(async () => {
        if (!params.id) return null;

        const currentUrl = urlBase();
        const isEditPage = APP_FORM_DINAMICS_EDIT.some(item => currentUrl.includes(item));

        if (isEditPage) {
            const endpoint = `/${table}/obter/${toDecrypt(params.id)}`;
            return await fetchDataOn(endpoint);
        }

        return null;
    }, [params.id, table]);

    /**
     * Função para buscar dados de combobox (select) baseado em uma tabela específica.
     */
    const dataCombo = useCallback(async (table, body = null) => {
        const parsedTable = helper.parseTableName(table);
        const endpoint = `/${parsedTable}/combo`;

        return await fetchDataOn(endpoint, body);
    }, []);

    /**
     * Hook de `useQuery` para buscar os dados da tabela quando necessário,
     * como quando a página está em modo de edição.
     */
    const query = useQuery([`${table}_edit`], dataTable, {
        retry: 10,
        refetchIntervalInBackground: false,
    });

    /**
     * Função para atualizar os itens de `fields` com base em uma consulta de dados,
     * como a atualização de um campo select.
     */
    const updateItemsFields = useCallback((status, result, name) => {
        if (status) {
            const fieldIndex = fields.findIndex(x => x.name === name);

            if (fieldIndex !== -1) {
                const updatedFields = [...fields];
                updatedFields[fieldIndex].items = result;
                updateFields(updatedFields); // Atualiza apenas se houver mudança real
            }
        }
    }, [fields, updateFields]);

    /**
     * Função para carregar os dados de campos filhos dependentes de um campo pai.
     * Isso é útil para campos select dependentes em formulários dinâmicos.
     */
    const setDataToFieldSelectChild = useCallback(async (id, value) => {
        const field = fields.find(field => field.name === id);

        if (field && field.type === 'int' && field.name.startsWith("cod_") && field.children) {
            setSelectStatus({ status: true, msg: `Preparado` });

            for (const child of field.children) {
                const childObj = fields.find(field => field.name === child);
                const tableChild = child.replace("cod_", "");
                const body = { [field.name]: value };

                setSelectStatus({ status: true, msg: `Atualizando itens de ${field.label}(s)` });

                try {
                    const { status, result } = await dataCombo(tableChild, body);
                    updateItemsFields(status, result, child);
                } catch (error) {
                    updateItemsFields(true, [], child);
                    enqueueSnackbar(`Não foi possível carregar os itens de ${childObj.label} de ${field.label}. Motivo: ${error.message}`, { variant: 'error' });
                }
            }

            setSelectStatus({ status: false, msg: 'Pronto' });
        }
    }, [fields, dataCombo, enqueueSnackbar, updateItemsFields]);

    /**
     * Função para definir a visibilidade de campos com base em regras específicas.
     * A visibilidade de campos pode ser controlada dinamicamente.
     */
    const defineVisibility = useCallback((name, value) => {
        const field = fields.find(field => field.name === name);

        if (field && field.items) {
            const visibilityItem = field.items.find(x => x.value === value);

            if (visibilityItem && visibilityItem.visible) {
                const updatedFields = [...fields];
                visibilityItem.visible.forEach(item => {
                    const { id, status } = item;
                    const fieldIndex = updatedFields.findIndex(field => field.name === id);

                    if (fieldIndex !== -1 && 'visible' in updatedFields[fieldIndex]) {
                        updatedFields[fieldIndex].visible = status;
                    }
                });

                updateFields(updatedFields); // Atualiza estado apenas se houve mudanças
            }
        }
    }, [fields, updateFields]);

    /**
     * Função para inicializar a visibilidade dos campos com base nos valores atuais.
     */
    const defineVisibilityInit = useCallback((values) => {
        Object.entries(values).forEach(([name, value]) => {
            defineVisibility(name, value);
        });
    }, [defineVisibility]);

    /**
     * Função de callback para manipulação de mudanças em campos de input.
     * Esta função é usada em eventos `onChange` nos campos de formulário.
     */
    const onChange = useCallback((e) => {
        const { value, id, name } = e.target;
        const ID = id ?? name;

        setValues((prevValues) => {
            const newValues = { ...prevValues, [ID]: value };
            defineVisibility(ID, value);
            setDataToFieldSelectChild(ID, value);
            return newValues;
        });
    }, [defineVisibility, setDataToFieldSelectChild]);

    /**
     * Função para definir os valores iniciais dos campos, usada ao carregar o formulário.
     */
    const defineInitialValues = useCallback(async () => {
        if (query.data) {
            const { status, result } = query.data;

            if (status) {
                setValues(helper.getInitialValues(fields, result));
                await setDataToFieldSelectMainInit(result);
            } else {
                enqueueSnackbar(result, { variant: 'error' });
            }
        } else {
            setValues({});
            await setDataToFieldSelectMainInit([]);
        }
    }, [query.data, fields, enqueueSnackbar]);

    /**
     * Função para inicializar os campos de select no formulário,
     * carregando os dados necessários para os selects principais.
     */
    const setDataToFieldSelectMainInit = useCallback(async (vals) => {
        setSelectStatus({ status: true, msg: "Iniciando..." });

        const selects = helper.getSelects(fields);

        for (const select of selects) {
            const { name, label } = select;
            const selectMain = helper.getFieldMainFromChild(selects, select);

            let body = null;
            let msg = '';

            if (selectMain) {
                body = { [selectMain.name]: vals[selectMain.name] || 1 };
                msg = ` de ${selectMain.label}(s)`;
            }

            setSelectStatus({ status: true, msg: `Buscando itens de ${label}(s)${msg}` });

            try {
                const { status, result } = await dataCombo(name.replace('cod_', ''), body);
                updateItemsFields(status, result, name);
            } catch (error) {
                updateItemsFields(true, [], name);
                enqueueSnackbar(`Não foi possível carregar os itens de ${label}. Motivo: ${error.message}`, { variant: 'error' });
            }
        }

        setSelectStatus({ status: false, msg: "Pronto" });
    }, [fields, dataCombo, enqueueSnackbar]);

    /**
     * Função para enviar os dados do formulário para o backend.
     * Esta função é usada tanto para criar quanto para atualizar registros.
     */
    const sendData = useCallback(async () => {
        const endpoint = params.id && APP_FORM_DINAMICS_EDIT.some(item => urlBase().includes(item))
            ? `/${table}/atualizar/${toDecrypt(params.id)}`
            : `/${table}/adicionar`;

        return await fetchDataOn(endpoint, helper.parseValuesToSend(fields, values, params));
    }, [params.id, table, fields, values]);

    const mutation = useMutation(sendData);

    /**
     * Função para validar o formulário antes do envio.
     * Verifica se todos os campos obrigatórios estão preenchidos.
     */
    const validation = useCallback(() => {
        const errors = fields
            .filter(field => field.required && field.visible)
            .filter(field => {
                const { name } = field;
                const value = values[name];
                return value === undefined || value.toString().trim().length === 0;
            });

        if (errors.length > 0) {
            const { name, label } = errors[0];
            enqueueSnackbar(`O campo ${label} é de preenchimento obrigatório!`, { variant: 'warning' });
            document.getElementById(name).focus();
        }

        return errors.length === 0;
    }, [fields, values, enqueueSnackbar]);

    /**
     * Função para enviar o formulário após a validação.
     * Exibe uma confirmação antes de enviar os dados.
     */
    const onSend = useCallback(() => {
        if (validation()) {
            const action = APP_FORM_DINAMICS_EDIT.some(item => urlBase().includes(item)) ? "ATUALIZAR" : "CADASTRAR";

            confirmAlert({
                title: 'Confirmação',
                message: `Tem certeza que deseja ${action} esses novos dados?`,
                buttons: [
                    {
                        label: 'Sim',
                        onClick: () => mutation.mutate(helper.parseValuesToSend(fields, values, params)),
                    },
                    {
                        label: 'Não',
                    },
                ],
            });
        }
    }, [validation, fields, values, params, mutation]);

    // Inicializa valores do formulário ao montar o componente
    useEffect(() => {
        setValues({});
    }, []);

    // Reseta o formulário se for um novo registro (inserção)
    useEffect(() => {
        if (urlBase().includes("insert")) {
            setValues({});
            document.getElementById(`form_${table}`).reset();
        }
    }, [params.id, table]);

    // Define a visibilidade dos campos ao montar o componente ou quando `values` mudar
    useEffect(() => {
        defineVisibilityInit(values);
    }, [values, defineVisibilityInit]);

    // Define valores iniciais ao carregar os dados
    useEffect(() => {
        defineInitialValues();
    }, [defineInitialValues]);

    // Observa o sucesso da mutação (envio de dados) e realiza ações necessárias
    useEffect(() => {
        const { data, isSuccess } = mutation;

        if (isSuccess) {
            const { status, result, id } = data;

            if (status) {
                enqueueSnackbar(result.replace("Item", capitalize(title)));

                if (!params.id) {
                    navigate(PATH_DASHBOARD[idPage].edit.replace(":id", toEncrypt(id)));
                }
            } else {
                enqueueSnackbar(result, { variant: 'error' });
            }
        }
    }, [mutation.isSuccess, params.id, navigate, idPage, title, enqueueSnackbar]);

    // Observa erros da mutação e exibe mensagens de erro
    useEffect(() => {
        const { isError, error } = mutation;

        if (isError) {
            const { status, result } = error.response.data;

            if (status) {
                enqueueSnackbar(result, { variant: 'warning' });
            } else {
                enqueueSnackbar(`Ops, houve algum erro: ${result}`, { variant: 'error' });
            }
        }
    }, [mutation.isError, enqueueSnackbar]);

    // Provedor de contexto para fornecer valores e funções ao restante da aplicação
    return (
        <FormContext.Provider
            value={useMemo(() => ({
                idPage,
                title,
                table,
                fields,
                optionsButtonsList,
                selectStatus,
                values,
                validated,
                query,
                mutation,
                setValidated,
                setFields,
                setValues,
                onChange,
                onSubmit: onSend, // Modificado para refletir a mudança de nome
            }), [
                idPage, title, table, fields, optionsButtonsList, selectStatus, values,
                validated, query, mutation, setValidated, setFields, setValues, onChange, onSend,
            ])}
        >
            {children}
        </FormContext.Provider>
    );
};

