import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';

import { IconButton } from 'src/components/Ca2';
import { datetime_add, days2seconds, now } from 'src/helpers/utils';
import { useAuthContext } from 'src/auth/useAuthContext';

import useResponsive from 'src/hooks/useResponsive';

const DateTimeFilter = memo(({ di, setDi, df, setDf, getData, buttonType = "button" }) => {
    const { user } = useAuthContext(); 
    const isDesktop = useResponsive('up', 'md');
    const [diLimit, setDiLimit] = useState(di);

    useEffect(() => {
        let { relatorio_dias } = user;

        if (relatorio_dias > 0) {
            relatorio_dias -= 1;
            setDiLimit(datetime_add(di, days2seconds(-relatorio_dias)).split(' ')[0] + " 23:59:59");
        } else {
            setDiLimit(datetime_add(di, days2seconds(-45)));
        }

        document.getElementById('di').focus();
    }, [di, user]);

    const handleDiChange = useCallback((event) => {
        setDi(event.target.value);
    }, [setDi]);

    const handleDfChange = useCallback((event) => {
        setDf(event.target.value);
    }, [setDf]);

    const DateTimeFields = (
        <>
            <TextField
                id="di"
                size="small"
                type="datetime-local"
                value={di}
                onChange={handleDiChange}
                inputProps={{ min: diLimit, max: df }}
            />
            <TextField
                id="df"
                size="small"
                type="datetime-local"
                value={df}
                onChange={handleDfChange}
                inputProps={{ min: di, max: now().split(' ')[0] + " 23:59:59" }}
            />  
        </>
    );

    return isDesktop ? (
        <>
            <Typography sx={{ color: 'text.secondary' }} variant="subtitle2">
                Filtro por Data/Hora
            </Typography>
            {DateTimeFields}
            {buttonType === "button" ? (
                <Button variant='contained' fullWidth onClick={getData}>
                    Enviar
                </Button>  
            ) : (
                <IconButton icon='ic:sharp-send' title="Enviar" onClick={getData}/>    
            )}
        </>
    ) : (
        <Grid container>
            <Grid item xs={12}>
                {DateTimeFields}
            </Grid>
            <Grid item xs={12}>
                <Button 
                    variant='contained' 
                    onClick={getData} 
                    style={{ width: 220, marginLeft: 5 }}
                >
                    Enviar
                </Button>  
            </Grid>
        </Grid>
    );
});

export default DateTimeFilter;

