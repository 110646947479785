// @mui
import { Divider, Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { APP_PATH_MAIN } from 'src/config/constants';
import { version } from 'src/helpers/utils';
import { getApplicationName } from 'src/helpers/servers';

import LoginLayout from '../../layouts/login';
import AuthLoginForm from './AuthLoginForm';
import Image from 'src/components/image/Image';

export default function Login() 
{
	const [logoName, setLogoName] = useState('dashboard');

	return (
		<LoginLayout logoName={logoName}>
			<Stack sx={{ mb: 5, position: 'relative' }}>
				<Typography variant="h4">Acessar {getApplicationName()}</Typography>
			</Stack>
			<AuthLoginForm setLogoName={setLogoName}/>
			<Stack flexDirection={"row"} justifyContent={"center"} alignContent={"center"} sx={{pt: 3}}>
				<Typography variant="subtitle2" paragraph>
					{`Ca2Track v.${version()} - Developed by Ca2soft (${new Date().getFullYear()})`}
				</Typography>
			</Stack>
			<Divider />
			<Stack flexDirection={"row"} justifyContent={"left"} alignContent={"center"} alignItems={"center"} sx={{ mt: 3}} > 
				<Image
					disabledEffect
					visibleByDefault
					alt="Aponte sua câmera para baixar em seu telefone ou clique para obter acessar o aplicativo na Google Play"
					title="Aponte sua câmera para baixar em seu telefone ou clique para obter acessar o aplicativo na Google Play"
					src={`${APP_PATH_MAIN}/assets/images/ca2track/qrcode-ca2track-app.png`}
					sx={{ maxWidth: 100, mr: 1, cursor: 'pointer' }}
					onClick={() => window.open("https://play.google.com/store/apps/details?id=br.com.ca2soft.ca2track")}
				/>		
				<Typography variant="caption" sx={{ textAlign: 'center' }}>
					Disponível para Android
				</Typography>
			</Stack>
		</LoginLayout>
  	);
}
