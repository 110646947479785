import { useState, useEffect } from 'react';
import ApiViagem from 'src/services/api/viagem';
import ApiPosicaoHistorico from 'src/services/api/posicao_historico';
import { now, parseError } from 'src/helpers/utils';

const useMapState = (trip, setTrip, selectedRow, dispatch) => {
    const [markers, setMarkers] = useState([]);
    const [nearbyVehicles, setNearbyVehicles] = useState({ enabled: false, radius: 10000 });
    const [nearbyMarkers, setNearbyMarkers] = useState({ enabled: false, radius: 10000 });
    const [lastPositions, setLastPositions] = useState([]);
    const [place, setPlace] = useState(null);

    useEffect(() => {
        resetMap();
    }, [selectedRow]);

    useEffect(() => {
        if (trip) {
            defineMarkers();
            defineLastPostion();
        }
    }, [trip]);

    const resetMap = () => {
        setNearbyVehicles({ ...nearbyVehicles, enabled: false });
        setNearbyMarkers({ ...nearbyMarkers, enabled: false });
        setLastPositions([]);
        if (trip && selectedRow && trip.veiculo.cod_veiculo !== selectedRow.row.cod_veiculo) {
            setTrip(null);
            setMarkers([]);
        }
    };

    const defineMarkers = async () => {
        if (selectedRow) await getMarkers(selectedRow.row);
    };

    const getMarkers = async (row) => {
        if (row.itinerario) {
            dispatch({ type: 'LOADING', payload: "Carregando dados de marcadores de viagem..." });
            try {
                const { status, result } = await ApiViagem.getMarkers(row.cod_veiculo);
                if (status) {
                    setMarkers(result);
                    dispatch({ type: 'SUCCESS' });
                } else {
                    dispatch({ type: 'ERROR', payload: result });
                }
            } catch (error) {
                const { message } = parseError("Marcadores", error);
                dispatch({ type: 'ERROR', payload: message });
            }
        }
    };

    const defineLastPostion = async () => {
        if (selectedRow) await getLastPositions(selectedRow.row);
    };

    const getLastPositions = async (row) => {
        try {
            const di = now().split(' ')[0];
            const { status, result } = await ApiPosicaoHistorico.getRowsAnalitico(row.cod_rastreador, di, di);
            if (status) {
                setLastPositions(result.map(x => [x.lat, x.lng]));
            } else {
                dispatch({ type: 'ERROR', payload: result });
            }
        } catch (error) {
            const { message } = parseError("Últimas Posições", error);
            dispatch({ type: 'ERROR', payload: message });
        }
    };

    return {
        markers,
        nearbyVehicles,
        setNearbyVehicles,
        nearbyMarkers,
        setNearbyMarkers,
        lastPositions,
        setLastPositions,
        place, 
        setPlace
    };
};

export default useMapState;

