import React from 'react';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'src/context/FormReusable/useForm';

const Footer = React.memo(() => {
    const { id } = useParams();
    const { query, mutation, selectStatus, onSubmit } = useForm();

    const isLoading = query.isLoading || query.isFetching || mutation.isLoading || selectStatus.status;

    return !isLoading && (
        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
            <LoadingButton 
                type="button" 
                variant="contained" 
                loading={false}
                onClick={onSubmit}
            >
                {!id ? 'Adicionar' : 'Salvar Alterações'}
            </LoadingButton>
        </Stack>
    );
});

export default Footer;

