import { useQuery } from "@tanstack/react-query";
import { useMemo, useEffect } from "react";
import { useAuthContext } from "src/auth/useAuthContext";
import api from 'src/services/api/grid_coluna';

const useColumnsUser = () => {
   const { user } = useAuthContext();
   const { data, isError } = useQuery(['columns_user'], () => api.getColsByGrid(user.cod_grid));

   const columns = useMemo(() => processColumns(data), [data]);

   useEffect(() => {
       if (isError) {
           console.error("Failed to fetch columns data");
       }
   }, [isError]);

   return { columns };
};

const processColumns = (data) => {
   if (data && data.status) {
       return data.result.map(col => ({
           id: col.field,
           title: col.headerName,
           width: col.width,
           ...(col.renderCell && { renderCell: col.renderCell }),
       }));
   }
   return [];
};

export default useColumnsUser;

