import React from 'react';
import { formataTelefone } from 'src/helpers/utils';

const Cellphone = ({value}) => 
{
    if (value)
    {
        const celphone = formataTelefone(value);

        return (
            <div>{celphone}</div>
        );
    }
    else
        return null; 
}

export default Cellphone