import PropTypes from 'prop-types';
import { forwardRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Stack } from '@mui/material';

import { APP_PATH_MAIN } from 'src/config/constants';
import { useSettingsContext } from '../settings';
import { useAuthContext } from 'src/auth/useAuthContext';
import { list, setApplicationName } from 'src/helpers/servers';

import AppName from 'src/config/AppName';

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => 
{
	const user = useAuthContext();

	const { themeLayout } = useSettingsContext();

	const [company] = useState(localStorage.getItem('ca2track-company'))
	const [img, setImg] = useState('single.svg');
	const [appName, setAppName] = useState('Ca2Track');
	const [appName1, setAppName1] = useState({n1: 'Ca2', n2: 'Track'});

	useEffect(() =>
	{
		if (company)
		{
			const data = list(company);

			setImg(data.logoApp);
			setAppName(data.applicationName);
			setAppName1(data.appName);

			setApplicationName(company);
		}
	}, 
	[company]);

	const logo = (
		<Box
			component="img"
			src={`${APP_PATH_MAIN}/logo/logo_${img}`} 
			sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
			title={`Clique para recarregar ${appName}`}
		/>
	);

	if (disabledLink) 
	{
		return <>{logo}</>;
	}

	return !user.isAuthenticated  
	? <>
		<Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
			{logo} 
		</Link>
	</>
	: <>
		<Stack flexDirection={"row"} justifyContent={"left"} alignItems={"center"}>
			<Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
				{logo} 
				{ themeLayout === "vertical" && <AppName n1={appName1.n1} n2={appName1.n2}/> }
			</Link>
		</Stack>
	</>
});

Logo.propTypes = {
	sx: PropTypes.object,
	disabledLink: PropTypes.bool,
};

export default Logo;
