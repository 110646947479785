import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

const useShortcutHandlers = (selectedRow, setSelectedRow, getDetailedData, trip, setTrip, setOpenDialogDetails, setOpenDialogReport) => {
    const navigate = useNavigate();

    const onCellKeyDown = useCallback((params, event) => {
        event.preventDefault();

        const { altKey, shiftKey, ctrlKey, keyCode } = event;
        let newRow = { id: params.id, row: params.row };

        if (!altKey && !shiftKey && !ctrlKey) {
            switch (keyCode) {
                case 13: shortcutShowTripOnMap(newRow); break; // Enter
                case 38: shortcutSetPreviuosRow(params, newRow); break; // ArrowUp
                case 40: shortcutSetNewRow(newRow, params); break; // ArrowDown
                case 45: shortcutSetTripInsert(newRow); break; // Insert
                case 46: shortcutSetTripFinish(newRow); break; // Del
                case 112: shortcutOpenReport(); break; // F1
                case 115: shortcutSetVehicleEditor(newRow); break; // F4
                case 118: shortcutSetOccurrenceAdd(newRow); break; // F7
                case 121: shortcutGetLinkTrip(newRow); break; // F10
                default: break;
            }
        } else if (ctrlKey && !altKey && !shiftKey) {
            switch (keyCode) {
                case 13: shortcutOpenDetails(); break; // Ctrl+Enter
                case 49: shortcutOpenReport(); break; // Ctrl+1
                case 118: shortcutSetOccurrenceAdd(newRow); break; // Ctrl+F7
                default: break;
            }
        }
    }, [selectedRow]);

    const shortcutShowTripOnMap = (newRow) => {
        getDetailedData(newRow.row);
        setSelectedRow(newRow);
    };

    const shortcutSetPreviuosRow = (params, newRow) => {
        const newId = params.id - 1;
        if (newId > -1) {
            newRow = { id: newId, row: data?.result?.find(row => row.id === newId) };
            setSelectedRow(newRow);
        }
    };

    const shortcutSetNewRow = (newRow, params) => {
        const newId = params.id + 1;
        if (newId < data?.result?.length - 1) {
            newRow = { id: newId, row: data?.result?.find(row => row.id === newId) };
            setSelectedRow(newRow);
        }
    };

    const shortcutSetVehicleEditor = (newRow) => {
        navigate(PATH_DASHBOARD.vehicle.edit.replace(":id", newRow.row.cod_veiculo));
        setSelectedRow(newRow);
    };

    const shortcutSetOccurrenceAdd = (newRow) => {
        navigate(PATH_DASHBOARD.occurrence.insertf7.replace(":vehicle_id", newRow.row.cod_veiculo));
        setSelectedRow(newRow);
    };

    const shortcutSetTripInsert = (newRow) => {
        if (!newRow.row.itinerario) {
            navigate(PATH_DASHBOARD.trip.insert.replace(":id", newRow.row.cod_veiculo));
            setSelectedRow(newRow);
        }
    };

    const shortcutSetTripFinish = (newRow) => {
        if (newRow.row.itinerario) {
            navigate(PATH_DASHBOARD.trip.finish);
            setSelectedRow(newRow);
        }
    };

    const shortcutGetLinkTrip = (newRow) => {
        if (newRow.row.itinerario) {
            getShareLinkTrip(newRow);
        }
    };

    const shortcutOpenDetails = () => {
        setOpenDialogDetails(true);
    };

    const shortcutOpenReport = () => {
        setOpenDialogReport(true);
    };

    return { onCellKeyDown };
};

export default useShortcutHandlers;

