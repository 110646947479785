import { fetchDataOn } from "src/helpers/utils";

let Api = {
    getRowsCobranca: async () =>
    {
		const endpoint = '/ca2soft/cobranca';
		return await fetchDataOn(endpoint);
    },
	getRowsServers: async () =>
    {
		const endpoint = '/ca2soft/servers/listar';
		return await fetchDataOn(endpoint);
    },
	getVersion: async () =>
    {
		const endpoint = '/ca2soft/app/last/version';
		return await fetchDataOn(endpoint);
    },
}

export default Api;
