// routes
import { confirmAlert } from 'react-confirm-alert';
import { APP_ALIAS } from 'src/config/constants';
import { PATH_AUTH } from '../routes/paths';

import axios from '../utils/axios';

function jwtDecode(token) 
{
  	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		window
		.atob(base64)
		.split('')
		.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
		.join('')
	);

  	return JSON.parse(jsonPayload);
}

export const isValidToken = (accessToken) => 
{
	if (!accessToken) {
		return false;
	}

	const decoded = jwtDecode(accessToken);

	const currentTime = Date.now() / 1000;

	return decoded.exp > currentTime;
};

export const tokenExpired = (exp) => 
{
	// eslint-disable-next-line prefer-const
	let expiredTimer;

	const currentTime = Date.now();

	// Test token expires after 10s
	// const timeLeft = currentTime + 10000 - currentTime; // ~10s
	const timeLeft = exp * 1000 - currentTime;

	clearTimeout(expiredTimer);

	expiredTimer = setTimeout(() => 
	{
		confirmAlert({
			title: "Aviso",
			message: "Sua sessão de usuário expirou. Faça o login novamente",
			buttons: [
				{ 
					label: "Ok", 
					onClick: () => 
					{
						localStorage.removeItem(APP_ALIAS + '-accessToken');
						window.location.href = PATH_AUTH.login;
					}
				}
			]
		})
	}, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => 
{
	if (accessToken) 
	{
		localStorage.setItem(APP_ALIAS + '-accessToken', accessToken);

		axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
		// console.log("jwtDecode", jwtDecode(accessToken))
		// This function below will handle when token is expired
		const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
		tokenExpired(exp);
	} 
	else 
	{
		localStorage.removeItem(APP_ALIAS + '-accessToken');

		delete axios.defaults.headers.common.Authorization;
	}
};

export const getSessionLocal = () => 
{
	const accessToken = localStorage.getItem(APP_ALIAS + '-accessToken');

	return jwtDecode(accessToken); 
};

export const getSessionLocalEncoded = () => localStorage.getItem(APP_ALIAS + '-accessToken');