import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';

import { fNumber } from 'src/utils/formatNumber';
import Chart, { useChart } from 'src/components/chart';

const CHART_HEIGHT = 400;

const LEGEND_HEIGHT = 72;

const StyledChart = styled('div')(({ theme }) => (
{
	height: CHART_HEIGHT,
	marginTop: theme.spacing(5),
	'& .apexcharts-canvas svg': {
		height: CHART_HEIGHT,
	},
	'& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
		overflow: 'visible',
	},
	'& .apexcharts-legend': {
		height: LEGEND_HEIGHT,
		alignContent: 'center',
		position: 'relative !important',
		borderTop: `solid 1px ${theme.palette.divider}`,
		top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
	},
}));

Donut.propTypes = {
  chart: PropTypes.object,
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function Donut({ title, subheader, chart, height=280,...other }) 
{
	const theme = useTheme();

	const { colors, series, options, events } = chart;

	const chartSeries = series.map((i) => i.value);

	const chartOptions = useChart(
	{
		chart: {
			events: events ?? {},
			sparkline: {
				enabled: true,
			},
		},
		colors,
		labels: series.map((i) => i.label),
		stroke: { colors: [theme.palette.background.paper] },
		legend: { floating: true, horizontalAlign: 'center' },
		tooltip: {
			fillSeriesColor: false,
			y: {
				formatter: (value) => fNumber(value),
				title: {
					formatter: (seriesName) => `${seriesName}`,
				},
			},
		},
		plotOptions: {
			pie: {
				donut: {
					size: '90%',
					labels: {
						value: {
							formatter: (value) => fNumber(value),
						},
						total: {
							formatter: (w) => {
								const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
								return fNumber(sum);
							},
						},
					},
				},
			},
		},
		...options,
	});

	return (
		<Card {...other}>
			<CardHeader title={title} subheader={subheader} />
			<StyledChart dir="ltr">
				<Chart type="donut" series={chartSeries} options={chartOptions} height={height} />
			</StyledChart>
		</Card>
	);
}
