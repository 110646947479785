import React, { memo } from 'react';
import Label from 'src/components/label';

const LoadStatus = memo(({ value }) => {
    let color = 'success';

    switch (value) {
        case 'Vazio':
            color = 'info';
            break;
        case 'Indefinido':
            color = 'warning';
            break;
        default:
            break;
    }

    return value ? <Label color={color}>{value}</Label> : null;
});

export default LoadStatus;

