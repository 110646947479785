import * as Util from '../../helpers/utils';

let Api = {
    getRows: async () =>
    {
        const endpoint = '/usuario/listar';

		return Util.fetchDataOn(endpoint);
    },
	getRow: async (id_user) =>
    {
        const endpoint = `/usuario/obter/${id_user}`;

		return Util.fetchDataOn(endpoint);
    },
	getDelete: async (id_user) =>
    {
        const endpoint = `/usuario/remover/${id_user}`;

		return Util.fetchDataOn(endpoint);
    },
	getSession: async () =>
	{
		const endpoint = '/usuario/sessao';

		return await Util.fetchDataOn(endpoint);
	},
	logoff: async () =>
	{
		const endpoint = '/usuario/logoff';

		return await Util.fetchDataOn(endpoint, {origem: "Web"});
	},
	changeMyPwd: async (password) =>
	{
		const endpoint = '/usuario/sessao/senha/trocar';

		return await Util.fetchDataOn(endpoint, JSON.stringify({senha: password}));
	},
	changePwd: async (id_user, password) =>
	{
		const endpoint = `/usuario/senha/trocar/${id_user}`;

		return await Util.fetchDataOn(endpoint, JSON.stringify({senha: password}));
	}
}

export default Api;
