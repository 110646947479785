import React from 'react';
import Label from 'src/components/label/Label';
import { datetimeBr2Mysql, datetime_diff, now } from 'src/helpers/utils';

const TemperatureSensor = ({ value }) => {
    if (!value?.length) return null;

    const [rawTemperature, rawDate] = value.split('C');
    const temperature = parseFloat(rawTemperature.replace("º", "").trim());
    const datahora = rawDate.trim();
    const datetime = datetimeBr2Mysql(datahora);
    const diff = datetime_diff(datetime, now());

    let color = "success";
    let title = datahora;

    if (diff > 3600) {
        color = diff > 7200 ? "error" : "warning";
        title = `Desatualizado (${datahora})`;
    }

    return temperature > -125 ? (
        <Label title={title} color={color}>
            {temperature}°C
        </Label>
    ) : null;
};

export default TemperatureSensor;

