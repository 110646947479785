import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stack, Pagination } from '@mui/material';
import { DataGrid, gridPageCountSelector, gridPageSelector, ptBR, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import Iconify from 'src/components/iconify/Iconify';
import EmptyContent from 'src/components/empty-content/EmptyContent';
import { APP_PATH_MAIN } from 'src/config/constants';

function SortedDescendingIcon() { 
    return <Iconify icon="typcn:arrow-sorted-down" />; 
}

function SortedAscendingIcon() { 
    return <Iconify icon="typcn:arrow-sorted-up" />; 
}

function Empty() { 
    return <EmptyContent title="Nenhum registro foi encontrado" img={`${APP_PATH_MAIN}/assets/illustrations/illustration_empty_mail.svg`} />; 
}

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Stack spacing={1} sx={{ mt: 2, mr: 1, ml: 1, mb: 1 }}>
            <Pagination
                color="primary"
                variant="outlined"
                shape="rounded"
                page={page + 1}
                count={pageCount}
                onChange={(event, value) => apiRef.current.setPage(value - 1)} 
            />
        </Stack>
    );
}

const CustomDataGrid = ({
    columns = [],
    data = null,
    height = 400,
    loading = false,
    filtered,
    isCompact = false,
    style,
    toolbar,
    initialState,
    onRowClick = () => null,
    onCellKeyDown = () => null
}) => {
    const [dataTable, setDataTable] = useState([]);

    useEffect(() => {
        if (data) {
            const { status, result } = data;
            if (status) {
                const normalizedData = (filtered || result).map((row, i) => ({ ...row, id: i }));
                setDataTable(normalizedData);
            }
        } else {
            setDataTable([]);
        }
    }, [data, filtered]);

    return (
        <div style={{ height: height, width: '100%' }}>
            <DataGrid
                className="bg-light"
                initialState={initialState}
                style={style}
                rows={dataTable}
                columns={columns
                    .filter(col => col.visible !== false)
                    .map(({ id, title, width = 60, sortable = true, renderCell, hide }) => ({
                        field: id,
                        headerName: title,
                        width,
                        sortable,
                        renderCell,
                        hide,
                    }))
                }
                disableColumnMenu
                loading={loading}
                onRowClick={onRowClick}
                onCellKeyDown={onCellKeyDown}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                density={isCompact ? 'compact' : 'standard'} 
                components={{ 
                    Toolbar: toolbar,
                    ColumnSortedDescendingIcon: SortedDescendingIcon,
                    ColumnSortedAscendingIcon: SortedAscendingIcon,
                    NoRowsOverlay: Empty,
                    NoResultsOverlay: Empty,
                    Footer: CustomPagination,
                }} 
                componentsProps={{
                    toolbar: toolbar && {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </div>
    );
};

CustomDataGrid.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.object, 
    height: PropTypes.string,
    loading: PropTypes.bool,
    filtered: PropTypes.array,
    isCompact: PropTypes.bool,
    toolbar: PropTypes.func,
    style: PropTypes.object,
    onRowClick: PropTypes.func,
    onCellKeyDown: PropTypes.func
};

export default CustomDataGrid;

