import React, { memo } from 'react';
import { useNavigate } from 'react-router';
import { Stack, useTheme } from '@mui/material';

import { IconButton } from 'src/components/Ca2';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { isNull } from 'lodash';

const Reference = memo(({ item }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    if (item.row.cod_referencia === 0) {
        return <span title={item.value}>{item.value}</span>;
    }

    if (isNull(item.row.referencia)) {
        return null;
    }

    return (
        <Stack flexDirection="row" alignContent="center" alignItems="center">
            <IconButton
                title="Rastrear outros veículos nesta referência"
                icon="gg:track"
                color={theme.palette.warning.main}
                onClick={() =>
                    navigate(PATH_DASHBOARD.tracking.view('reference', item.row.cod_referencia))
                }
            />
            <span title={item.value}>{item.value}</span>
        </Stack>
    );
});

export default Reference;

