import React, { createContext, useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router';
import { sumBy, groupBy } from 'lodash';

import useShortcutHandlers from './useShortcutHandlers';
import useTripData from './useTripData';
import useMapState from './useMapState';
import useMenu from './useMenu';
import useColumnsUser from './useColumnsUser';
import useTrackingData from './useTrackingData';

export const TrackingContext = createContext({});

const initialState = {
    isLoading: false,
    message: '',
    isError: false,
};

function stateDataReducer(state, action) {
    switch (action.type) {
        case 'LOADING':
            return { ...state, isLoading: true, message: action.payload, isError: false };
        case 'SUCCESS':
            return { ...state, isLoading: false, message: '', isError: false };
        case 'ERROR':
            return { ...state, isLoading: false, message: action.payload, isError: true };
        default:
            return state;
    }
}

// Funções auxiliares
const defineLastOccurrences = (counters, lastOccurrences, setLastOccurrences) => {
    const updatedOccurrences = [...lastOccurrences, counters.occurrences];
    if (updatedOccurrences.length > counters.occurrences) updatedOccurrences.shift();
    setLastOccurrences(updatedOccurrences);
};

const getCounters = (result) => {
    const groupAndCount = (items, key) => {
        const grouped = groupBy(items, key);
        return Object.keys(grouped).map(group => ({
            label: group,
            value: grouped[group].length
        }));
    };

    return {
        vehicles: {
            _private: result.filter(item => item.contrato === 'Fixo').length,
            added: result.filter(item => item.contrato === 'Agregado').length,
            third: result.filter(item => item.contrato === 'Terceiro').length,
        },
        trips: {
            full: result.filter(item => item.cv === 'Carregado').length,
            empty: result.filter(item => item.contrato === 'Vazio').length,
            _undefined: result.filter(item => item.contrato === 'Indefinido').length,
        },
        systems: groupAndCount(result, "sistema"),
        operationKind: groupAndCount(result, "tipooperacao"),
        logisticalStatus: groupAndCount(result.filter(item => item.status_logistico !== " - "), "status_logistico"),
        pgr: groupAndCount(result, "pgr"),
        occurrences: sumBy(result, (o) => o.conta_pend_oc),
        signalLoss: groupAndCount(result, "evt_003"),
    };
};

export const TrackingProvider = ({ children }) => {
    const params = useParams();  // Obtém os parâmetros da rota
    const { menu, permissions } = useMenu();
    const { columns } = useColumnsUser(); 
    const { data, isLoading, isFetching, isError, error, refetch } = useTrackingData();
    //const { data, isLoading, isFetching, isError, error, refetch } = useSocketTrackingData();

    const [stateData, dispatch] = useReducer(stateDataReducer, initialState);
    const [selectedRow, setSelectedRow] = useState(null);
    const [windowState, setWindowState] = useState("normal"); // Estado da janela
    const [cameras, setCameras] = useState([]); // Estado das câmeras
    const [openDrawer, setOpenDrawer] = useState(false); // Estado do drawer
    const [openDialogDetails, setOpenDialogDetails] = useState(false); // Estado do dialog de detalhes
    const [openDialogReport, setOpenDialogReport] = useState(false); // Estado do dialog de relatório
    const [openDatagramModal, setOpenDatagramModal] = useState(false); // Estado do modal de datagrama
    const [pressedEnter, setPressedEnter] = useState(false); // Estado para capturar enter pressionado
    const [dataDetails] = useState(null); // Dados dos detalhes
    const { trip, setTrip, getTrip } = useTripData(dispatch);
    const { markers, nearbyVehicles, setNearbyVehicles, nearbyMarkers, setNearbyMarkers, lastPositions, setLastPositions, place, setPlace } = useMapState(trip, setTrip, selectedRow, dispatch);
    const { onCellKeyDown } = useShortcutHandlers(selectedRow, setSelectedRow, getTrip, trip, setTrip, setOpenDialogDetails, setOpenDialogReport);

    const [counters, setCounters] = useState({
        vehicles: { _private: 0, added: 0, third: 0 },
        trips: { full: 0, empty: 0, _undefined: 0 },
        occurrences: 0,
        signalLoss: [],
    });

    const [lastOccurrences, setLastOccurrences] = useState([]);

    useEffect(() => {
        setSelectedRow(null);
        refetch();
    }, [params]);  // Reexecuta o efeito quando os parâmetros da rota mudam

    useEffect(() => {
        defineData();
    }, [data]);

    const defineData = () => {
        if (data && data.status) {
            const calculatedCounters = getCounters(data.result);
            setCounters(calculatedCounters);
            defineLastOccurrences(calculatedCounters, lastOccurrences, setLastOccurrences);
            updateSelectedRow(data.result);
        }
    };

    const updateSelectedRow = (result) => {
        if (selectedRow) {
            const updatedRow = result.find(item => item.cod_veiculo === selectedRow.row.cod_veiculo);
            if (selectedRow.row.datahora !== updatedRow?.datahora) {
                setSelectedRow({ ...selectedRow, row: updatedRow });
            }
        }
    };

    return (
        <TrackingContext.Provider value={{
            data, isLoading, isFetching, isError, error, refetch,
            stateData, dispatch,
            trip, setTrip, getTrip,
            markers, nearbyVehicles, setNearbyVehicles, nearbyMarkers, setNearbyMarkers, lastPositions, setLastPositions,
            menu, permissions,
            columns,
            selectedRow, setSelectedRow,
            counters,
            lastOccurrences,
            windowState, setWindowState, // Adicionado windowState ao contexto
            place, setPlace,
            cameras, setCameras, // Adicionado cameras ao contexto
            openDrawer, setOpenDrawer, // Adicionado openDrawer ao contexto
            openDialogDetails, setOpenDialogDetails, // Adicionado openDialogDetails ao contexto
            openDialogReport, setOpenDialogReport, // Adicionado openDialogReport ao contexto
            openDatagramModal, setOpenDatagramModal, // Adicionado openDatagramModal ao contexto
            pressedEnter, setPressedEnter, // Adicionado pressedEnter ao contexto
            dataDetails, // Adicionado dataDetails ao contexto
            onCellKeyDown,
        }}>
            {children}
        </TrackingContext.Provider>
    );
};

