import { APP_PATH_MAIN } from "src/config/constants";

const path = (root, sublink) => `${root}${sublink}`;
const ROOTS_DASHBOARD = '';

export const PATH_AUTH = {
    login: `${APP_PATH_MAIN}/login`,
};

const createPaths = (root, subpaths) => {
    const result = {};
    for (const [key, value] of Object.entries(subpaths)) {
        result[key] = path(root, value);
    }
    return result;
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    _403: {
        root: path(ROOTS_DASHBOARD, '/403')
    },
    account: createPaths(ROOTS_DASHBOARD, {
        root: '/account',
        list: '/account/list',
        insert: '/account/insert',
        edit: '/account/edit/:id',
        delete: '/account/delete/:id',
        duplicate: '/account/duplicate/:id',
    }),
    bot: createPaths(ROOTS_DASHBOARD, {
        root: '/bot',
        list: '/bot/list',
        insert: '/bot/insert',
        edit: '/bot/edit/:id',
        telegramStatus: '/bot/telegram-status/:id',
    }),
    client: createPaths(ROOTS_DASHBOARD, {
        root: '/client',
        list: '/client/list',
        insert: '/client/insert',
        edit: '/client/edit/:id',
        defaultGroups: '/client/defaultGroups/:id',
        macros: '/client/macros/:id',
        config: '/client/config/:id',
    }),
    command: createPaths(ROOTS_DASHBOARD, {
        root: '/command',
        list: '/command/list',
        listGenericCommand: '/command/list/genericCommand/:id',
        insert: '/command/insert',
        edit: '/command/edit/:id',
        lastsent: '/command/lastsent',
    }),
    contact: createPaths(ROOTS_DASHBOARD, {
        root: '/contact',
        list: '/contact/list',
        insert: '/contact/insert',
        edit: '/contact/edit/:id',
        delete: '/contact/delete/:id',
        contactsGroup: '/contact/contactGroup/:id',
    }),
    contactGroup: createPaths(ROOTS_DASHBOARD, {
        root: '/contactGroup',
        list: '/contactGroup/list',
        insert: '/contactGroup/insert',
        edit: '/contactGroup/edit/:id',
        contacts: '/contactGroup/contacts/:id',
    }),
    dashboard: createPaths(ROOTS_DASHBOARD, {
        root: '/dashboard',
        list: '/dashboard/list',
    }),
    driver: createPaths(ROOTS_DASHBOARD, {
        root: '/driver',
        list: '/driver/list',
        insert: '/driver/insert',
        edit: '/driver/edit/:id',
        editApp: '/driver/edit/app/:id',
    }),
    fence: createPaths(ROOTS_DASHBOARD, {
        root: '/fence',
        list: '/fence/list',
        insert: '/fence/insert',
        edit: '/fence/edit/:id',
        vehicles: '/fence/vehicles/:id',
        within: '/fence/vehicle/within/:id',
        reportWithin: '/fence/vehicle/within/report/:id',
    }),
    event: {
        root: path(ROOTS_DASHBOARD, '/event'),
        list: path(ROOTS_DASHBOARD, '/event/list'),
        new: path(ROOTS_DASHBOARD, '/event/list/new'),
        view: (cod_rastreador) => path(ROOTS_DASHBOARD, `/event/list/${cod_rastreador}`),
    },
    genericCommand: createPaths(ROOTS_DASHBOARD, {
        root: '/genericCommand',
        list: '/genericCommand/list',
        insert: '/genericCommand/insert',
        edit: '/genericCommand/edit/:id',
    }),
    genericCommandPackage: createPaths(ROOTS_DASHBOARD, {
        root: '/genericCommandPackage',
        list: '/genericCommandPackage/list/:id',
    }),
    grid: createPaths(ROOTS_DASHBOARD, {
        root: '/grid',
        list: '/grid/list',
        insert: '/grid/insert',
        edit: '/grid/edit/:id',
        columns: '/grid/columns/:id',
        users: '/grid/users/:id',
    }),
    group: createPaths(ROOTS_DASHBOARD, {
        root: '/group',
        list: '/group/list',
        insert: '/group/insert',
        edit: '/group/edit/:id',
        autoreport: '/group/edit/auto-report/:id',
        users: '/group/users/:id',
        vehicles: '/group/vehicles/:id',
        sendMessage: '/group/send-message/:id',
        reportSpeed: '/group/report/speed/:id',
        heatMapEventType: '/group/heatmap/eventtype/:id',
    }),
    idleTime: createPaths(ROOTS_DASHBOARD, {
        root: '/idletime',
        list: '/idletime/list',
    }),
    import: createPaths(ROOTS_DASHBOARD, {
        root: '/import',
        upload: '/import/upload',
    }),
    inhibition: createPaths(ROOTS_DASHBOARD, {
        root: '/inhibition',
        list: '/inhibition/list',
        listVehicle: '/inhibition/list/vehicle/:id',
        listTracker: '/inhibition/list/tracker/:id',
        insert: '/inhibition/insert',
        insertById: '/inhibition/insert/vehicle/:id',
        edit: '/inhibition/edit/:id',
        delete: '/inhibition/delete/:id',
    }),
    insurer: createPaths(ROOTS_DASHBOARD, {
        root: '/insurer',
        list: '/insurer/list',
        insert: '/insurer/insert',
        edit: '/insurer/edit/:id',
    }),
    insurance: createPaths(ROOTS_DASHBOARD, {
        root: '/insurance',
        list: '/insurance/list',
        insert: '/insurance/insert',
        edit: '/insurance/edit/:id',
    }),
    kinds: createPaths(ROOTS_DASHBOARD, {
        root: '/kind',
        kindOfEvent: '/kind/kindOfEvent',
        kindOfMarker: '/kind/kindOfMarker',
        kindOfOccurrence: '/kind/kindOfOccurrence',
        kindOfOperation: '/kind/kindOfOperation',
        kindOfRisk: '/kind/kindOfRisk',
        kindOfTruckBody: '/kind/kindOfTruckBody',
        kindOfUser: '/kind/kindOfUser',
    }),
    kindOfEvent: createPaths(ROOTS_DASHBOARD, {
        root: '/kindOfEvent',
        list: '/kindOfEvent/list',
        insert: '/kindOfEvent/insert',
        edit: '/kindOfEvent/edit/:id',
        markReadByKind: '/kindOfEvent/mark-read-by-kind/:id',
        riskManagement: '/kindOfEvent/riskManagement/:id',
    }),
    kindOfMarker: createPaths(ROOTS_DASHBOARD, {
        root: '/kindOfMarker',
        list: '/kindOfMarker/list',
        insert: '/kindOfMarker/insert',
        edit: '/kindOfMarker/edit/:id',
    }),
    kindOfOccurrence: createPaths(ROOTS_DASHBOARD, {
        root: '/kindOfOccurrence',
        list: '/kindOfOccurrence/list',
        insert: '/kindOfOccurrence/insert',
        edit: '/kindOfOccurrence/edit/:id',
    }),
    kindOfOperation: createPaths(ROOTS_DASHBOARD, {
        root: '/kindOfOperation',
        list: '/kindOfOperation/list',
        insert: '/kindOfOperation/insert',
        edit: '/kindOfOperation/edit/:id',
    }),
    kindOfRisk: createPaths(ROOTS_DASHBOARD, {
        root: '/kindOfRisk',
        list: '/kindOfRisk/list',
        insert: '/kindOfRisk/insert',
        edit: '/kindOfRisk/edit/:id',
    }),
    kindOfTruckBody: createPaths(ROOTS_DASHBOARD, {
        root: '/kindOfTruckBody',
        list: '/kindOfTruckBody/list',
        insert: '/kindOfTruckBody/insert',
        edit: '/kindOfTruckBody/edit/:id',
    }),
    kindOfUser: createPaths(ROOTS_DASHBOARD, {
        root: '/kindOfUser',
        list: '/kindOfUser/list',
        insert: '/kindOfUser/insert',
        edit: '/kindOfUser/edit/:id',
        duplicate: '/kindOfUser/duplicate/:id',
        delete: '/kindOfUser/delete/:id',
        menuitem: '/KindOfUser/MenuItem/:id',
        kindOfEvents: '/KindOfUser/KindOfEvents/:id',
    }),
    macro: createPaths(ROOTS_DASHBOARD, {
        root: '/macro',
        list: '/macro/list',
        insert: '/macro/insert',
        edit: '/macro/edit/:id',
        delete: '/macro/delete/:id',
    }),
    macro_item: createPaths(ROOTS_DASHBOARD, {
        root: '/macro_item',
        list: '/macro_item/list/:id',
        insertById: '/macro_item/macro/insert/:id',
        edit: '/macro_item/edit/:id',
        delete: '/macro_item/delete/:id',
    }),
    map: createPaths(ROOTS_DASHBOARD, {
        root: '/map',
        markerViewer: '/map/markerViewer',
        tripTester: '/map/tripTester',
    }),
    mapserver_api: createPaths(ROOTS_DASHBOARD, {
        root: '/mapserver',
        list: '/mapserver/list',
        insert: '/mapserver/insert',
        edit: '/mapserver/edit/:id',
    }),
    message: {
        root: path(ROOTS_DASHBOARD, '/message'),
        list: path(ROOTS_DASHBOARD, '/message/list'),
        new: path(ROOTS_DASHBOARD, '/message/list/new'),
        view: (cod_rastreador) => path(ROOTS_DASHBOARD, `/message/list/${cod_rastreador}`),
    },
    occurrence: createPaths(ROOTS_DASHBOARD, {
        root: '/occurrence',
        list: '/occurrence/list',
        listVehicle: '/occurrence/list/vehicle/:id',
        insert: '/occurrence/insert',
        insertf7: '/occurrence/insert/:vehicle_id',
        edit: '/occurrence/edit/:id',
        read: '/occurrence/read/:id',
    }),
    platform: createPaths(ROOTS_DASHBOARD, {
        root: '/platform',
        list: '/platform/list',
        insert: '/platform/insert',
        edit: '/platform/edit/:id',
    }),
    procedure: createPaths(ROOTS_DASHBOARD, {
        root: '/procedure',
        list: '/procedure/list',
        insert: '/procedure/insert',
        edit: '/procedure/edit/:id',
    }),
    reference: createPaths(ROOTS_DASHBOARD, {
        root: '/reference',
        list: '/reference/list',
        insert: '/reference/insert',
        save: '/reference/insert/:lat/:lng/:name',
        edit: '/reference/edit/:id',
        kindOfEvent: '/reference/kindOfEvent/:id',
        tracking: '/reference/tracking/:id',
        trigger: '/reference/trigger/:id',
        latLng: '/reference/latlng/:id',
    }),
    report: createPaths(ROOTS_DASHBOARD, {
        root: '/report',
        event: '/report/event/:id',
        position: '/report/position/:id',
        trip: '/report/trip/:id',
        temperatureControl: '/report/temperature/control/:id'
    }),
    riskManagement: createPaths(ROOTS_DASHBOARD, {
        root: '/risk-management',
        list: '/risk-management/list',
        listClient: '/risk-management/list/client/:id',
        insert: '/risk-management/insert',
        edit: '/risk-management/edit/:id',
        duplicate: '/risk-management/duplicate/:id',
        delete: '/risk-management/delete/:id',
    }),
    riskManagementEvents: createPaths(ROOTS_DASHBOARD, {
        root: '/risk-management-events',
        list: '/risk-management-events/list',
        manager: '/risk-management-events/manager/:id',
        edit: '/risk-management-events/edit/:id',
    }),
    scheduledTrip: createPaths(ROOTS_DASHBOARD, {
        root: '/scheduled-trip',
        list: '/scheduled-trip/list',
        insert: '/scheduled-trip/insert',
        edit: '/scheduled-trip/edit/:id',
        confirm: '/scheduled-trip/confirm/:id',
    }),
    temperatureRange: createPaths(ROOTS_DASHBOARD, {
        root: '/temperatureRange',
        list: '/temperatureRange/list',
        insert: '/temperatureRange/insert',
        edit: '/temperatureRange/edit/:id',
    }),
    test: createPaths(ROOTS_DASHBOARD, {
        root: '/test',
        test: '/test/test',
        socket: '/test/socket',
        testjig: '/tracker/testjig',
    }),
    tracker: createPaths(ROOTS_DASHBOARD, {
        root: '/tracker',
        list: '/tracker/list',
        listClient: '/tracker/list/client/:id',
        insert: '/tracker/insert',
        edit: '/tracker/edit/:id',
        delete: '/tracker/delete/:id',
        testjig: '/tracker/testjig',
        log: '/tracker/log/:id',
        debug: '/tracker/debug/:id',
    }),
    trackerSystem: createPaths(ROOTS_DASHBOARD, {
        root: '/tracker-system',
        list: '/tracker-system/list',
        edit: '/tracker-system/edit/:id',
        product: '/tracker-system/product/:id',
    }),
    tracking: {
        root: path(ROOTS_DASHBOARD, '/tracking'),
        list: path(ROOTS_DASHBOARD, '/tracking/list'),
        view: (type, keyWord) => path(ROOTS_DASHBOARD, `/tracking/list/${type}/${keyWord}`),
    },
    trailer: createPaths(ROOTS_DASHBOARD, {
        root: '/trailer',
        list: '/trailer/list',
        insert: '/trailer/insert',
        edit: '/trailer/edit/:id',
    }),
    transittime: createPaths(ROOTS_DASHBOARD, {
        root: '/transittime',
        list: '/transittime/list',
    }),
    trip: createPaths(ROOTS_DASHBOARD, {
        root: '/trip',
        list: '/trip/list',
        insert: '/trip/insert/:id',
        cancel: '/trip/cancel',
        finish: '/trip/finish',
    }),
    user: createPaths(ROOTS_DASHBOARD, {
        root: '/user',
        list: '/user/list',
        insert: '/user/insert',
        edit: '/user/edit/:id',
        editKindOfUser: '/user/edit/KindOfUser/:id',
        editGroup: '/user/edit/group/:id',
        editGrid: '/user/edit/grid/:id',
        delete: '/user/delete/:id',
        groups: '/user/groups/:id',
        grids: '/user/grids/:id',
        logout: '/user/logout',
        log: '/user/log/:id',
        secret: '/user/secret/:id',
    }),
    vehicle: createPaths(ROOTS_DASHBOARD, {
        root: '/vehicle',
        list: '/vehicle/list',
        listClient: '/vehicle/list/client/:id',
        insert: '/vehicle/insert',
        edit: '/vehicle/edit/:id',
        trackers: '/vehicle/trackers/:id',
        groups: '/vehicle/groups/:id',
        trailers: '/vehicle/trailers/:id',
        mercosul: '/vehicle/mercosul/:id',
        log: '/vehicle/log/:id',
    }),
};

