import { fetchDataOn } from "src/helpers/utils";

let Api = {
	getRows: async () =>
    {
		const endpoint = '/menu/listar';
		const body = { tipo: "ca2track" }
		return await fetchDataOn(endpoint, body);
    },
}

export default Api;