// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const defaultSettings = {
    themeMode: 'dark',
    themeDirection: 'ltr',
    themeContrast: 'default',
    themeLayout: 'vertical',
    themeColorPresets: 'cyan', 
    themeStretch: false,
};