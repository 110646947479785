import React from 'react'
import PropType from 'prop-types';
import { IconButton, Tooltip } from '@mui/material'
import Iconify from 'src/components/iconify'

const Index = ({title, icon, color, isVisible=true, disabled=false,  onClick, ...others}) => 
{
    return isVisible &&
    <div {...others}>
        {
            disabled
            ? <IconButton onClick={onClick} disabled>
                <Iconify icon={icon} color={color}/>
            </IconButton>
            : <Tooltip title={title}>
                <IconButton onClick={onClick} >
                    <Iconify icon={icon} color={color}/>
                </IconButton>
            </Tooltip>
        }
    </div>
}

Index.prototype = {
    title: PropType.string,
    icon: PropType.string,
    color: PropType.string,
    isVisible: PropType.bool,
    false: PropType.bool,
    onClick: PropType.func,
}

export default Index