import React from 'react';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router';

import useResponsive from 'src/hooks/useResponsive';
import { toEncrypt } from 'src/helpers/utils';

import { IconButton, MenuButton } from 'src/components/Ca2';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useCrud } from 'src/context/CrudProvider/useCrud';

const Toolbar = ({ onOpenNav, ...other }) => {
    const navigate = useNavigate();

    const { id, refetch, selectedRow, cod_pk_name, optionsButtonsList } = useCrud();

    const smUp = useResponsive('up', 'sm');
    const mdUp = useResponsive('up', 'md');

    const getNameSelected = () => {
        if (!selectedRow) return '';
        
        const idList = {
            vehicle: 'placa',
            tracker: 'cb',
        };
        
        const colName = idList[id];
        return colName ? selectedRow.row[colName]?.toUpperCase() : '';
    };

    const handleEditClick = () => {
        const path = PATH_DASHBOARD[id].edit.replace(':id', toEncrypt(selectedRow.row[cod_pk_name].toString()));
        navigate(path);
    };

    const handleMenuClick = (item) => {
        const { path, cod_pk } = item;
        navigate(path.replace(':id', toEncrypt(selectedRow.row[cod_pk].toString())));
    };

    return (
        <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            flexShrink={0}
            sx={{
                px: 2,
                height: 80,
                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
            }}
            {...other}
        >
            <Stack direction="row" alignItems="center" flexGrow={1}>
                <IconButton
                    title="Menu"
                    icon="eva:menu-fill"
                    isVisible={!mdUp}
                    onClick={onOpenNav}
                />
                <IconButton
                    title="Atualizar Dados"
                    icon="eva:refresh-fill"
                    isVisible={smUp}
                    onClick={refetch}
                />
                {selectedRow && (
                    <>
                        <IconButton
                            title={`Editar ${getNameSelected()}`}
                            icon="akar-icons:edit"
                            onClick={handleEditClick}
                        />
                        {optionsButtonsList.length > 0 && (
                            <MenuButton
                                id="more"
                                title="Mais"
                                icon="eva:more-vertical-fill"
                                items={optionsButtonsList.map((item) => ({
                                    title: item.title,
                                    type: item.type ?? 'menu',
                                    color: item.color,
                                    onClick: () => handleMenuClick(item),
                                }))}
                            />
                        )}
                    </>
                )}
            </Stack>
            <GridToolbarQuickFilter
                variant="outlined"
                size="small"
                placeholder="Pesquisar"
            />
        </Stack>
    );
};

export default Toolbar;

