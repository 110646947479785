import React, { memo } from 'react';

import Label from 'src/components/label';
import { datetime2br } from 'src/helpers/utils';

const LogisticalStatus = memo(({ row }) => {
    let title = '';

    if (row?.statusVgm === 'Com Viagem') {
        title = `Prev. Chegada Atualizada: ${datetime2br(row?.transittime_previsao_chegada_revisado)}`;
    } else if (row?.statusVgm === 'Sem Viagem') {
        title = row?.status_logistico;
    }

    let color = 'secondary';

    switch (row?.status_logistico) {
        case 'Dentro do Prazo':
            color = 'success';
            break;
        case 'Em Trânsito':
            color = 'info';
            break;
        case 'Com Atrasado':
            color = 'warning';
            break;
        default:
            break;
    }

    return row?.status_logistico ? (
        <span title={title}>
            <Label color={color}>{row?.status_logistico}</Label>
        </span>
    ) : null;
});

export default LogisticalStatus;

