import React, { useState } from 'react';
import { Container, Card, Stack } from '@mui/material';
import { useNavigate } from 'react-router';

import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useCrud } from 'src/context/CrudProvider/useCrud';
import { IconButton } from '..';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import CrudNav from './CrudNav';
import CrudList from './CrudList';

const Main = () => {
	const navigate = useNavigate();
    const { id, title, navItemsFilter } = useCrud();
    const { themeStretch } = useSettingsContext();

    const [openNav, setOpenNav] = useState(false);
	const [_, setOpenCompose] = useState(false);

    const toggleOpenCompose = () => setOpenCompose(true);
    const toggleOpenNav = () => setOpenNav(!openNav);

    return (
        <Container maxWidth={themeStretch ? false : 'xl'}>
            <Stack direction="row" justifyContent="space-between">
				<CustomBreadcrumbs
					heading={title}
					links={[  
						{
							name: 'Dashboard',
							href: PATH_DASHBOARD.root,
						},
						{ 
							name: title,
							href: PATH_DASHBOARD[id]?.list,
						},
					]}
				/>
				<IconButton icon="ri:arrow-go-back-fill" title="Voltar à tela anterior" onClick={() => navigate(-1)} />
			</Stack>
            <Card
				sx={{
					height: '80vh',
					display: 'flex',
				}}
			>
                <CrudNav 
					items={navItemsFilter} 
					openNav={openNav} 
					onCloseNav={toggleOpenNav} 
					onOpenCompose={toggleOpenCompose} 
				/>
                <Stack flexGrow={1}>
                    <CrudList onOpenNav={toggleOpenNav}/>
                </Stack>
            </Card>
        </Container>
    );
}

export default Main;

