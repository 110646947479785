import Router from './routes';
import ThemeProvider from './theme';
import ThemeLocalization from './locales';

import SnackbarProvider from './components/snackbar';
import { StyledChart } from './components/chart';
import { ThemeSettings } from './components/settings';
import { MotionLazyContainer } from './components/animate';

import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/store';

export default function App() 
{
	return (
		<MotionLazyContainer>
			<ThemeProvider>
				<ThemeSettings>
					<ThemeLocalization>
						<SnackbarProvider>
							<ReduxProvider store={store}>
								<StyledChart />
								<Router />
							</ReduxProvider>
						</SnackbarProvider>
					</ThemeLocalization>
				</ThemeSettings>
			</ThemeProvider>
		</MotionLazyContainer>
	);
}
