import React from 'react';
import { Button, Grid, Stack, TextField } from '@mui/material';
import { IconButton } from '..';
import { isArray } from 'lodash';

const TextArray = React.memo(({
    id,
    label,
    placeholder,
    style,
    value = [],
    onChange = () => null,
    ...others
}) => {

    const handleChange = (e, i) => {
        const valTmp = [...value];
        valTmp[i] = e.target.value;

        onChange({
            target: { 
                value: valTmp, 
                id, 
                name: id 
            }
        });
    };

    const addItem = () => {
        const valTmp = [...value, "novo item"];
        onChange({
            target: { 
                value: valTmp, 
                id, 
                name: id 
            }
        });
    };

    const removeItem = (i) => {
        const valTmp = [...value];
        valTmp.splice(i, 1);
        onChange({
            target: { 
                value: valTmp, 
                id, 
                name: id 
            }
        });
    };

    return (
        <Grid container spacing={2}>
            {isArray(value) && value.map((item, i) => (
                <Grid item xs={12} key={`${id}_${i}`}>
                    <Stack flexDirection="row" alignItems="center" spacing={2}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label={`${label} #${i + 1}`}
                            placeholder={placeholder}
                            onChange={(e) => handleChange(e, i)}
                            style={style}
                            value={item}
                            {...others}
                        />
                        <IconButton 
                            icon="material-symbols:add-box-outline" 
                            title="Adicionar"
                            onClick={addItem}
                        />
                        <IconButton 
                            icon="mdi:minus-box-outline" 
                            title="Remover"
                            onClick={() => removeItem(i)}
                        />
                    </Stack>
                </Grid>
            ))}
            {!isArray(value) || value.length === 0 ? (
                <Button onClick={addItem} variant="contained" color="info">
                    {`Adicionar ${label}`}
                </Button>
            ) : null}
        </Grid>
    );
});

export default TextArray;

