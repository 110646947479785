import React from 'react';
import { InputAdornment, Stack, TextField, Typography } from '@mui/material';

import Iconify from 'src/components/iconify/Iconify';
import { useTransfer } from 'src/context/TransferProvider/useTransfer';

const ToolbarLeft = () =>
{
    const { titleSecondary, keyWordFilter, setKeyWordFilter } = useTransfer();

    return (
        <Stack sx={{ mb: 2 }} spacing={1}>
            <Typography variant='subtitle1'>{ titleSecondary } Disponíveis</Typography>
            <TextField
                id="transfer_filter"
                size="small"
                placeholder={`Pesquisar por ${titleSecondary} disponíveis`}
                value={keyWordFilter}
                onChange={(e) => setKeyWordFilter(e.target.value)}
                onDoubleClick={() => setKeyWordFilter('')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill"  />
                        </InputAdornment>
                    ),
                }}
            />            
        </Stack>        
    )
}

export default ToolbarLeft