import React from 'react';
import { TextField } from '@mui/material';

const InputText = React.memo(({
    id,
    type = 'text',
    label,
    placeholder,
    style,
    value = '',
    error,
    maxLength,
    disabled,
    required = false,
    step,
    tabIndex,
    onChange = () => null,
    helper,
    ...rest
}) => {
    return (
        <TextField
            variant="outlined"
            type={type}
            id={id}
            name={id}
            label={label}
            placeholder={placeholder}
            onChange={onChange}
            style={style}
            value={value}
            disabled={disabled}
            required={required}
            fullWidth
            error={error}
            tabIndex={tabIndex}
            helperText={helper}
            inputProps={{ maxLength, step }}
            {...rest}
        />
    );
});

export default InputText;

