import React, { memo } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';

import CircularProgressWithLabel from 'src/components/Ca2/DataGrid/CircularProgressWithLabel';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify/Iconify';

const ProgressTrip = memo(({ row }) => {
    const theme = useTheme();

    const renderItem = () => {
        if (!row?.andamentoCliente) {
            return null;
        }

        if (row.andamentoCliente.includes('%')) {
            let color = 'secondary';

            switch (row.status_logistico) {
                case 'Dentro do Prazo':
                    color = 'success';
                    break;
                case 'Com Atraso':
                    color = 'warning';
                    break;
                case 'Inversão de Rota':
                    color = 'error';
                    break;
                default:
                    break;
            }

            const value = parseFloat(row.andamentoCliente.replace('%', ''));

            return value > 0 ? (
                <CircularProgressWithLabel value={value} total={100} color={color} />
            ) : (
                <Label color="info">
                    <Typography noWrap variant="caption">
                        Aguardando...
                    </Typography>
                </Label>
            );
        }

        if (row.andamentoCliente.toLowerCase() === 'fora do alcance da rota principal') {
            return (
                <Iconify
                    icon="ic:sharp-warning"
                    title={row.andamentoCliente}
                    color={theme.palette.warning.main}
                />
            );
        }

        let color = 'secondary';
        if (row.andamentoCliente === 'a Finalizar') {
            color = 'success';
        }

        return (
            <span title={row.andamentoCliente}>
                <Label color={color}>
                    <Typography noWrap variant="caption">
                        {row.andamentoCliente}
                    </Typography>
                </Label>
            </span>
        );
    };

    return (
        <Stack
            title={row.andamentoCliente}
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%' }}
        >
            {renderItem()}
        </Stack>
    );
});

export default ProgressTrip;

