const { APP_FORM_DINAMICS_EDIT } = require("src/config/constants");
const { urlBase } = require("src/helpers/utils");

exports.transformType = (field) => {
    const { name, type } = field;

    switch (true) {
        case type === 'text':
            return { type: 'text-area' };
        
        case type.includes("varchar"):
            const maxLength = type.split('(')[1].replace(')', '');
            return { type: 'text', maxLength };

        case type === 'number':
        case type === 'money':
        case type === 'password':
        case type === 'time':
            return { type };
        
        case type.includes("enum("):
            return { type: 'enum', items: this.normalizeItemsFromEnumToSelect(field) };
        
        case type === 'int':
            return name.startsWith('cod_') ? { type: 'select-api' } : { type: 'text', maxLength: 11 };
        
        case type === "array":
            return { type: 'array' };

        default:
            return { type: 'unknown', size: 0 };
    }
};

exports.normalizeItemsFromEnumToSelect = (field) => {
    return field.items || field.type
        .replace("enum(", "")
        .replace(")", "")
        .replace(/[']+/g, '')
        .split(",")
        .map(item => ({ value: item.trim(), label: item.trim() }));
};

exports.getInitialValues = (fields, data) => {
    return fields.reduce((obj, field) => {
        obj[field.name] = data ? data[field.name] : '';
        return obj;
    }, {});
};

exports.getSelects = (fields) => {
    return fields.filter(({ type, name }) => type === 'int' && name.startsWith("cod_"));
};

exports.getField = (fields, name) => {
    return fields.find(field => field.name === name);
};

exports.getFieldMainFromChild = (selects, select) => {
    return selects.find(x => x.name === select.main);
};

exports.parseTableName = (table) => table === "pgr_minimo" ? "pgr" : table;

exports.getPermission = (params, field) => {
    const { insert, update } = field.permission;
    return APP_FORM_DINAMICS_EDIT.some(item => urlBase().includes(item)) ? update : insert;
};

exports.parseValuesToSend = (fields, values, params) => {
    return Object.keys(values).reduce((parsedValues, key) => {
        const field = this.getField(fields, key);
        if (this.getPermission(params, field)) {
            parsedValues[key] = values[key];
        }
        return parsedValues;
    }, {});
};

