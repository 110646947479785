import { APP_ALIAS } from "src/config/constants";
import { fetchDataOn, urlBase } from "src/helpers/utils";

const Api = {
    getRows: async () => {
        const endpoint = "/rastreamento/listar";
        const raw = { tipo: "desktop" };

        let res = await fetchDataOn(endpoint, raw);

        if (res.status && res.result.length) {
            applyLocalStorageFilters(res);
            applyUrlFilters(res);
        }

        return res;
    },

    getRowByVeiculo: async (cod_veiculo) => {
        const endpoint = `/rastreamento/obter/${cod_veiculo}`;
        const raw = { tipo: "desktop" };
        return await fetchDataOn(endpoint, raw);
    },

    paradaTempo: async () => {
        const endpoint = '/rastreamento/tempo/parado';
        return await fetchDataOn(endpoint);
    }
};

const applyLocalStorageFilters = (res) => {
    const hideNullPositions = localStorage.getItem(`${APP_ALIAS}-hideNullPositions`);

    if (hideNullPositions) {
        res.result = res.result.filter(row => row.posicao !== null);
    }
};

const applyUrlFilters = (res) => {
    const link = urlBase().split('/');
    
    if (link.length !== 8) return;

    const filter = link[6];
    const keyword = decodeURI(link[7]).toLowerCase();

    if (!filter || !keyword) return;

    switch (filter) {
        case "system":
            res.result = res.result.filter(item => item.sistema.toLowerCase() === keyword);
            break;
        case "logisticalStatus":
            res.result = res.result.filter(item => item.status_logistico.toLowerCase() === keyword);
            break;
        case "cv":
            res.result = res.result.filter(item => item.cv && item.cv.toLowerCase() === keyword);
            break;
        case "contract":
            res.result = res.result.filter(item => item.contrato.toLowerCase() === keyword.replace("privado", "fixo"));
            break;
        case "operationKind":
            res.result = res.result.filter(item => item.tipooperacao.toLowerCase() === keyword);
            break;
        case "pgr":
            res.result = res.result.filter(item => item.pgr.toLowerCase() === keyword);
            break;
        case "reference":
            res.result = res.result.filter(item => item.cod_referencia === +keyword);
            break;
        default:
            break;
    }
};

export default Api;

