import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import MenuPopover from 'src/components/menu-popover';
import Api from 'src/services/api/evento';

import { fToNow } from 'src/utils/formatTime';
import { IconButtonAnimate } from 'src/components/animate';
import { useEvents } from 'src/context/EventsProvider/useEvents';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { confirmAlert } from 'react-confirm-alert';
import { useQuery } from '@tanstack/react-query';

export default function EventsPopover() 
{
	const navigate = useNavigate();

	const { data, refetch } = useQuery(['events_last_5'], async () => await Api.getRowsLimit());
	const { totalEvents, markAllAsRead, markAsRead } = useEvents();

	const [rows, setRows] = useState([]);
	const [openPopover, setOpenPopover] = useState(null);

	const handleOpenPopover = (event) => setOpenPopover(event.currentTarget); 

	const handleClosePopover = () => setOpenPopover(null);

	const handleMarkAllAsRead = async () => 
	{
		handleClosePopover();

		confirmAlert(
		{
			title: 'Confirmação',
			message: 'Tem certeza que deseja marcar como lida TODOS os eventos?',
			buttons: [
				{ label: 'Sim', onClick: () => markAllAsRead() },
				{ label: 'Não' }
			]
		});
	};

	useEffect(() =>
	{
		if (data?.status)
			setRows(data.result);
	}, 
	[data]);

	useEffect(() =>
	{
		refetch();
	}, 
	// eslint-disable-next-line
	[totalEvents]);

	const handleSeeAll = () =>
	{
		handleClosePopover();
		navigate(PATH_DASHBOARD.event.list);
	}

	const handleMarkAsRead = async (item) =>  await markAsRead(item.cod_evento);
	
	return (
		<>
			<IconButtonAnimate
				color={openPopover ? 'primary' : 'default'}
				onClick={handleOpenPopover}
				sx={{ width: 40, height: 40 }}
			>
				<Badge badgeContent={totalEvents} color="warning">
					<Iconify icon="ic:outline-warning-amber" />
				</Badge>
			</IconButtonAnimate>
			<MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
				<Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
					<Box sx={{ flexGrow: 1 }}>
						<Typography variant="subtitle1">Eventos</Typography>
						<Typography variant="body2" sx={{ color: 'text.secondary' }}>
							{
								totalEvents === 0
								? "Nenhum evento foi encontrado"
								: `Você tem ${totalEvents} ${(totalEvents > 1 ? "eventos não lidos" : "evento não lido")}`
							}
							
						</Typography>
					</Box>
					{
						totalEvents > 0 && (
							<Tooltip title="Marcar todos como lidos">
								<IconButton color="primary" onClick={handleMarkAllAsRead}>
									<Iconify icon="eva:done-all-fill" />
								</IconButton>
							</Tooltip>
						)
					}
				</Box>
				<Divider sx={{ borderStyle: 'dashed' }} />
				<Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
					<List disablePadding>
						{rows.map((event, i) => <MessageItem key={i} event={event} handleMarkAsRead={handleMarkAsRead}/>)}
					</List>
				</Scrollbar>
				<Divider sx={{ borderStyle: 'dashed' }} />
				<Box sx={{ p: 1 }}>
					<Button fullWidth disableRipple onClick={() => handleSeeAll()}>
						Ver Todas
					</Button>
				</Box>
			</MenuPopover>
		</>
	);
}

MessageItem.propTypes = {
  	notification: PropTypes.shape({
		id: PropTypes.string,
		avatar: PropTypes.node,
		type: PropTypes.string,
		title: PropTypes.string,
		isUnRead: PropTypes.bool,
		description: PropTypes.string,
		createdAt: PropTypes.instanceOf(Date),
		handleMarkAsRead: PropTypes.func
  	}),
};

function MessageItem({ event, handleMarkAsRead }) 
{
	const { avatar, title } = renderContent(event);

	return (
		<ListItemButton
			sx={{
				py: 1.5,
				px: 2.5,
				mt: '1px',
			}}
			onClick={() => handleMarkAsRead(event) }
		>
			<ListItemAvatar>
				<Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
			</ListItemAvatar>
			<ListItemText
				disableTypography
				primary={title}
				secondary={
					<>
						<Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
							<Iconify icon="material-symbols:info-outline" width={16} sx={{ mr: 0.5 }} />
							<Typography variant="caption">{event.detalhe}</Typography>
						</Stack>
						<Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
							<Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
							<Typography variant="caption">{fToNow(event.ins_data)}</Typography>
						</Stack>
					</>
				}
			/> 
		</ListItemButton>
	);
}

function renderContent(event) 
{
	const title = (
		<Typography variant="subtitle2">
			{event.placa}
			<Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
				&nbsp; {event.tipoevento}
			</Typography>
		</Typography>
	);

	return {
		avatar: event.avatar ? <img alt={event.title} src={event.avatar} /> : null,
		title,
	};
}
