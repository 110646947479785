import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton } from '@mui/material';

import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import MessagesPopover from './MessagesPopover';
import EventsPopover from './EventsPopover';
import IntegrationFail from './IntegrationFail';

import { useAuthContext } from 'src/auth/useAuthContext';
import { useTracking } from 'src/context/TrackingProvider/useTracking';
import { hasPermission } from 'src/helpers/utils';
import { useSettingsContext } from 'src/components/settings';
import { HEADER, NAV } from 'src/config';
import { bgBlur } from 'src/utils/cssStyles';
import FilterOfTrackingButton from './FilterOfTrackingButton';

Header.propTypes = { onOpenNav: PropTypes.func, };

	export default function Header({ onOpenNav }) 
	{
	const theme = useTheme();

	const { themeLayout } = useSettingsContext();
	const { user } = useAuthContext();
	const { permissions } = useTracking();
	
	const isNavHorizontal = themeLayout === 'horizontal';
	const isNavMini = themeLayout === 'mini';

	const isDesktop = useResponsive('up', 'lg');
	const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

	const renderContent = (
		<>
			{isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}
			{!isDesktop && (
				<IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
					<Iconify icon="eva:menu-2-fill" />
				</IconButton>
			)}
			<Searchbar />
            <FilterOfTrackingButton />
			<Stack flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={{ xs: 0.5, sm: 1.5 }}>
				{/* <LanguagePopover /> */}
				{/* <NotificationsPopover /> */}
				{ user?.tipo_gerenciamento === "Interno" && <IntegrationFail /> } 
				{ hasPermission(permissions,'principal', 'evento', 'evento') && <EventsPopover />}
				{ hasPermission(permissions,'principal', 'mensagem', 'mensagem') && <MessagesPopover />}
				{/* <ContactsPopover /> */}
				<AccountPopover />
			</Stack>
		</>
	);

	return (
		<AppBar
			sx={
			{
				boxShadow: 'none',
				height: HEADER.H_MOBILE,
				zIndex: theme.zIndex.appBar + 1,
				...bgBlur(
				{
					color: theme.palette.background.default,
				}),
				transition: theme.transitions.create(['height'], 
				{
					duration: theme.transitions.duration.shorter,
				}),
				...(isDesktop && 
				{
					width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
					height: HEADER.H_DASHBOARD_DESKTOP,
					...(isOffset && 
					{
						height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
					}),
					...(isNavHorizontal && 
					{
						width: 1,
						bgcolor: 'background.default',
						height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
						borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
					}),
					...(isNavMini && 
					{
						width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
					}),
				}),
			}}
		>
		<Toolbar
			sx={{
				height: 1,
				px: { lg: 5 },
			}}
		>
			{renderContent}
		</Toolbar>
		</AppBar>
	);
}
