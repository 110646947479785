
import React, { useState, useEffect } from 'react';
import { DataGrid, GridColumnMenuContainer, ptBR, useGridApiRef } from '@mui/x-data-grid';
import { useParams } from 'react-router';
import { Stack, styled, TextField } from '@mui/material';

import { useSnackbar } from 'src/components/snackbar';
import { useTransfer } from 'src/context/TransferProvider/useTransfer';
import { useTracking } from 'src/context/TrackingProvider/useTracking';
import { getError } from 'src/helpers/utils';
import { IconButton } from '..';

import ApiGrid from 'src/services/api/grid_coluna';

const StyledGridColumnMenuContainer = styled(GridColumnMenuContainer)
(
    ({ theme, ownerState }) => ({
        background: theme.palette[ownerState.color].main,
        color: theme.palette[ownerState.color].contrastText,
    }),
);

const CustomColumnMenuComponent = ({hideMenu, currentColumn, color, ...other}) =>
{
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const { columns, setColumns } = useTransfer();

    const [width, setWidth] = useState(currentColumn.width);

    const add = () => setWidth(width + 1);

    const remove = () => setWidth(width - 1);

    const save = async (e) =>
    {
        const cod_grid = +params.id;
        const cod_grid_columns = columns.find(col => col.field === currentColumn.field).id;

        try
        {
            const { status, result } = await ApiGrid.setWidth(cod_grid, cod_grid_columns, width);
            
            if (status)
            {
                enqueueSnackbar(`Coluna ${currentColumn.headerName}: ${result}`);
                hideMenu(e);
            }
            else 
                enqueueSnackbar(`Coluna ${currentColumn.headerName}: ${result}`, { variant: 'warning' });
        }
        catch(error)
        {
            const { result } = getError();
            enqueueSnackbar(`Coluna ${currentColumn.headerName}: ${result}`, { variant: 'error' });
        }
    }

    useEffect(() =>
    {
        if (width !== currentColumn.width)
        {
            let cols = columns.slice();

            setColumns(cols.map(col =>
            {
                if (col.field === currentColumn.field)
                    col.width = width;
                
                return col;
            }));
        }
    }, 
    //eslint-disable-next-line
    [width]);

    return (
        <StyledGridColumnMenuContainer
            hideMenu={hideMenu}
            currentColumn={currentColumn}
            ownerState={{ color }}
            {...other}
        >
            <Stack sx={{p:1}} flexDirection={"row"} alignContent={"center"} alignItems={"center"} spacing={1}>
                <IconButton icon="material-symbols:remove" title="Diminuir" onClick={() => remove()} />
                <TextField 
                    type="number"
                    size="small"
                    label="Largura"
                    value={width}
                    onChange={(e => setWidth(+e.target.value))}  
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                    sx={{pl: 1, pr: 1}}
                    step={5}
                />
                <IconButton icon="material-symbols:add" title="Aumentar" onClick={() => add()} />
                <IconButton icon="material-symbols:save" title="Salvar" onClick={save} />
            </Stack>
        </StyledGridColumnMenuContainer>
    );
}

const GridCfg = () => 
{
    const params = useParams();
    const apiRef = useGridApiRef();
    
    const { enqueueSnackbar } = useSnackbar();
    const { right, columns, setColumns } = useTransfer();
    const { data: rows } = useTracking();

    const [color] = useState('background');

    useEffect(() =>
    {
        (async () => 
        {
            await getColumns();
        })();
    }, 
    //eslint-disable-next-line
    [right]);

    const getColumns = async () =>
    {
        try 
        {
            const { status, result } = await ApiGrid.getColsByGrid(+params.id);

            if (status)
                setColumns(result);
            else
                enqueueSnackbar(`Colunas: ${result}`, { variant: 'warning' });
        } 
        catch (error) 
        {
            const { result } = getError(error);

            enqueueSnackbar(`Colunas: ${result}`, { variant: 'error' });
        }
    }

    return (
        <div style={{ height: '30vh', width: '100%' }}>
            <DataGrid
                apiRef={apiRef}
                rows={rows?.status ? rows.result.map((row, i) => 
                {
                    row.id = i;
                    return row;
                }) : []}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
                disableSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                components={{ 
                    ColumnMenu: CustomColumnMenuComponent
                }}
                componentsProps={{
                    columnMenu: { color },
                }} 
            />
        </div>
    )
}

export default GridCfg