import { fetchDataOn } from "src/helpers/utils";

let Api = {
	getRows: async () =>
    {
        const endpoint = '/mensagem/listar';
		return await fetchDataOn(endpoint);
    },
	getRowsLimit: async () =>
    {
        const endpoint = '/mensagem/listar/limite/5';
		return await fetchDataOn(endpoint);
    },
	getRowsCounters: async () =>
    {
        const endpoint = '/mensagem/listar/contadores';
		return await fetchDataOn(endpoint);
    },
	getRowsPerRastreador: async (cod_rastreador) =>
    {
        const endpoint = `/mensagem/listar/rastreador/${cod_rastreador}`;
		return await fetchDataOn(endpoint);
    },	
    getRowsMacrosByDevice: async (cod_rastreador) =>
    {
        const endpoint = `/mensagem/listar/macros/${cod_rastreador}`;
		return await fetchDataOn(endpoint);
    },
    getOnixsatXmlMacroPre: async (tfrID,tipo) =>
    {
        const endpoint = `/mensagem/obter/onixsat/macro-pre/xml/${tfrID}/${tipo}`;
		return await fetchDataOn(endpoint);
    },
    getOmnilinkIasWsXmlMacroPre: async (IdMensagemPre) =>
    {
        const endpoint = `/mensagem/obter/omnilink/iasws/macro-pre/xml/${IdMensagemPre}`;
		return await fetchDataOn(endpoint);
    },
	ler: async (cod_mensagem) =>
    {
        const endpoint = `/mensagem/ler/${cod_mensagem}`;
		return await fetchDataOn(endpoint);
    },
	lerTodas: async () =>
    {
        const endpoint = `/mensagem/ler`;
		return await fetchDataOn(endpoint);
    },
	lerTodasPorRastreador: async (cod_rastreador) =>
    {
        const endpoint = `/mensagem/ler/rastreador/${cod_rastreador}`;
		return await fetchDataOn(endpoint);
    },
    adicionar: async (values) =>
    {
        const endpoint = `/mensagem/adicionar`;
		return await fetchDataOn(endpoint, values);
    },
}

export default Api;
