import React from 'react';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router';

import { useSettingsContext } from 'src/components/settings';
import { useTransfer } from 'src/context/TransferProvider/useTransfer';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { IconButton } from '..';

import Tranfer from './Tranfer';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

const Main = () => 
{
    const { themeStretch } = useSettingsContext();

    const { idPrimary, getName, titlePrimary, titleSecondary, } = useTransfer();

	
	const navigate = useNavigate();

	const name = getName();
    return (
        <Container maxWidth={themeStretch ? false : 'xl'}>
			<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
				<CustomBreadcrumbs
					heading={`${titleSecondary} por ${titlePrimary}${name ? ` - ${name}` : ''}`}
					links={[
						{
							name: 'Dashboard',
							href: PATH_DASHBOARD.root,
						},
						{
							name: titlePrimary,
							href: PATH_DASHBOARD[idPrimary].list,
						},
						{ 
							name: titleSecondary,
						},
					]}
				/>
				<IconButton icon="ri:arrow-go-back-fill" title="Voltar à tela anterior" onClick={() => navigate(-1)} />
			</div>
            <Tranfer />
        </Container>
    )
}

export default Main