import * as Util from '../../helpers/utils';

let Api = { 
    getRows: async () =>
    {
        const endpoint = '/evento/listar'; 
		return Util.fetchDataOn(endpoint);
    },
    getRowsLimit: async () =>
    {
        const endpoint = '/evento/listar/limite/5'; 
		return Util.fetchDataOn(endpoint);
    },
    getRowsCounters: async () =>
    {
        const endpoint = '/evento/listar/contadores';
		return Util.fetchDataOn(endpoint);
    },
	getRowsPerRastreador: async (cod_rastreador) =>
    {
        const endpoint = `/evento/listar/rastreador/${cod_rastreador}`;
		return Util.fetchDataOn(endpoint);
    },
	ler: async (cod_evento) =>
    {
        const endpoint = `/evento/ler/${cod_evento}`;
		return Util.fetchDataOn(endpoint);
    },
	lerTodas: async () =>
    {
        const endpoint = `/evento/ler`;
		return Util.fetchDataOn(endpoint);
    },
	lerTodasPorRastreador: async (cod_rastreador) =>
    {
        const endpoint = `/evento/ler/rastreador/${cod_rastreador}`;
		return Util.fetchDataOn(endpoint);
    },
	lerTodasPorTipo: async (cod_tipoevento) =>
    {
        const endpoint = `/evento/ler/tipoevento/${cod_tipoevento}`;
		return Util.fetchDataOn(endpoint);
    },
}

export default Api;
