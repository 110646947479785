export const APP_NAME = 'Ca2Track';
export const APP_ALIAS = 'ca2track';
export const APP_PATH_MAIN = '/ca2track';
export const APP_SUPORTE_FONE = '5544998140006';
export const APP_FORM_DINAMICS_EDIT = ['edit', 'config', 'trigger','debug','latlng'];

export const getName = () => {
    if (location.hostname.includes('genesisgroups'))
        document.title = "Genesis Groups";
    else 
        document.title = APP_NAME;
}
