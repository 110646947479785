import React, { createContext, useState, useEffect  } from 'react';
import { useQuery } from '@tanstack/react-query';

import ApiEvents from 'src/services/api/evento';

import { useSnackbar } from 'src/components/snackbar';
import { parseError } from 'src/helpers/utils';
import { confirmAlert } from 'react-confirm-alert';

export const EventsContext = createContext({});

export const EventsProvider = ({children}) =>
{   
    const eventsTol = 10;

    const { enqueueSnackbar } = useSnackbar();

    const { data, isFetching, isError, error, refetch } = useQuery(['events'], 
        async () => await ApiEvents.getRowsCounters(), 
        {
            //retry: 10,
            refetchInterval: 10000,
            refetchOnWindowFocus: false
        }
    );

    const [eventsGroupByVehicle, setEventsGroupByVehicle] = useState([]);
    const [selected, setSelected] = useState(null);
    const [valueFilter, setvalueFilter] = useState('');

    const [events, setEvents] = useState([]);
    const [lastEvents, setLastEvents] = useState([]); 
    const [totalEvents, setTotalEvents] = useState(events.length);
    
    useEffect(() =>
    {
        if (data)
        {
            const { status, result } = data;
         
            if (status)
            {
                setEventsGroupByVehicle(result);
                defineTotalEvents(result);
                showToastResponseCommand(result);
            }
            else
                enqueueSnackbar(result, { variant: 'warning' });
        }
    }, 
    // eslint-disable-next-line
    [data]);

    useEffect(() =>
    {
        if (isError)
        {
            const { events } = parseError("Eventos pendêntes", error);

            enqueueSnackbar(events, { variant: 'error' });
        }
    }, 
    //eslint-disable-next-line
    [isError]);

    useEffect(() =>
    {
        defineLastEvents();
    }, 
    //eslint-disable-next-line
    [totalEvents]);

    const showToastResponseCommand = (result) =>
    {
        result.filter(item => item.tipoevento === "Resposta de Comando").forEach(item => 
        {
            const { descricao, placa} = item;
            enqueueSnackbar(`Resposta de Comando: ${placa} - ${descricao}`, { variant: 'info', autoHideDuration: 5000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' }});
        })
    }

    const defineLastEvents = () =>
    {
        let tmp = [...lastEvents]//structuredClone(lastEvents); 
        tmp.push(totalEvents);

        if (lastEvents.length >= eventsTol) 
            tmp.shift();
        
        setLastEvents(tmp);
    }

    const defineTotalEvents = (result) =>
    {
        let count = 0;

        result.forEach(item => 
        {
            count += item.qtde;  
        })

        setTotalEvents(count);
    }

    const getEvents = async (item) =>
    {
        setEvents([]);
        
        setSelected(item);

        try 
        {
            const { status, result } = await ApiEvents.getRowsPerRastreador(item.cod_rastreador);

            if (status)
                setEvents(result);
        } 
        catch (error) 
        {
            setSelected(null);

            const { message } = parseError("Eventos pendêntes", error);

            enqueueSnackbar(message, { variant: 'error' });
        }
    }

    const markAllAsReadsByTracker = async (cod_rastreador) =>
    {
        try 
        {
            const { status, result } = await ApiEvents.lerTodasPorRastreador(cod_rastreador);

            if (status)
            {   
                setTotalEvents(totalEvents - selected.qtde);
            }
            else
                enqueueSnackbar(result, { variant: 'warning' });
        } 
        catch (error) 
        {
            const { status, message } = parseError("Ler Eventos", error);

            if (status !== 404)
                enqueueSnackbar(message, { variant: 'error' });
        }
    }

    const markAsRead = async (cod_evento) =>
    {
        try 
        {
            const { status, result } = await ApiEvents.ler(cod_evento);

            if (!status)
                enqueueSnackbar(result, { variant: 'warning' });
            else
                refetch();

            return status;
        } 
        catch (error) 
        {
            const { message } = parseError("Ler Evento", error);

            enqueueSnackbar(message, { variant: 'error' });

            return false;
        }
    }

    const markAllAsReadByType = async (cod_tipoevento) =>
    {
        try 
        {
            const { status, result } = await ApiEvents.lerTodasPorTipo(cod_tipoevento);

            if (status)
            {
                setSelected(null);
                setEventsGroupByVehicle([]);
                setEvents([]);
                setTotalEvents(0);

                return true;
            }
            else
            {
                enqueueSnackbar(result, { variant: 'warning' });

                return false;
            }
        } 
        catch (error) 
        {
            const { message } = parseError("Ler Todas Mensagens", error);

            enqueueSnackbar(message, { variant: 'error' });

            return false;
        }
    }

    const markAllAsRead = async () =>
    {
        try 
        {
            const { status, result } = await ApiEvents.lerTodas();

            if (status)
            {
                setSelected(null);
                setEventsGroupByVehicle([]);
                setEvents([]);
                setTotalEvents(0);

                return true;
            }
            else
            {
                enqueueSnackbar(result, { variant: 'warning' });

                return false;
            }
        } 
        catch (error) 
        {
            const { message } = parseError("Ler Todas Mensagens", error);

            enqueueSnackbar(message, { variant: 'error' });

            return false;
        }
    }

    const handEvents = async (item) =>
    {
        await getEvents(item);
        await markAllAsReadsByTracker(item.cod_rastreador);
    }

    const onChangeFilter = (e) =>
    {
        const { value } = e.target;

        setvalueFilter(value);
    }

    // useEffect(() =>
    // {
    //     console.log("Implementar filter", valueFilter);
    // }, 
    // [valueFilter]);

    const onCellKeyDownGroup = (params, event) =>
    {
        event.preventDefault();

        const { altKey, shiftKey, ctrlKey, keyCode } = event;
        // console.log(altKey, shiftKey, ctrlKey, keyCode)
        let newRow = {
            id: params.id,
            row: params.row
        };

        if (!altKey && !shiftKey && !ctrlKey)
        { 
            switch (keyCode) 
            {
                case 46: shortcutMarkAllAsReadByType(newRow); break; // Del
            
                default:
                    break;
            }
        }
    }

    const shortcutMarkAllAsReadByType = (newRow) =>
    {
        const { cod_tipoevento, count, tipoevento } = newRow.row;

        confirmAlert({
            title: 'Confirmação',
            message: `Tem certeza que deseja marcar como lido o(s) ${count} evento(s) de ${tipoevento}?`,
            buttons: [
                { 
                    label: 'Sim', 
                    onClick: async () => await markAllAsReadByType(cod_tipoevento)
                },
                { label: 'Não' }
            ],
        });
    }

    return (
        <EventsContext.Provider
            value={
            {
                eventsGroupByVehicle, isFetching, isError, error, refetch,
                handEvents, setEvents, events,
                selected,
                valueFilter, onChangeFilter,
                totalEvents, lastEvents,
                markAsRead, markAllAsRead, markAllAsReadByType,
                onCellKeyDownGroup
            }}
        >
            {children}
        </EventsContext.Provider>
    )
}