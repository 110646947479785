import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Form from './Form';

import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSettingsContext } from 'src/components/settings';
import { FormProvider } from 'src/context/FormReusable';
import { IconButton } from '..';
import { getApplicationName, getClientName } from 'src/helpers/servers';
import { useTracking } from 'src/context/TrackingProvider/useTracking';
import { hasPermission, toDecrypt, urlBase } from 'src/helpers/utils';
import { APP_FORM_DINAMICS_EDIT } from 'src/config/constants';

const Index = React.memo(({ id, title, table, fields, optionsButtonsList = [], permission }) => {
    const { themeStretch } = useSettingsContext();
    const { permissions } = useTracking();

    const navigate = useNavigate();
    const params = useParams();

    const [show, setShow] = useState(false);

    const checkPermission = useCallback(() => {
        if (permission) {
            const { category, menu, item } = permission;
            if (!hasPermission(permissions, category, menu, item)) {
                navigate(PATH_DASHBOARD._403.root);
            } else {
                setShow(true);
            }
        } else {
            setShow(true);
        }
    }, [permissions, permission, navigate]);

    useEffect(() => {
        checkPermission();
        document.body.style.overflow = 'auto';
    }, [checkPermission]);

    return show && (
        <FormProvider idPage={id} title={title} table={table} fieldsInit={fields} optionsButtonsList={optionsButtonsList}>
            <Helmet>
                <title>{title} | {getApplicationName()} | {getClientName()}</title>
            </Helmet>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <CustomBreadcrumbs
                        heading={title}
                        links={[
                            {
                                name: 'Dashboard',
                                href: PATH_DASHBOARD.root,
                            },
                            {
                                name: title,
                                href: PATH_DASHBOARD[id]?.list,
                            },
                            {
                                name: APP_FORM_DINAMICS_EDIT.some(item => urlBase().includes(item))
                                    ? `ID: ${toDecrypt(params.id).padStart(6, '0')}`
                                    : "Cadastro",
                            },
                        ]}
                    />
                    <IconButton icon="ri:arrow-go-back-fill" title="Voltar à tela anterior" onClick={() => navigate(-1)} />
                </div>
                <Form />
            </Container>
        </FormProvider>
    );
});

Index.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    table: PropTypes.string,
    fields: PropTypes.array,
    permission: PropTypes.object,
    optionsButtonsList: PropTypes.array,
};

Index.defaultProps = {
    optionsButtonsList: [],
};

export default Index;

