import { Helmet } from 'react-helmet-async';

import { getApplicationName, getClientName } from 'src/helpers/servers';
import { version } from 'src/helpers/utils';

import Login from '../sections/auth/Login';

export default function LoginPage() 
{
	return (
		<>
			<Helmet>
				<title> Login | {getApplicationName()} v{version()} | {getClientName()}</title>
			</Helmet>
			<Login />
		</>
	);
}
