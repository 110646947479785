import React, { memo } from 'react';
import Label from 'src/components/label';
import { datetime2br } from 'src/helpers/utils';

const Evt003 = memo(({ item }) => {
    let color = 'success';

    if (item?.row?.evt_003 === 'S') {
        color = 'warning';
    }

    return item?.value ? (
        <span title={item?.row?.evt_003 === 'S' ? 'Perda de Sinal' : ''}>
            <Label color={color}>{datetime2br(item.value)}</Label>
        </span>
    ) : null;
});

export default Evt003;

