import { fetchDataOn } from "src/helpers/utils";

let Api = {
    getRowsTemperature: async (sensor) => 
    {
        const endpoint = `/viagem/faixatemperatura/listar/status/${sensor}`;
        return await fetchDataOn(endpoint)
    },
    getRow: async (cod_veiculo) =>
    {
		const endpoint = `/viagem/obter/${cod_veiculo}`;
		return await fetchDataOn(endpoint);
    },
    getPolyline: async (cod_veiculo) =>
    {
		const endpoint = `/viagem/polilinha/${cod_veiculo}`;
		const body = {
			mostrar_rastro: false,
			mostrar_paradas: false
		}
		return await fetchDataOn(endpoint, body);
    },
    getMarkers: async (cod_veiculo) =>
    {
		const endpoint = `/viagem/sm/obter/marcadores/${cod_veiculo}`;
		return await fetchDataOn(endpoint);
    },
    getTemperatureRange: async (cod_veiculo) =>
    {
		const endpoint = `/viagem/faixatemperatura/obter/${cod_veiculo}`;
		return await fetchDataOn(endpoint);
    },

	update: async (cod_viagem, body) =>
    {
		const endpoint = `/viagem/atualizar/${cod_viagem}`;
		return await fetchDataOn(endpoint, body);
    },
	setSmPre: async (body) =>
    {
		const endpoint = `/viagem/sm/predefinido`;
		return await fetchDataOn(endpoint, body);
    },
	setSmManual: async (body) =>
    {
		const endpoint = `/viagem/sm/manual`;
		return await fetchDataOn(endpoint, body);
    },
	encerrar: async (cod_veiculo) =>
    {
		const endpoint = `/viagem/sm/encerrar/${cod_veiculo}`;
		return await fetchDataOn(endpoint);
    },
	cancelar: async (cod_veiculo) =>
    {
		const endpoint = `/viagem/sm/cancelar/${cod_veiculo}`;
		return await fetchDataOn(endpoint);
    },
	getLink: async (cod_veiculo) =>
    {
		const endpoint = `/viagem/sm/obter/link/${cod_veiculo}`;
		return await fetchDataOn(endpoint);
    },
}

export default Api;
