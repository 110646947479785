import React, { useCallback } from 'react';
import { Grid } from '@mui/material';

import { useForm } from 'src/context/FormReusable/useForm';
import Field from './Field';
import LoadingMutation from './LoadingMutation';
import LoadingQuery from './LoadingQuery';
import LoadingSelect from './LoadingSelect';

const Fields = React.memo(() => {
    const { query, mutation, selectStatus, fields, values, onChange } = useForm();

    const isReadyToRender = !query.isFetching && !query.isLoading && !mutation.isLoading && !selectStatus.status;

    const renderField = useCallback(
        (field, i) => (
            <Field
                key={i}
                field={field}
                value={values !== undefined && values !== null ? values[field.name] : ''}
                required={field.required}
                visible={field.visible}
                onChange={onChange}
                helper={field?.helper}
                tabIndex={field?.tabIndex}
            />
        ),
        [values, onChange]
    );

    return (
        <>
            <LoadingQuery />
            <LoadingMutation />
            <LoadingSelect />
            {isReadyToRender && fields && (
                <Grid container spacing={3}>
                    {fields.map(renderField)}
                </Grid>
            )}
        </>
    );
});

export default Fields;

