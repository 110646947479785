import React from 'react';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, name, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            isNumericString
            prefix="R$ "
        />
    );
});

const InputMoney = React.memo(({
    id,
    label,
    placeholder,
    style,
    value = 0,
    error,
    maxLength,
    disabled,
    required = false,
    step,
    tabIndex,
    onChange = () => null,
    helper,
    ...rest
}) => {
    return (
        <TextField
            variant="outlined"
            id={id}
            name={id}
            label={label}
            placeholder={placeholder}
            onChange={onChange}
            style={style}
            value={value}
            disabled={disabled}
            required={required}
            fullWidth
            error={error}
            tabIndex={tabIndex}
            helperText={helper}
            InputProps={{
                inputComponent: NumberFormatCustom,
            }}
            {...rest}
        />
    );
});

export default InputMoney;

