import { Typography, useTheme } from '@mui/material'
import React from 'react'

const AppName = ({n1, n2}) => 
{
    const theme = useTheme();
    
    return (
        <>
            <Typography variant='h4' color={theme.palette.text.secondary}>
                {n1}
            </Typography>
            <Typography variant='h4' color={theme.palette.text.primary}>
                {n2}
            </Typography>
        </>
    )
}

export default AppName