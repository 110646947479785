import React, { memo, useState, useCallback } from 'react';
import { Card, Stack } from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import {useNavigate} from 'react-router';

import { DataGrid, MenuButton } from '..';
import ReportNav from './ReportNav';
import {APP_ALIAS} from 'src/config/constants';

const Index = memo(({
    columns,
    data,
    isFecthing,
    selectedRow,
    setSelectedRow,
    onCellKeyDown,
    di,
    df,
    setDi,
    setDf,
    getData,
    buttonsToRows = [],
    buttonsToRow = [],
    beforeFilter,
    addFilters = [],
    height = "45vh",
    optionsButtonsList=[],
}) => {
    const navigate = useNavigate();

    const navItemsFilter = [];

    // Adicionando o estado openNav
    const [openNav, setOpenNav] = useState(false);
    
    const handleOpenCompose = useCallback(() => {
        setOpenNav(true);  // Corrigindo para usar o estado correto
    }, []);

    const handleCloseNav = useCallback(() => {
        setOpenNav(false);
    }, []);

    const handleMenuClick = (item) => {
        fromTripToTemperatureControl(item);
    };

    const fromTripToTemperatureControl = (item) => {
        const {from, to, cod_pk, path} = item;
        if (from === 'trip') {
            if (to === 'temperature-control') {
                const values = JSON.stringify({
                    to: item,
                    from: selectedRow.row
                });
                localStorage.setItem(`${APP_ALIAS}-report-data-tmp`,values); // armazena os dados necessarios no localStorage para ser recuperado no relatorio de temperatura
                navigate(path.replace(':id',selectedRow.row[cod_pk]));
            }
        }
    }

    const Toolbar = useCallback(() => (
        <Stack
            spacing={0}
            direction="row"
            alignItems="center"
            flexShrink={0}
            sx={{ ml: 0.5, mr: 0.5, mt: 0.5 }}
        >
            <GridToolbarQuickFilter
                variant="outlined"
                size="small"
                placeholder="Pesquisar"
                sx={{ mr: 0.5 }}
            />
            {selectedRow && (
                <>
                    {optionsButtonsList.length > 0 && (
                        <MenuButton
                            id="more"
                            title="Mais"
                            icon="eva:more-vertical-fill"
                            items={optionsButtonsList.map((item) => ({
                                title: item.title,
                                type: item.type ?? 'menu',
                                color: item.color,
                                onClick: () => handleMenuClick(item),
                            }))}
                        />
                    )}
                </>
            )}
        </Stack>
    ), [selectedRow]);

    return (
        <Card
            sx={{
                height: height,
                display: 'flex',
                bgcolor: 'background.neutral'
            }}
        >
            <ReportNav
                items={navItemsFilter}
                openNav={openNav}
                onCloseNav={handleCloseNav}
                onOpenCompose={handleOpenCompose}
                di={di}
                df={df}
                setDi={setDi}
                setDf={setDf}
                getData={getData}
                buttonsToRows={buttonsToRows}
                buttonsToRow={buttonsToRow}
                beforeFilter={beforeFilter}
                addFilters={addFilters}
            />
            <Stack flexGrow={1}>
                <DataGrid
                    columns={columns}
                    data={data}
                    loading={isFecthing}
                    onRowClick={(e) => setSelectedRow(e)}
                    onCellKeyDown={onCellKeyDown}
                    height={'100%'}
                    isCompact
                    toolbar={Toolbar}
                />
            </Stack>
        </Card>
    );
});

export default Index;

