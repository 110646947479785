import React from 'react';
import { Helmet } from 'react-helmet-async';
import { APP_NAME } from 'src/config/constants';
import { TransferProvider } from 'src/context/TransferProvider';
import Main from './Main';

const index = ({idPrimary, idSecondary, titlePrimary, titleSecondary, apiGetMain, apiGetLeft, apiGetRight, apiPostToUp, apiPostToDown, apiPostToLink, apiPostToUnlink, optionsButtonsList=[]}) => 
{
    return (
        <>
            <Helmet>
                <title>{titleSecondary} por {titlePrimary} | { APP_NAME }</title>
            </Helmet>
            <TransferProvider
                idPrimary={idPrimary}
                idSecondary={idSecondary}
                titlePrimary={titlePrimary}
                titleSecondary={titleSecondary}
                apiGetMain={apiGetMain}
                apiGetLeft={apiGetLeft}
                apiGetRight={apiGetRight}
                apiPostToUp={apiPostToUp}
                apiPostToDown={apiPostToDown}
                apiPostToLink={apiPostToLink}
                apiPostToUnlink={apiPostToUnlink} 
                optionsButtonsList={optionsButtonsList}
            >
                <Main />
            </TransferProvider>
        </>
    )
}

export default index