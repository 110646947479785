import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import Api from 'src/services/api/ca2soft';
import { version } from 'src/helpers/utils';

const Update = () => 
{
    const { data } = useQuery(['version'], async () => await Api.getVersion(), { refetchOnWindowFocus: true });

    const [isUpdated, setIsUpdated] = useState(true);

    useEffect(() =>
    {
        if (data)
        {
            const { status, result } = data;

            if (status)
            {
                setIsUpdated(result.versao === version());
                // console.log("remoto", result.versao, "local", version())
            }
        }
    }, [data]);

    return !isUpdated && <Button variant='contained' color={"warning"} sx={{m: 1}} onClick={() => document.location.reload(true)}>Atualizar</Button>
}

export default Update