import { useTheme } from '@mui/material';
import React, { memo } from 'react';
import Iconify from 'src/components/iconify/Iconify';

const Ignition = memo(({ value }) => {
    const theme = useTheme();

    let color = theme.palette.warning.main;
    let icon = 'carbon:undefined';

    switch (value) {
        case 'Ligado':
            color = theme.palette.success.main;
            icon = 'tabler:key';
            break;
        case 'Desligado':
            color = theme.palette.info.main;
            icon = 'tabler:key-off';
            break;
        default:
            break;
    }

    return value ? (
        <span title={value}>
            <Iconify icon={icon} color={color} />
        </span>
    ) : null;
});

export default Ignition;

