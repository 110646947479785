import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import MenuPopover from 'src/components/menu-popover';
import Api from 'src/services/api/mensagem';

import { IconButtonAnimate } from 'src/components/animate';
import { fToNow } from 'src/utils/formatTime';
import { useMessages } from 'src/context/MessagesProvider/useMessages';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { confirmAlert } from 'react-confirm-alert';
import { useQuery } from '@tanstack/react-query';

export default function MessagesPopover() 
{
	const navigate = useNavigate();

	const { data, refetch } = useQuery(['messages_last_5'], async () => await Api.getRowsLimit());
	const { totalMessages, markAllAsRead, markAsRead } = useMessages();

	const [rows, setRows] = useState([]);
	const [openPopover, setOpenPopover] = useState(null);

	useEffect(() =>
	{
		if (data?.status)
			setRows(data.result);
	}, 
	[data]);

	useEffect(() =>
	{
		refetch();
	}, 
	// eslint-disable-next-line
	[totalMessages]);

	const handleOpenPopover = (event) => setOpenPopover(event.currentTarget); 

	const handleClosePopover = () => setOpenPopover(null);

	const handleMarkAllAsRead = async () => 
	{
		handleClosePopover();

		confirmAlert(
		{
			title: 'Confirmação',
			message: 'Tem certeza que deseja marcar como lida TODAS as mensagens?',
			buttons: [
				{ label: 'Sim', onClick: () => markAllAsRead() },
				{ label: 'Não' }
			]
		});
	};

	const handleSeeAll = () =>
	{
		handleClosePopover();
		navigate(PATH_DASHBOARD.message.list);
	}

	const handleMarkAsRead = async (item) =>  await markAsRead(item.cod_mensagem);

	return (
		<>
			<IconButtonAnimate
				color={openPopover ? 'primary' : 'default'}
				onClick={handleOpenPopover}
				sx={{ width: 40, height: 40 }}
			>
				<Badge badgeContent={totalMessages} color="warning">
					<Iconify icon="ic:outline-message" />
				</Badge>
			</IconButtonAnimate>
			<MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
				<Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
					<Box sx={{ flexGrow: 1 }}>
						<Typography variant="subtitle1">Mensagens</Typography>
						<Typography variant="body2" sx={{ color: 'text.secondary' }}>
							Você tem {totalMessages} {totalMessages > 1 ? "mensagens não lidas" : "mensagem não lida"}
						</Typography>
					</Box>
					{
						totalMessages > 0 && (
							<Tooltip title="Marcar todas como lidas">
								<IconButton color="primary" onClick={handleMarkAllAsRead}>
									<Iconify icon="eva:done-all-fill" />
								</IconButton>
							</Tooltip>
						)
					}
				</Box>
				<Divider sx={{ borderStyle: 'dashed' }} />
				<Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
					<List disablePadding>
						{rows.map((message, i) => <MessageItem key={i} message={message} handleMarkAsRead={handleMarkAsRead}/>)}
					</List>
				</Scrollbar>
				<Divider sx={{ borderStyle: 'dashed' }} />
				<Box sx={{ p: 1 }}>
					<Button fullWidth disableRipple onClick={() => handleSeeAll()}>
						Ver Todas
					</Button>
				</Box>
			</MenuPopover>
		</>
	);
}

MessageItem.propTypes = {
  	notification: PropTypes.shape({
		id: PropTypes.string,
		avatar: PropTypes.node,
		type: PropTypes.string,
		title: PropTypes.string,
		isUnRead: PropTypes.bool,
		description: PropTypes.string,
		createdAt: PropTypes.instanceOf(Date),
		handleMarkAsRead: PropTypes.func
  	}),
};

function MessageItem({ message, handleMarkAsRead }) 
{
	const { avatar, title } = renderContent(message);

	return (
		<ListItemButton
			sx={{
				py: 1.5,
				px: 2.5,
				mt: '1px',
			}}
			onClick={() => handleMarkAsRead(message) }
		>
			<ListItemAvatar>
				<Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
			</ListItemAvatar>
			<ListItemText
				disableTypography
				primary={title}
				secondary={
					<Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
						<Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
						<Typography variant="caption">{fToNow(message.ins_data)}</Typography>
					</Stack>
				}
			/> 
		</ListItemButton>
	);
}

function renderContent(message) 
{
	const title = (
		<Typography variant="subtitle2">
			{message.placa}
			<Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
				&nbsp; {(message.texto)}
			</Typography>
		</Typography>
	);

	return {
		avatar: message.avatar ? <img alt={message.title} src={message.avatar} /> : null,
		title,
	};
}
