import React, { createContext, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'src/components/snackbar';
import { fetchDataOn } from 'src/helpers/utils';

export const CrudContext = createContext({});

export const CrudProvider = ({ id, columns, cod_pk_name, endpoint, title, optionsButtonsList, children }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [selectedRow, setSelectedRow] = useState(null);
    const [navItemsFilter, setNavItemsFilter] = useState([]);
    const [filtered, setFiltered] = useState(null);

    const { data, isFetching, isError, error, refetch } = useQuery([id], () => fetchDataOn(endpoint));

    useEffect(() => {
        if (isError) {
            console.error(error);
            enqueueSnackbar(error, { variant: 'error' });
        }
    }, [isError, error, enqueueSnackbar]);

    useEffect(() => {
        if (data) {
            const { status, result } = data;

            if (status) {
                const items = generateNavItems(result);
                setNavItemsFilter(items);
            } else {
                enqueueSnackbar(result, { variant: 'error' });
            }
        }
    }, [data, columns, enqueueSnackbar]);

    const generateNavItems = (result) => {
        const items = [{ id: "all", type: "system", name: "total", count: result.length }];

        columns.forEach(col => {
            if (col.navItem) {
                const { navItem } = col;
                const { id, name, type, values } = navItem;

                if (type === "groupBy") {
                    items.push(...generateGroupByItems(result, id, values, col));
                } else if (type === "sum") {
                    items.push(generateSumItems(result, id, name, values, col));
                }
            }
        });

        return items;
    };

    const generateGroupByItems = (result, id, values, col) => {
        return values.map(value => {
            const count = result.filter(x => x[id] === value.value).length;
            return createNavItem(id, "system", value.value, count, col.navItem);
        });
    };

    const generateSumItems = (result, id, name, values, col) => {
        let filteredResult = values.reduce((acc, item, index) => {
            if (item.operator === "!==") {
                return index === 0 ? result.filter(x => x[item.col] !== item.value) : acc.filter(x => x[item.col] !== item.value);
            } else {
                return index === 0 ? result.filter(x => x[item.col] === item.value) : acc.filter(x => x[item.col] === item.value);
            }
        }, result);

        return createNavItem(id, "system", name, filteredResult.length, col.navItem);
    };

    const createNavItem = (id, type, name, count, navItem) => {
        const item = { id, type, name, count, origin: navItem };

        if (navItem.icon) item.icon = navItem.icon;
        if (navItem.color) item.color = navItem.color;

        return item;
    };

    return (
        <CrudContext.Provider
            value={{
                id,
                title,
                cod_pk_name,
                columns,
                data,
                isFetching,
                isError,
                error,
                refetch,
                selectedRow,
                setSelectedRow,
                navItemsFilter,
                filtered,
                setFiltered,
                optionsButtonsList,
            }}
        >
            {children}
        </CrudContext.Provider>
    );
};

