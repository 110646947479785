import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useAuthContext } from "src/auth/useAuthContext";
import navConfig from "src/layouts/dashboard/nav/config";
import api from 'src/services/api/menu';

const transformMenu = (result, user) => {
   let newMenu = [];

   if (user.cod_tipousuario === 1) {
       result.push({
           id: 'suporte',
           items: [
               { id: 'comando', items: [{ id: 'consultar' }, { id: 'ultimos-comandos' }] },
               { id: 'comando-generico', items: [{ id: 'consultar' }] },
               { id: 'sistema', items: [{ id: 'consultar' }] },
               { id: 'sistema-conta', items: [{ id: 'consultar' }, { id: 'cadastrar' }] },
               { id: 'map', items: [{ id: 'markerViewer' }, { id: 'tripTester' }] },
               { id: 'import', items: [{ id: 'upload' }] },
               { id: 'test', items: [{ id: 'test' }] },
           ],
       });
   }

   result.forEach(n1a => {
       const n1b = navConfig.find(x => x.id === n1a.id && n1a.items.length);
       if (n1b) {
           let n1bt = {
               subheader: n1b.subheader,
               items: [],
           };
           n1a.items.forEach(n2a => {
               const n2b = n1b.items.find(x => x.id === n2a.id && n2a.items.length);
               if (n2b) {
                   let n2bt = {
                       id: n2b.id,
                       title: n2b.title,
                       path: n2b.path,
                       icon: n2b.icon,
                       ...(n2b?.children && { children: [] }),
                   };
                   n2a.items.forEach(n3a => {
                       const n3b = n2b.children ? n2b.children.find(x => x.id === n3a.id) : null;
                       if (n3b) {
                           n2bt.children.push({
                               id: n3b.id,
                               title: n3b.title,
                               path: n3b.path,
                           });
                       }
                   });
                   n1bt.items.push(n2bt);
               }
           });
           newMenu.push(n1bt);
       }
   });

   return newMenu;
};

const useMenu = () => {
   const { user } = useAuthContext();
   const { data, isFetching, isError } = useQuery(['menu_user'], api.getRows);

   useEffect(() => {
       if (isError) {
           console.error("Failed to fetch menu data");
       }
   }, [isError]);

   const menu = useMemo(() => {
       if (data && !isFetching) {
           const { status, result } = data;
           if (status) {
               return transformMenu(result, user);
           }
       }
       return [];
   }, [data, isFetching, user]);

   return { menu, permissions: data ? data.result : [] };
};

export default useMenu;

