import React, { useEffect } from 'react';
import { Stack } from '@mui/material';

import { DataGrid } from 'src/components/Ca2';
import { useCrud } from 'src/context/CrudProvider/useCrud';

import Toolbar from './Toolbar';
import useResponsive from 'src/hooks/useResponsive';

const CrudList = ({ onOpenNav }) => {
    const { columns, data, isFetching, setSelectedRow, filtered } = useCrud();
    const isDesktop = useResponsive('up', 'md');

    useEffect(() => {
        document.body.style.overflow = isDesktop ? 'hidden' : 'auto';
    }, [isDesktop]);

    return (
        <Stack
            sx={{
                position: 'relative',
                height: '100%',
            }}
        >
            <DataGrid
                columns={columns}
                height="100%"
                data={data}
                filtered={filtered}
                loading={isFetching}
                onRowClick={setSelectedRow}
                toolbar={() => <Toolbar onOpenNav={onOpenNav} />}
                style={{ borderRadius: '10px' }}
            />
        </Stack>
    );
}

export default CrudList;

