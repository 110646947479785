import { fetchDataOn } from "src/helpers/utils";

import DateTime from "src/components/Ca2/DataGrid/DateTime";
import Evt003 from "src/pages/tracking/Grid/Evt003";
import Ignition from "src/pages/tracking/Grid/Ignition";
import LoadStatus from "src/pages/tracking/Grid/LoadStatus";
import LogisticalStatus from "src/pages/tracking/Grid/LogisticalStatus";
import ProgressTrip from "src/pages/tracking/Grid/ProgressTrip";
import TripDistance from "src/components/Ca2/DataGrid/TripDistance";
import TemperatureSensor from "src/components/Ca2/DataGrid/TemperatureSensor";
import SignalLoss from "src/pages/tracking/Grid/SignalLoss";
import Cellphone from "src/components/Ca2/DataGrid/Cellphone";
import TextGrid from "src/pages/tracking/Grid/TextGrid";
import StatusGeneral from "src/pages/tracking/Grid/StatusGeneral";
import Reference from "src/pages/tracking/Grid/Reference";

const Api = {
    getCols: async () => {
        const endpoint = "/grid/listar/usuario";
        const response = await fetchDataOn(endpoint);

        if (response.status) {
            response.result = response.result.map(item => ({
                ...item,
                field: item.col,
                title: item.titulo,
                width: item.largura + 30,
                headerFilter: 'input',
            }));
        }

        return response;
    },

    getColsByGrid: async (cod_grid) => {
        const endpoint = `/grid_coluna/listar/grid/${cod_grid}`;
        const response = await fetchDataOn(endpoint);

        if (response.status) {
            const colsTextGrid = ['posicao', 'cliente', 'placa', 'referencia', 'itinerario'];
            const colsSt = ['st1', 'st2', 'st3'];

            response.result = response.result.map(item => {
                const commonProps = {
                    id: item.cod_grid_coluna_padrao,
                    field: item.col === "lon" ? "lng" : item.col,
                    headerName: item.titulo,
                    width: item.largura,
                };

                switch (item.col) {
                    case "datahora":
                        return {
                            ...commonProps,
                            renderCell: item => <Evt003 item={item} />,
                            type: "dateTime",
                            description: "Data/hora da última posição do rastreador",
                        };
                    case "iniciadoEm":
                        return {
                            ...commonProps,
                            renderCell: item => <DateTime value={item.value} />,
                            type: "dateTime",
                            description: "Data/hora da efetivação da viagem",
                        };
                    case "ignicao":
                        return {
                            ...commonProps,
                            renderCell: item => <Ignition value={item.value} />,
                        };
                    case "fone1":
                    case "fone2":
                        return {
                            ...commonProps,
                            renderCell: item => <Cellphone value={item.value} />,
                        };
                    case "cv":
                        return {
                            ...commonProps,
                            renderCell: item => <LoadStatus value={item.value} />,
                        };
                    case "status_logistico":
                        return {
                            ...commonProps,
                            renderCell: item => <LogisticalStatus row={item.row} />,
                        };
                    case "andamentoCliente":
                        return {
                            ...commonProps,
                            renderCell: item => <ProgressTrip row={item.row} />,
                        };
                    case "ps":
                        return {
                            ...commonProps,
                            renderCell: item => <SignalLoss item={item} />,
                        };
                    case "statusVgm":
                        return {
                            ...commonProps,
                            renderCell: item => <StatusGeneral item={item} />,
                        };
                    case "referencia":
                        return {
                            ...commonProps,
                            renderCell: item => <Reference item={item} />,
                        };
                    case "kmRestante":
                        return {
                            ...commonProps,
                            renderCell: item => <TripDistance value={item.value} />,
                        };
                    default:
                        if (colsSt.includes(item.col)) {
                            return {
                                ...commonProps,
                                description: "Sensor de Temperatura",
                                renderCell: item => <TemperatureSensor value={item.value} />,
                            };
                        }
                        if (colsTextGrid.includes(item.col)) {
                            return {
                                ...commonProps,
                                renderCell: item => <TextGrid item={item} />,
                            };
                        }
                        return commonProps;
                }
            });
        }

        return response;
    },

    setWidth: async (cod_grid, cod_grid_coluna_padrao, largura) => {
        const endpoint = `/grid_coluna/combo/grid/coluna/atualizar/largura/${cod_grid}/${cod_grid_coluna_padrao}/${largura}`;
        return await fetchDataOn(endpoint);
    }
};

export default Api;

