import React from 'react';
import { useNavigate } from 'react-router';
import { InputAdornment, Stack, TextField, Typography } from '@mui/material';

import { useTransfer } from 'src/context/TransferProvider/useTransfer';
import { MenuButton } from 'src/components/Ca2';
import { toEncrypt } from 'src/helpers/utils';

import Iconify from 'src/components/iconify';

const ToolbarRight = () =>
{
    const navigate = useNavigate();

    const { titleSecondary, rightChecked, optionsButtonsList, keyWordFilter, setKeyWordFilter } = useTransfer();

    return (
        <Stack sx={{ mb: 2 }} spacing={1}>
            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
            <Typography variant='subtitle1'>{ titleSecondary } Associados</Typography>
            {
                rightChecked.length === 1 && optionsButtonsList.length === 1 &&
                <MenuButton
                    id="more"
                    title="Opções"
                    icon={"eva:more-vertical-fill"}
                    items={
                        optionsButtonsList.map(item =>
                        {
                            const { title, path, type, color } = item;
                            return {
                                title: title, 
                                type: type ?? 'menu',
                                color: color,
                                onClick: () => 
                                {
                                    const { id, cod_pk } = rightChecked[0];

                                    navigate(path.replace(':id', toEncrypt(cod_pk ? cod_pk.toString() : id.toString())))
                                }
                            }
                        })
                    }
                />
            }
            </Stack>
            <TextField
                id="transfer_filter"
                size="small"
                placeholder={`Pesquisar por ${titleSecondary} associados`}
                value={keyWordFilter}
                onChange={(e) => setKeyWordFilter(e.target.value)}
                onDoubleClick={() => setKeyWordFilter('')}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill"  />
                        </InputAdornment>
                    ),
                }}
            />            
        </Stack>        
    )

    // return (
    //     <div style={{display: 'flex', justifyContent: 'flex-start', alignItems:'center', width: '100%' }}>
    //         <div style={{ marginBottom: 10}}><b>{ titleSecondary } Associados</b></div>
    //         {
    //             rightChecked.length === 1 && optionsButtonsList.length === 1 &&
    //             <div style={{marginTop: -8}}>
    //                 <MenuButton
    //                     id="more"
    //                     title="Opções"
    //                     icon={"eva:more-vertical-fill"}
    //                     items={
    //                         optionsButtonsList.map(item =>
    //                         {
    //                             const { title, path, type, color } = item;
    //                             return {
    //                                 title: title, 
    //                                 type: type ?? 'menu',
    //                                 color: color,
    //                                 onClick: () => 
    //                                 {
    //                                     const { id, cod_pk } = rightChecked[0];
    
    //                                     navigate(path.replace(':id', toEncrypt(cod_pk ? cod_pk.toString() : id.toString())))
    //                                 }
    //                             }
    //                         })
    //                     }
    //                 />
    //             </div>
    //         }
    //     </div>
    // ) 
}

export default ToolbarRight