import React from 'react';
import { TextField } from '@mui/material';

const InputNumber = React.memo(({
    id,
    label,
    placeholder,
    style,
    value = '',
    error,
    maxLength,
    disabled,
    required = false,
    step,
    tabIndex,
    onChange = () => null,
    ...rest
}) => {
    return (
        <TextField
            variant="outlined"
            type="number"
            id={id}
            name={id}
            label={label}
            placeholder={placeholder}
            onChange={onChange}
            style={style}
            value={value}
            disabled={disabled}
            required={required}
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength, step }}
            error={error}
            tabIndex={tabIndex}
            {...rest}
        />
    );
});

export default InputNumber;

