import React, { memo } from 'react';
import Label from 'src/components/label';

const SignalLoss = memo(({ item }) => {
    if (!item?.value || item?.row?.evt_003 !== "S") return null;

    return <Label color="warning">{item.value}</Label>;
});

export default SignalLoss;

