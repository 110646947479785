import React from 'react';
import { Box, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { useForm } from 'src/context/FormReusable/useForm';
import { datetime2br, toDecrypt, toEncrypt, urlBase } from 'src/helpers/utils';
import { MenuButton } from '..';
import { APP_FORM_DINAMICS_EDIT } from 'src/config/constants';

import Fields from './Fields';
import Footer from './Footer';

const Info = React.memo(({ query }) => {
    const isEditPage = APP_FORM_DINAMICS_EDIT.some(item => urlBase().includes(item));
    
    if (!isEditPage || !query.data || !query.data.status) {
        return <Grid item md={2}></Grid>;
    }

    const { ins_usuario, ins_data, upd_usuario, upd_data } = query.data.result;

    return (
        <Grid item xs={12} md={4}>
            <Card sx={{ pt: 5, pb: 5, px: 3, mb: 3 }}>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Cadastrado por
                </Typography>
                <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                    {ins_usuario}
                </Typography>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Data do Cadastro
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {datetime2br(ins_data)}
                </Typography>
            </Card>
            <Card sx={{ pt: 5, pb: 5, px: 3 }}>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Modificado por
                </Typography>
                <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                    {upd_usuario}
                </Typography>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Data da Modificação
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {datetime2br(upd_data)}
                </Typography>
            </Card>
        </Grid>
    );
});

const Form = () => {
    const { title, table, query, optionsButtonsList } = useForm();
    const { id } = useParams();
    const navigate = useNavigate();

    const isEditPage = APP_FORM_DINAMICS_EDIT.some(item => urlBase().includes(item));
    const headerTitle = `${title} - ${isEditPage ? `ID: ${toDecrypt(id).padStart(6, '0')}` : 'Cadastro'}`;

    return (
        <Grid container spacing={3}>
            {urlBase().includes("insert") && <Grid item md={2}></Grid>}
            <Grid item xs={12} md={8}>
                <Card>
                    <CardHeader
                        title={headerTitle}
                        action={
                            isEditPage && optionsButtonsList.length > 0 && (
                                <MenuButton
                                    id="more"
                                    title="Opções"
                                    icon="eva:more-vertical-fill"
                                    items={
                                        optionsButtonsList.map(item => ({
                                            title: item.title,
                                            type: item.type ?? 'menu',
                                            color: item.color,
                                            onClick: () => navigate(item.path.replace(':id', toEncrypt(id.toString())))
                                        }))
                                    }
                                />
                            )
                        }
                    />
                    <Divider sx={{ pt: 1, pb: 1 }} />
                    <form id={`form_${table}`} style={{ margin: 20 }}>
                        <Box rowGap={3} columnGap={1} display="grid">
                            <Fields />
                        </Box>
                        <Footer />
                    </form>
                </Card>
            </Grid>
            <Info query={query} />
        </Grid>
    );
};

export default React.memo(Form);

