//import { AsyncStorage } from 'AsyncStorage';
import { APP_ALIAS } from 'src/config/constants';
import { fetchDataOff, fetchDataOn } from 'src/helpers/utils';

let Api = {
	signIn: async (company, user, password) =>
    {
		localStorage.setItem(APP_ALIAS + "-company",company);

		const endpoint = "/usuario/login";
		const raw = { "usuario": user, "senha": password };

		return await fetchDataOff(company, endpoint, raw);
    },
	signInByKey: async (segredo) =>
    {
		const endpoint = "/usuario/login2";
		const raw = { "chave": segredo };

		return await fetchDataOn(endpoint, raw);
    }
}

export default Api;
