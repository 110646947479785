import React from 'react';
import { CircularProgress } from '@mui/material';
import { useForm } from 'src/context/FormReusable/useForm';
import { urlBase } from 'src/helpers/utils';
import { APP_FORM_DINAMICS_EDIT } from 'src/config/constants';

const LoadingMutation = React.memo(() => {
    const { mutation } = useForm();
    const loadingMessage = APP_FORM_DINAMICS_EDIT.some(item => urlBase().includes(item)) 
        ? 'Atualizando' 
        : 'Enviando';

    return mutation.isLoading && (
        <div style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
            <CircularProgress />
            <span style={{margin: 10}}>{loadingMessage} dados...</span>
        </div>
    );
});

export default LoadingMutation;

