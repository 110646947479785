import React from 'react';
import { Checkbox, LinearProgress, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { isNull } from 'lodash';

import { useTransfer } from 'src/context/TransferProvider/useTransfer';

const CustomList = ({height='100%',items=[], isLoading=false}) => 
{
    const { checked, handleToggle  } = useTransfer();

    return (
        <>
            {
                isLoading && 
                <LinearProgress
					color="inherit"
					sx={{
						top: 0,
						width: 1,
						position: 'absolute',
					}}
				/>
            }
            <List dense
                component="div"
                role="list"
                sx={{ width: '100%', height: height, overflow: 'auto', borderWidth: 1, borderRadius: 2 }}
            >
                {
                    items.map((item, i) =>
                    {
                        let { label } = item; 

                        if (isNull(label))
                            label = "";

                        const labelId = `transfer-list-item-${label.replace(' ','-')}-label`;

                        return (
                            <ListItem
                                key={labelId + '_' + i}
                                role="listitem"
                                button
                                onClick={handleToggle(item)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(item) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={label} />
                            </ListItem>
                        );
                    })
                }
                <ListItem />
            </List>
        </>
    )
}

export default CustomList