import React, { createContext, useState, useEffect  } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import ApiMessage from 'src/services/api/mensagem';

import { useSnackbar } from 'src/components/snackbar';
import { parseError } from 'src/helpers/utils';

export const MessagesContext = createContext({});

export const MessagesProvider = ({children}) =>
{   
    const messagemTol = 10;
    const { id: cod_rastreador } = useParams();

    const { enqueueSnackbar } = useSnackbar();

    const { data, isFetching, isError, error, refetch } = useQuery(['messages'], 
        async () => await ApiMessage.getRowsCounters(), 
        {
            //retry: 10,
            refetchInterval: 60000,
            refetchOnWindowFocus: false
        }
    );

    const [ dataStatus , setDataStatus] = useState({isLoading: false, message: ''});

    const [messagesGroupByVehicle, setMessagesGroupByVehicle] = useState([]);
    const [selected, setSelected] = useState(null);
    const [valueFilter, setValueFilter] = useState(null);

    const [messages, setMessages] = useState([]);
    const [lastMessages, setLastMessages] = useState([]); 
    const [totalMessages, setTotalMessages] = useState(messages.length);
    
    useEffect(() =>
    {
        defineData();
    }, 
    // eslint-disable-next-line
    [data]);

    useEffect(() =>
    {
        if (isError)
        {
            const { message } = parseError("Mensagens pendêntes", error);

            enqueueSnackbar(message, { variant: 'error' });
        }
    }, 
    //eslint-disable-next-line
    [isError]);

    useEffect(() =>
    {
        defineLastMessages();
    }, 
    //eslint-disable-next-line 
    [totalMessages]);

    const defineData = () =>
    {
        if (data)
        {
            const { status, result } = data;
         
            if (status)
            {
                let filteredResult = [];

                if (valueFilter)
                {
                    filteredResult = result.filter(item => item.cod_rastreador === valueFilter.value.cod_rastreador);
     
                    if (filteredResult.length === 0)
                        filteredResult = [{...valueFilter.value, qtde: 0, texto: '...'}];
                }
            
                if (cod_rastreador)
                    setMessagesGroupByVehicle(valueFilter ? filteredResult : result.filter(item => item.cod_rastreador === +cod_rastreador));
                else 
                    setMessagesGroupByVehicle(valueFilter ? filteredResult : result);

                defineTotalMessages(result);
            }
            else
                enqueueSnackbar(result, { variant: 'warning' });
        }
    }

    const defineLastMessages = () =>
    {
        let tmp = [...lastMessages];//structuredClone(lastMessages); 
        tmp.push(totalMessages);

        if (lastMessages.length >= messagemTol) 
            tmp.shift();
        
        setLastMessages(tmp);
    }

    const defineTotalMessages = (result) =>
    {
        let count = 0;

        result.forEach(item => 
        {
            count += item.qtde;  
        })

        setTotalMessages(count);
    }

    const getMessages = async (item) =>
    {
        setDataStatus({isLoading: true, message: 'Buscando dados...'});
        setMessages([]);
        
        setSelected(item);

        try 
        {
            const { status, result } = await ApiMessage.getRowsPerRastreador(item.cod_rastreador);

            if (status)
            {
                setMessages(result);
                await markAllAsReadsByTracker(item.cod_rastreador);
            }
        } 
        catch (error) 
        {
            setSelected(null);

            const { message } = parseError("Mensagens pendêntes", error);

            enqueueSnackbar(message, { variant: 'error' });
        }

        setDataStatus({isLoading: false, message: ''});
    }

    const markAllAsReadsByTracker = async (cod_rastreador) =>
    {
        try 
        {
            const { status, result } = await ApiMessage.lerTodasPorRastreador(cod_rastreador);

            if (status)
            {
                if (selected)
                    setTotalMessages(totalMessages - selected.qtde);
            } 
            else
            if (result !== "Item não encontrado")
                enqueueSnackbar(result, { variant: 'warning' });
        } 
        catch (error) 
        {
            const { status, message } = parseError("Ler Mensagens", error);

            if (status !== 404 || !message.includes("Cannot read properties of null (reading 'qtd"))
                enqueueSnackbar(message, { variant: 'error' });
        }
    }

    const markAsRead = async (cod_mensagem) =>
    {
        try 
        {
            const { status, result } = await ApiMessage.ler(cod_mensagem);

            if (!status)
                enqueueSnackbar(result, { variant: 'warning' });
            else
                refetch();
        } 
        catch (error) 
        {
            const { message } = parseError("Ler Mensagem", error);

            enqueueSnackbar(message, { variant: 'error' });
        }
    }

    const markAllAsRead = async () =>
    {
        try 
        {
            const { status, result } = await ApiMessage.lerTodas();

            if (status)
            {
                setSelected(null);
                setMessagesGroupByVehicle([]);
                setMessages([]);
                setTotalMessages(0);
            }
            else
                enqueueSnackbar(result, { variant: 'warning' });
        } 
        catch (error) 
        {
            const { message } = parseError("Ler Todas Mensagens", error);

            enqueueSnackbar(message, { variant: 'error' });
        }
    }

    const handMessages = async (item) => await getMessages(item);

    useEffect(() =>
    {
        defineData();
    }, 
    //eslint-disable-next-line
    [valueFilter]);

    return (
        <MessagesContext.Provider
            value={
            {
                messagesGroupByVehicle, isFetching, isError, error, refetch,
                handMessages, setMessages, messages,
                selected,
                valueFilter, setValueFilter,
                totalMessages, lastMessages,
                markAsRead, markAllAsRead,
                dataStatus
            }}
        >
            {children}
        </MessagesContext.Provider>
    )
}