import React from 'react';
import Select from '@mui/material/Select';
import { FormControl, InputLabel, MenuItem } from '@mui/material';

const InputSelect = React.memo(({
    id,
    label,
    value = '',
    items = [],
    error,
    size = '',
    onChange,
    tabIndex,
    disabled = false,
    required = false,
    style,
    helper,
}) => {
    const translateLabel = (label) => {
        const translations = {
            'S': 'Sim',
            'N': 'Não',
        };
        return translations[label] || label;
    };

    return (
        <div style={style}>
            <FormControl fullWidth error={error}>
                <InputLabel id={`select_label_${id}`}>{label}</InputLabel>
                <Select
                    name={id}
                    labelId={`select_label_${id}`}
                    id={id}
                    value={value}
                    disabled={disabled}
                    label={label}
                    size={size}
                    onChange={onChange}
                    required={required}
                    fullWidth
                    tabIndex={tabIndex}
                >
                    <MenuItem value="" key={`select_${id}`}>- Selecione um Item -</MenuItem>
                    {items.map((item, key) => (
                        <MenuItem 
                            key={`select_${id}_${key}`}
                            value={item.value !== undefined ? item.value : item.id}
                        >
                            {translateLabel(item.label !== undefined ? item.label : item.value)}
                        </MenuItem>
                    ))}
                </Select>
                {/* {helper && <FormHelperText>{helper}</FormHelperText>} */}
            </FormControl>
        </div>
    );
});

export default InputSelect;

