import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import { CrudProvider } from 'src/context/CrudProvider';
import { getApplicationName, getClientName } from 'src/helpers/servers';

import Main from './Main';

const Index = ({ title, id, endpoint, columns, cod_pk_name, optionsButtonsList = [] }) => {
    return (
        <CrudProvider 
            id={id} 
            columns={columns} 
            endpoint={endpoint} 
            cod_pk_name={cod_pk_name} 
            title={title} 
            optionsButtonsList={optionsButtonsList}
        >
            <Helmet>
                <title>{`${title} | ${getApplicationName()} | ${getClientName()}`}</title>
            </Helmet>
            <Main />
        </CrudProvider>
    );
}

Index.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    cod_pk_name: PropTypes.string.isRequired,
    optionsButtonsList: PropTypes.array,
};

export default Index;

