import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItemText, ListItemButton } from '@mui/material';
import { ICON } from 'src/config';
import useActiveLink from 'src/hooks/useActiveLink';
import Iconify from 'src/components/iconify';
import { useCrud } from 'src/context/CrudProvider/useCrud';
import { useSnackbar } from 'src/components/snackbar';

const CrudNavItem = React.memo(({ label, ...other }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { setFiltered, data, refetch, setSelectedRow } = useCrud();
    const { active } = useActiveLink(label.id);

    const handleFilter = useCallback(() => {
        if (!data) return;

        const { status, result } = data;
        if (!status) return;

        setSelectedRow(null);

        if (label.origin) {
            const { id, type, values, message, isWarning } = label.origin;
            const valueSelected = values.find(item => item.value === label.name);

            let filteredResult = [];

            if (type === "groupBy") {
                filteredResult = result.filter(x => x[id] === valueSelected.value);
            } else if (type === "sum") {
                filteredResult = values.reduce((acc, item, i) => {
                    const operation = item.operator === "!=="
                        ? (arr) => arr.filter(x => x[item.col] !== item.value)
                        : (arr) => arr.filter(x => x[item.col] === item.value);
                    return i === 0 ? operation(result) : operation(acc);
                }, result);
            }

            setFiltered(filteredResult);

            enqueueSnackbar(message || `Filtro por ${label.name}`, { variant: isWarning ? 'warning' : 'success' });
        } else {
            updateData();
        }
    }, [data, label, setFiltered, setSelectedRow, enqueueSnackbar]);

    const updateData = useCallback(() => {
        setFiltered(null);
        refetch();
    }, [setFiltered, refetch]);

    const isUnread = label.unreadCount !== 0;

    return label.count > 0 ? (
        <ListItemButton
            onClick={handleFilter}
            sx={{
                px: 3,
                height: 48,
                typography: 'body2',
                color: 'text.secondary',
                textTransform: 'capitalize',
                ...(active && {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightMedium',
                }),
            }}
            {...other}
        >
            <Iconify
                icon={label.icon}
                sx={{
                    mr: 2,
                    width: ICON.NAV_ITEM,
                    height: ICON.NAV_ITEM,
                    color: label.color,
                }}
            />
            <ListItemText disableTypography primary={label.name} />
            {isUnread && <Typography variant="caption">{label.count}</Typography>}
        </ListItemButton>
    ) : null;
});

CrudNavItem.propTypes = {
    label: PropTypes.object.isRequired,
};

export default CrudNavItem;

