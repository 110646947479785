import React from 'react';
import { CircularProgress } from '@mui/material';
import { useForm } from 'src/context/FormReusable/useForm';

const LoadingSelect = React.memo(() => {
    const { selectStatus } = useForm();

    return selectStatus.status ? (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress />
            <span style={{margin: 10}}>{selectStatus.msg}...</span>
        </div>
    ) : null;
});

export default LoadingSelect;

