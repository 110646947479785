import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';

import { IconButton } from 'src/components/Ca2';
import { useTracking } from 'src/context/TrackingProvider/useTracking';
import { PATH_DASHBOARD } from 'src/routes/paths';

import Iconify from 'src/components/iconify/Iconify';

const StatusGeneral = ({ item }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { getDetailedData } = useTracking();
    const { row } = item;

    const statusItems = [
        {
            condition: row?.statusVgm === 'Com Viagem',
            icon: 'mdi:truck-fast',
            color: theme.palette.success.main,
            title: 'Com Viagem (Ctrl + Enter)',
            onClick: () => getDetailedData(row),
        },
        {
            condition: row?.c_evt_inibidos_ref > 0,
            icon: 'mdi:map-marker-remove',
            color: theme.palette.warning.main,
            title: `Eventos inibido pela referência '${row.referencia}': ${row?.c_evt_inibidos_ref}`,
            onClick: () => navigate(PATH_DASHBOARD.reference.kindOfEvent.replace(':id', row.cod_referencia)),
        },
        {
            condition: row?.c_evt_inibidos > 0,
            icon: 'material-symbols:warning-off',
            color: theme.palette.warning.main,
            title: `Eventos inibidos por raio: ${row?.c_evt_inibidos}`,
            onClick: () => navigate(PATH_DASHBOARD.inhibition.listVehicle.replace(':id', row.cod_veiculo)),
        },
        {
            condition: row?.conta_pend_oc > 0,
            icon: 'mdi:note-text-outline',
            color: theme.palette.info.main,
            title: `Ocorrências: ${row?.conta_pend_oc}`,
            onClick: () => navigate(PATH_DASHBOARD.occurrence.listVehicle.replace(':id', row.cod_veiculo)),
        },
        {
            condition: row?.evt_003 === 'S',
            icon: 'material-symbols:signal-disconnected',
            color: theme.palette.warning.main,
            title: `Perda de Sinal: ${row?.ps}`,
            margin: 7,
        },
        {
            condition: row?.evt_014 === 'S',
            icon: 'mdi:transit-detour',
            color: theme.palette.error.main,
            title: 'Desvio de Rota',
            margin: 7,
        },
        {
            condition: row?.evt_026 === 'S',
            icon: 'iconoir:move-down',
            color: theme.palette.error.main,
            title: 'Movimento Indevido',
            margin: 7,
        },
        {
            condition: row?.evt_042 === 'S',
            icon: 'fluent:presence-blocked-10-regular',
            color: theme.palette.error.main,
            title: 'Veículo Bloqueado',
            margin: 7,
        },
        {
            condition: row?.evt_049 === 'S',
            icon: 'fa6-solid:trailer',
            color: theme.palette.error.main,
            title: 'Desengate de Carreta 1',
            margin: 7,
        },
        {
            condition: row?.evt_099 === 'S',
            icon: 'ic:outline-sensor-door',
            color: theme.palette.error.main,
            title: 'Porta do Baú',
            margin: 7,
        },
        {
            condition: row?.evt_pi === 'S',
            icon: 'mdi:stop-alert',
            color: theme.palette.error.main,
            title: 'Parada Indevida',
            margin: 7,
        },
    ];

    return (
        <Stack flexDirection="row" justifyContent="center" alignItems="center">
            {statusItems.map(
                ({ condition, icon, color, title, onClick, margin }, index) =>
                    condition && (
                        <span key={index} title={title} style={margin ? { margin } : {}}>
                            {onClick ? (
                                <IconButton icon={icon} color={color} onClick={onClick} />
                            ) : (
                                <Iconify icon={icon} color={color} />
                            )}
                        </span>
                    )
            )}
        </Stack>
    );
};

export default StatusGeneral;

