import { fetchDataOn } from '../../helpers/utils';

let Api = {
    getRows: async () =>
    {
		const endpoint = '/veiculo/listar';

		return await fetchDataOn(endpoint);
    },
	getRow: async (id) =>
    {
        const endpoint = `/veiculo/obter/${id}`;

		return await fetchDataOn(endpoint);
    },
	getRowOperationMode: async (id) =>
    {
        const endpoint = `/veiculo/obter/modooperacao/${id}`;

		return await fetchDataOn(endpoint);
    },
	update: async (id, values) =>
    {
        const endpoint = `/veiculo/atualizar/${id}`;

		return await fetchDataOn(endpoint, values);
    },
    combo: async () =>
    {
        const endpoint = `/veiculo/combo`;

		return await fetchDataOn(endpoint);
    },
    comboByGroup: async () =>
    {
        const endpoint = `/veiculo/combo/grupo`;

		return await fetchDataOn(endpoint);
    },
    convertPlate2Mercosul: async (cod_veiculo) =>
    {
        const endpoint = `/veiculo/converter-placa/mercosul/${cod_veiculo}`;

		return await fetchDataOn(endpoint);
    },
    hasEvtPgrMain: async (cod_veiculo, cod_tipoevento) =>
    {
        const endpoint = `/veiculo/pgr/principal/tem-evento/${cod_veiculo}/${cod_tipoevento}`;
        
		return await fetchDataOn(endpoint);
    },
    hasEvtPgrSecondary: async (cod_veiculo, cod_tipoevento) =>
    {
        const endpoint = `/veiculo/pgr/minimo/tem-evento/${cod_veiculo}/${cod_tipoevento}`;

		return await fetchDataOn(endpoint);
    },
}

export default Api;
