import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';
    import {Button, Stack, Typography} from '@mui/material';

import Iconify from 'src/components/iconify';

import {PATH_DASHBOARD} from 'src/routes/paths';
import {typeFiltersTracking} from 'src/helpers/utils';

const FilterOfTrackingButton = () =>
{
    const location = useLocation();
    const navigate = useNavigate();

    const filterInit = {label: '', description: ''};

    const [visible, setVisible] = useState(false);
    const [filter, setFilter] = useState(filterInit);

    useEffect(() => {
       control(); 
    }, 
    //eslint-disable-next-line
    [location]);

    const control = () =>
    {
        const {pathname} = location;

        const isVisible = pathname.includes("/tracking/list/");

        setVisible(isVisible);

        if (isVisible)
        {
            const tmp = pathname.split('/');

            setFilter({label: typeFiltersTracking(tmp[3]), description: decodeURI(tmp[4])});
        }
        else 
            setFilter(filterInit);
    }

    const handleGo = () => navigate(PATH_DASHBOARD.tracking.list);

    return visible && (
        <Button
            title={`Clique para remover o filtro ${filter.description}`}
            onClick={() => handleGo()}
        >
            <Stack style={{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <Typography sx={{mr: 1}}>{filter.label}: {filter.description}</Typography>
                <Iconify icon="bitcoin-icons:clear-character-outline"   />
            </Stack>
        </Button>
    );
}

export default FilterOfTrackingButton;
