import React, { createContext, useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useParams } from 'react-router-dom';

import { useSnackbar } from 'src/components/snackbar';
import { fetchDataOn } from 'src/helpers/utils';

export const TransferContext = createContext({});

export const TransferProvider = ({idPrimary, idSecondary, titlePrimary, titleSecondary, apiGetMain, apiGetLeft, apiGetRight, apiPostToUp, apiPostToDown, apiPostToLink, apiPostToUnlink, optionsButtonsList=[], children}) =>
{
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [keyWordFilter, setKeyWordFilter ] = useState('');
    const [stateData, setStateData] = useState({ isLoading: false, message: '', isError: false, source: '' });
    const [data, setData] = useState(null);
    const [hasUpDown, sethasUpDown] = useState(false);

    const getName = () =>
    {
        if (data)
        {
            const { nome, placa, cb } = data;
            
            if (placa)
                return placa;
            else
            if (cb)
                return cb;
            else
                return nome;
        }
        else
            return null;
    }

    //
    // Buttons Control
    //
    const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);
    const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);

    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const [isLoadingLeft, setIsLoadingLeft] = useState(false);
    const [isLoadingRight, setIsLoadingRight] = useState(false);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    // useEffect(() =>
    // {
    //     console.log(keyWordFilter)
    // },
    // [keyWordFilter]);
    
    const handleToggle = (item) => () =>
    {
        const currentIndex = checked.indexOf(item);
        const newChecked = [...checked];

        if (currentIndex === -1)
            newChecked.push(item);
        else
            newChecked.splice(currentIndex, 1);

        setChecked(newChecked);
    };

    const handleAllRight = async () =>
    {
        confirmAlert(
        {
            title: 'Atenção',
            message: `Tem certeza que deseja ADICIONAR todos esses itens à ${getName()} ?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () =>
                    {
                        for await (const item of left)
                            await toLink(item);

                        setRight(right.concat(left));
                        setLeft([]);
                    }
                },
                {
                    label: 'Não'
                }
            ]
        });
    };

    const handleCheckedRight = async () =>
    {
        for await (let item of leftChecked)
        {
            const cod_pk = await toLink(item);

            if (cod_pk)
            {
                let newLeftChecked = leftChecked;
                newLeftChecked[leftChecked.findIndex(x => x === item)].cod_pk = cod_pk;

                setRight(right.concat(newLeftChecked));
                setLeft(not(left, newLeftChecked));
                setChecked(not(checked, newLeftChecked));
            }
        }
    };

    const handleCheckedLeft = async () =>
    {
        for await (const item of rightChecked)
            await toUnlink(item);

        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () =>
    {
        confirmAlert(
        {
            title: 'Atenção',
            message: `Tem certeza que deseja remover todos os itens de ${getName()} ?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () =>
                    {
                        for await (const item of right)
                            await toUnlink(item);

                        setLeft(left.concat(right));
                        setRight([]);
                    }
                },
                {
                    label: 'Não'
                }
            ],
        })
    };

    const getData = async () =>
    {
        setStateData({ isLoading: true, message: 'Buscando dados', isError: false, source: 'data' });

        try
        {
            const { status, result } = await fetchDataOn(apiGetMain);

            if (status)
            {
                setData(result);
                setStateData({ isLoading: false, message: 'Pronto', isError: false, source: 'data' })
            }
            else
            {
                setStateData({ isLoading: false, message: result, isError: true, source: 'data' })
                enqueueSnackbar(result, { variant: 'warning' });
            }
        }
        catch (error)
        {
            console.log(error)
            //const { result } = error.response.data;

            setStateData({ isLoading: false, message: '', isError: true, source: 'data' });
            enqueueSnackbar("ops, houve algum erro", { variant: 'warning' });
        }
    }

    const getLeft = async () =>
    {
        setStateData({ isLoading: true, message: 'Buscando itens disponíveis', isError: false, source: 'left' });
        setIsLoadingLeft(true);

        try
        {
            const { status, result } = await fetchDataOn(apiGetLeft);

            if (status)
            {
                setLeft(result);
                setStateData({ isLoading: false, message: 'Pronto', isError: false, source: 'left' })
            }
            else
            {
                setStateData({ isLoading: false, message: result, isError: true, source: 'left' })
                enqueueSnackbar(result, { variant: 'warning' });
            }
        }
        catch (error)
        {
            console.log(error)
            //const { result } = error.response.data;

            setStateData({ isLoading: false, message: '', isError: true, source: 'left' });
            enqueueSnackbar("Ops, houve algum erro", { variant: 'warning' });
        }

        setIsLoadingLeft(false);
    }

    const getRight = async () =>
    {
        setStateData({ isLoading: true, message: 'Buscando itens associados', isError: false, source: 'right' });
    
        try
        {
            const { status, result } = await fetchDataOn(apiGetRight);

            if (status)
            {
                setRight(result);
                setStateData({ isLoading: false, message: 'Pronto', isError: false, source: 'right' })
            }
            else
            {
                setStateData({ isLoading: false, message: result, isError: true, source: 'right' });
                enqueueSnackbar(result, { variant: 'warning' });
            }
        }
        catch (error)
        {
            const { result } = error.response.data;

            setStateData({ isLoading: false, message: result, isError: true, source: 'right' });
            enqueueSnackbar(result, { variant: 'warning' });
        }

        setIsLoadingRight(false);
    }

    const toLink = async (item) =>
    {
        const { id, cod, label } = item;

        const cod_item = id ?? cod;

        setStateData({ isLoading: true, message: `Adicionando ${label}...`, isError: false, source: 'toLink' });

        setIsLoadingLeft(true);
        setIsLoadingRight(true);

        try
        {
            const { endpoint, id_main, id_child } = apiPostToLink;
            const body = { [id_main]: +params.id, [id_child]: cod_item};
            const { status, result, id } = await fetchDataOn(endpoint, body);

            if (status)
            {
                setStateData({ isLoading: false, message: result, isError: false, source: 'toLink' });
                setIsLoadingLeft(false);
                setIsLoadingRight(false);
                return id;
            }
            else
            {
                setStateData({ isLoading: false, message: result, isError: true, source: 'toLink' });
                enqueueSnackbar(result, { variant: 'warning' });
                setIsLoadingLeft(false);
                setIsLoadingRight(false);
                return null;
            }
        }
        catch (error)
        {
            const { result } = error.response.data;
            setStateData({ isLoading: false, message: result, isError: true, source: 'toLink' });
            enqueueSnackbar(result, { variant: 'error' });
            setIsLoadingLeft(false);
            setIsLoadingRight(false);
            return null;
        }
    }

    const toUnlink = async (item) =>
    {
        const { id, cod, label } = item;

        const cod_item = id ?? cod;

        setStateData({ isLoading: true, message: `Removendo ${label}...`, isError: false, source: 'toUnlink' });

        setIsLoadingLeft(true);
        setIsLoadingRight(true);

        try
        {
            const { endpoint, id_main, id_child } = apiPostToUnlink;
            const body = { [id_main]: +params.id, [id_child]: cod_item};
            const { status, result } = await fetchDataOn(endpoint, body);

            if (status)
            {
                setStateData({ isLoading: false, message: result, isError: false, source: 'toUnlink' });
            }
            else
            {
                setStateData({ isLoading: false, message: result, isError: true, source: 'toUnlink' });
                enqueueSnackbar(result, { variant: 'warning' });
            }
        }
        catch (error)
        {
            const { result } = error.response.data;
            setStateData({ isLoading: false, message: result, isError: true, source: 'toUnlink' });
            enqueueSnackbar(result, { variant: 'error' });
        }

        setIsLoadingLeft(false);
        setIsLoadingRight(false);
    }

    const handleCheckedUp = () =>
    {
        const index = right.findIndex(x => x.id === rightChecked[0].id);

        if (index > 0)
        {
            const indexNew = index - 1;
            let elements = right.slice();
            let element = elements.splice(index, 1)[0]; 
            elements.splice(indexNew, 0, element);
            repositionItems(elements, "up");
        }
    }

    const handleCheckedDown = () =>
    {
        const index = right.findIndex(x => x.id === rightChecked[0].id);

        if ((right.length - 1) > index )
        {
            const indexNew = index + 1;
            let elements = right.slice();
            let element = elements.splice(index, 1)[0];
            elements.splice(indexNew, 0, element);
            repositionItems(elements, "down");
        }
    }

    const repositionItems = async (elements, opc) =>
    {
        setStateData({ isLoading: true, message: "", isError: false, source: 'right' });

        let index = 1;
        
        for await (const item of elements) 
        {
            const path = opc === "up" ? apiPostToUp : apiPostToDown;
            const endpoint = path.replace(":id_main",+params.id).replace(":id_child", item.id).replace(":value",index);

            const { status, result } = await fetchDataOn(endpoint);

            if (!status)
                enqueueSnackbar(result, { variant: 'warning' });

            index++;
        }

        setStateData({ isLoading: false, message: "", isError: false, source: 'right' });

        setRight(elements);
    }

    useEffect(() =>
    {
        if (apiPostToUp && apiPostToDown)
            sethasUpDown(true);
    }, 
    // eslint-disable-next-line
    []);

    useEffect(() =>
    {
        (async () =>
        {
            if (apiGetMain && apiGetLeft && apiGetRight)
            {
                await getData();
                await getLeft();
                await getRight();
            }
        })();
    }, 
    // eslint-disable-next-line
    [apiGetMain, apiGetLeft, apiGetRight]);

    // SOMENTE PARA GRID
    const [columns, setColumns] = useState([]);

    return (
        <TransferContext.Provider
            value={
            {
                idPrimary, idSecondary, 
                titlePrimary, titleSecondary,
                stateData,
                data,
                checked, setChecked,
                left, setLeft, isLoadingLeft,
                right, setRight, isLoadingRight,
                leftChecked,
                rightChecked,
                handleToggle,
                handleAllRight,
                handleCheckedRight,
                handleCheckedLeft,
                handleAllLeft,
                hasUpDown, handleCheckedUp, handleCheckedDown,
                getName,
                optionsButtonsList,
                // somente para grid
                columns, setColumns,
                // filter
                keyWordFilter, setKeyWordFilter 
            }}
        >
            {children}
        </TransferContext.Provider>
    )
}