import { useQuery } from "@tanstack/react-query";
import { useMemo } from 'react';
import api from 'src/services/api/rastreamento';

const queryOptions = {
    refetchOnWindowFocus: true,
    retry: 10,
    cacheTime: 60000,
    refetchInterval: 60000,
    refetchOnReconnect: true,
};

const useTrackingData = () => {
    const queryResult = useQuery(['tracking'], api.getRows, queryOptions);

    // Memoriza o valor dos dados para evitar cálculos desnecessários
    const memoizedData = useMemo(() => queryResult.data, [queryResult.data]);

    // Retorna todos os valores do `useQuery` juntamente com os dados memorizados
    return {
        ...queryResult,  // Inclui isLoading, isError, error, refetch, etc.
        data: memoizedData,  // Substitui `data` pelo valor memorizado
    };
};

export default useTrackingData;

