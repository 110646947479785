import { fetchDataOn } from "src/helpers/utils";

let Api = {
	getRowsAnalitico: async (cod_rastreador, di, df) =>
    {
		const endpoint = '/posicao_historico/analitico';
		const body = { cod_rastreador, di, df }

		return await fetchDataOn(endpoint, body);
    },
	getRowsAnaliticoByVeiculo: async (cod_veiculo, di, df) =>
    {
		const endpoint = '/posicao_historico/analitico';
		const body = { cod_veiculo, di, df }

		return await fetchDataOn(endpoint, body);
    },
	getRowsPolyline: async (cod_rastreador, di, df) =>
    {
		const endpoint = '/posicao_historico/polilinha';
		const body = { cod_rastreador, di, df }

		return await fetchDataOn(endpoint, body);
    },
}

export default Api;
