import React from 'react';
import PropType  from 'prop-types';
import { MenuItem, Menu, Divider, ListItemText } from '@mui/material';
import { IconButton } from 'src/components/Ca2';
import Iconify from 'src/components/iconify';

const Index = ({id, title, icon, items, isVisible=true}, style) => 
{
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return isVisible && (
        <div style={{...style}}>
            <IconButton 
                id={`${id}_basic-button`}
                title={title}
                icon={icon}
                aria-controls={open ? `${id}_basic-menu` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            />
            <Menu
                id={ `${id}_basic-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{'aria-labelledby': `${id}_basic-button`, }}
            >
                {
                    items && items.filter(item => (item.isVisible === undefined || item.isVisible === null) ? true : item.isVisible).map((item, i) =>
                    {
                        return item.type === "menu" || item.type === undefined
                        ? <MenuItem
                            key={`${id}_${i}`}
                            onClick={() => { item.onClick(); handleClose() }}
                            disabled={item.disabled === null ? false : item.disabled}
                        >
                            { item.icon && <Iconify icon={item.icon} sx={{mr: 1}} />}
                            <ListItemText style={{color: item.color}} inset={item.icon === undefined}>{item.title}</ListItemText>
                        </MenuItem>
                        : <Divider key={`${id}_${i}`} />
                    })
                }
            </Menu>
        </div>
    )
}

Index.prototype = {
    id: PropType.string,
    title: PropType.string,
    icon: PropType.string,
    item: PropType.array,
    isVisible: PropType.bool,
    style: PropType.object
}

export default Index