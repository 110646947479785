import React from 'react';
import { IconButton } from '@mui/material';

import { useTransfer } from 'src/context/TransferProvider/useTransfer';
import Iconify from 'src/components/iconify';
import useResponsive from 'src/hooks/useResponsive';

const Buttons = () => 
{
    const {
        handleAllRight,
        handleAllLeft,
        right, rightChecked, handleCheckedRight,
        left, leftChecked, handleCheckedLeft,
        hasUpDown, handleCheckedUp, handleCheckedDown
    } = useTransfer();

    const isDesktop = useResponsive('up', 'md');

    return (
        <>
            <IconButton
                onClick={handleAllRight}
                disabled={left.length === 0 || leftChecked.length > 0}
                title="Adicionar Todos"
            >
                <Iconify icon={`bxs:chevrons-${isDesktop ? "right" : "down"}`} color="error"/>
            </IconButton>
            <IconButton
                style={{margin: 2}}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                title="Adicionar Itens Selecionados"

            >
                <Iconify icon={`bxs:chevron-${isDesktop ? "right" : "down"}`} />
            </IconButton>
            {
                hasUpDown &&
                <>
                    <IconButton
                        style={{margin: 2}}
                        onClick={handleCheckedUp}
                        disabled={rightChecked.length !== 1}
                        title="Subir"
                    >
                        <Iconify icon={`bxs:chevron-${isDesktop ? "up" : "left"}`} />
                    </IconButton>
                    <IconButton
                        style={{margin: 2}}
                        onClick={handleCheckedDown}
                        disabled={rightChecked.length !== 1}
                        title="Descer"
                    >
                        <Iconify icon={`bxs:chevron-${isDesktop ? "down" : "right"}`} />
                    </IconButton>                
                </>
            }
            <IconButton
                style={{margin: 2}}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                title="Remover Itens Selecionados"
            >
                <Iconify icon={`bxs:chevron-${isDesktop ? "left" : "up"}`} />
            </IconButton>
            <IconButton
                style={{margin: 2}}
                onClick={handleAllLeft}
                disabled={right.length === 0 || rightChecked.length > 0}
                title="Remover Todos"
            >
                <Iconify icon={`bxs:chevrons-${isDesktop ? "left" : "up"}`} color="error" />
            </IconButton>
        </>
    )
}

export default Buttons