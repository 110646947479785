import { useTheme } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/iconify/Iconify';
import Label from 'src/components/label/Label';

const TripDistance = ({ value }) => {
    const theme = useTheme();

    if (!value || value.length === 0) return null;

    if (value.includes("T:")) {
        const [remainingT, totalT] = value.replace("R:", "").split("T:").map(str => str.trim());
        return <Label>R: {remainingT}Km T: {totalT}Km</Label>;
    }

    if (value === "Aguardando...") {
        return (
            <span title="Aguardando...">
                <Iconify icon="material-symbols:time-auto-outline" color={theme.palette.info.main} />
            </span>
        );
    }

    if (value.includes("alcance da Rota")) {
        return (
            <span title={value}>
                <Iconify icon="material-symbols:warning-outline" color={theme.palette.error.main} />
            </span>
        );
    }

    return <Label>{value}</Label>;
};

export default TripDistance;

