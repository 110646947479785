import React from 'react';
import { Card, Divider, Grid } from '@mui/material';

import { useTransfer } from 'src/context/TransferProvider/useTransfer';

import CustomList from './CustomList';
import Buttons from './Buttons';
import ToolbarLeft from './ToolbarLeft';
import ToolbarRight from './ToolbarRight';
import useResponsive from 'src/hooks/useResponsive';
import GridCfg from './GridCfg';

const Tranfer = () => 
{
    const { 
        idPrimary, idSecondary,
        left, isLoadingLeft,
        right, isLoadingRight,
        keyWordFilter 
    } = useTransfer();

    const isDesktop = useResponsive('up', 'md');

    const height = idPrimary === "grid" && idSecondary === "columns" ? "35vh" : "72vh";

    return (
        isDesktop 
        ? (
            <Grid container>
                <Grid item xs={5}>
                    <Card sx={{ pt: 5, pb: 5, px: 3, mb: 3 }} style={{height: height}}>
                        <ToolbarLeft />
                        <Divider />
                        <CustomList height={'90%'} items={left.filter(item => item.label.toLowerCase().includes(keyWordFilter.trim().toLowerCase()))} isLoading={isLoadingLeft} />
                    </Card>
                </Grid>
                <Grid item xs={2} style={{display: 'flex', flexDirection: 'column', height: height, justifyContent: 'center', alignItems:'center'}}>
                    <Buttons />
                </Grid>
                <Grid  item xs={5}>
                    <Card sx={{ pt: 5, pb: 5, px: 3, mb: 3 }} style={{height: height}}>
                        <ToolbarRight />
                        <Divider />
                        <CustomList items={right.filter(item => item.label.toLowerCase().includes(keyWordFilter.trim().toLowerCase()))} isLoading={isLoadingRight} />
                    </Card>
                </Grid>
                {
                    idPrimary === "grid" && idSecondary === "columns" &&
                    <Grid  item xs={12}>
                        <Card sx={{ pt: 5, pb: 5, px: 3, mb: 3 }} style={{height: height}}>
                            <GridCfg />
                        </Card>
                    </Grid>
                }
            </Grid>
        )
        : (
            <Grid container>
                <Grid item xs={12}>
                    <Card sx={{ pt: 5, pb: 5, px: 3, mb: 0 }}  style={{height: 350}}>
                        <ToolbarLeft />
                        <Divider />
                        <CustomList items={left} />
                    </Card>
                </Grid>
                <Grid item xs={12} style={{display: 'flex', flexDirection: 'row', height: 50, justifyContent: 'center', alignItems:'center'}}>
                    <Buttons />
                </Grid>
                <Grid  item xs={12}>
                    <Card sx={{ pt: 5, pb: 5, px: 3, mb: 3 }}  style={{height: 350}}>
                        <ToolbarRight />
                        <Divider />
                        <CustomList items={right} />
                    </Card>
                </Grid>
                {
                    idPrimary === "grid" && idSecondary === "columns" &&
                    <Grid  item xs={12}>
                        <Card sx={{ pt: 5, pb: 5, px: 3, mb: 3 }}  style={{height: 350}}>
                            <GridCfg />
                        </Card>
                    </Grid>
                }
            </Grid>
        )
    )
}

export default Tranfer
