import React from 'react';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { IconButton } from '..';
import Iconify from 'src/components/iconify/Iconify';

const InputPassword = React.memo(({
    id,
    label,
    placeholder,
    style,
    value = '',
    error,
    maxLength,
    disabled,
    required = false,
    step,
    tabIndex,
    onChange = () => null,
    ...rest
}) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                id={id}
                name={id}
                label={label}
                placeholder={placeholder}
                onChange={onChange}
                style={style}
                value={value}
                disabled={disabled}
                required={required}
                fullWidth
                error={error}
                tabIndex={tabIndex}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <Iconify icon="mdi:eye-off" /> : <Iconify icon="mdi:eye-on" />}
                        </IconButton>
                    </InputAdornment>
                }
                inputProps={{ maxLength, step }}
                {...rest}
            />
        </FormControl>
    );
});

export default InputPassword;

